import { Body, H4 } from 'components/Typography';
import styled from 'styled-components';
import { FiCheck as DefaultCheckIcon } from 'react-icons/fi';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  padding: 0 10% 2rem 10%;
  text-align: center;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  background-color: ${({ theme }) => theme.colors.green};
  border-radius: 50%;
`;

export const Title = styled(H4)``;

export const Subtitle = styled(Body)`
  margin-bottom: 2rem;
`;

export const CheckIcon = styled(DefaultCheckIcon)`
  width: 1rem;
  height: 1rem;
  color: ${({ theme }) => theme.colors.white};
`;
