import styled, { css } from 'styled-components';

import { ContainerProps } from './types';

export const Container = styled.div<ContainerProps>`
  position: relative;
  z-index: ${({ $position }) => `${$position}`};
  opacity: 0;
  animation: ${({ $animation, $duration }) => css`
    ${$animation} ${$duration}ms cubic-bezier(.47,.08,.28,.98)
  `};
  animation-delay: ${({ $delay }) => `${$delay}ms`};
  animation-fill-mode: forwards;

  @media (prefers-reduced-motion) {
    opacity: 1;
    animation: none;
    animation-delay: 0;
  }

  .flex-container {
    display: flex;
  }
`;
