import { gql } from '@apollo/client';

export const SAVE_PAYMENT_TOKEN = gql`
  mutation SavePaymentToken($paymentTokenId: UUID!, $offerId: UUID!) {
    savePaymentToken(paymentTokenId: $paymentTokenId, offerId: $offerId) {
      ok
    }
  }
`;

export const AUTHORIZE_PAYMENT_DISPLAY = gql`
  mutation AuthorizePaymentDisplay($input: AuthorizePaymentDisplayInput!) {
    authorizePaymentDisplay(input: $input) {
      ok
    }
  }
`;
