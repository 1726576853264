import { parseISO } from 'date-fns';
import { DocumentTypeType, PolicyDocumentType } from 'graphql/generated';

type InputPolicyDocument = PolicyDocumentType & { policyId: string };

const getDocumentName = (input: InputPolicyDocument) => {
  if (input.documentType === 'DOCUMENT_FRONT') {
    return 'Front of ID';
  }
  if (input.documentType === 'DOCUMENT_BACK') {
    return 'Back of ID';
  }
  return input.name;
};

export const normalizePolicyDocument = (input: InputPolicyDocument) => ({
  createdAt: input.created ? parseISO(input.created).getTime() : undefined,
  id: input.id,
  name: getDocumentName(input),
  policyId: input.policyId,
  size: input.documentSize,
  type: input.documentType as DocumentTypeType,
});

export type PolicyDocument = ReturnType<typeof normalizePolicyDocument>;
