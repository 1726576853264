import { VehicleAssetType } from 'graphql/generated';
import formatMoney from 'utils/formatMoney';

type InputVehicleCoverage = Omit<VehicleAssetType, 'policy'>;

export const normalizeVehicleCoverage = (input: InputVehicleCoverage) => ({
  collisionDeductible: input.collisionDeductible
    ? formatMoney(input.collisionDeductible)
    : undefined,
  collisionValue: input.collisionValue,
  comprehensiveDeductible: input.comprehensiveDeductible
    ? formatMoney(input.comprehensiveDeductible)
    : undefined,
  comprehensiveValue: input.comprehensiveValue,
  id: input.id,
  make: input.make,
  model: input.model,
  othersBodilyAccident: input.othersBodilyAccident
    ? formatMoney(input.othersBodilyAccident)
    : undefined,
  othersBodilyPerson: input.othersBodilyPerson
    ? formatMoney(input.othersBodilyPerson)
    : undefined,
  othersMedicalPayments: input.othersMedicalPayments
    ? formatMoney(input.othersMedicalPayments)
    : undefined,
  othersPropertyAccident: input.othersPropertyAccident
    ? formatMoney(input.othersPropertyAccident)
    : undefined,
  ownership: input.ownership,
  personalProtectionPerson: input.personalProtectionPerson
    ? formatMoney(input.personalProtectionPerson)
    : undefined,
  primaryUse: input.primaryUse,
  reimbursementDay: input.reimbursementDay
    ? formatMoney(input.reimbursementDay)
    : undefined,
  reimbursementMaximum: 30,
  roadsideAssistance: input.roadsideAssistance,
  roadsideAssistanceValue: input.roadsideAssistanceValue,
  totalPremium: input.totalPremium,
  uninsuredMotoristAccident: input.uninsuredMotoristAccident
    ? formatMoney(input.uninsuredMotoristAccident)
    : undefined,
  uninsuredMotoristPerson: input.uninsuredMotoristPerson
    ? formatMoney(input.uninsuredMotoristPerson)
    : undefined,
  uninsuredPropertyAccident: input.uninsuredPropertyAccident
    ? formatMoney(input.uninsuredPropertyAccident)
    : undefined,
  uninsuredPropertyDeductible: input.uninsuredPropertyDeductible
    ? formatMoney(input.uninsuredPropertyDeductible)
    : undefined,
  vehicleInfo: input.vehicleInfo || '',
  vehicleVin: input.vehicleVin || '',
  year: input.year,
});

export type VehicleCoverage = ReturnType<typeof normalizeVehicleCoverage>;
