import { gql } from '@apollo/client';

import { BENEFICIARY_FRAGMENT } from './beneficiary';
import { CARRIER_FRAGMENT } from './carrier';
import { DOCUMENT_FRAGMENT } from './document';
import {
  DRIVER_FRAGMENT,
  HOME_OWNER_FRAGMENT,
  LIFE_INSURED_FRAGMENT,
  RENTER_FRAGMENT,
  SHARED_ACCESS_FRAGMENT,
} from './namedInsured';
import { USER_FRAGMENT } from './user';

const AUTO_POLICY_FRAGMENT = gql`
  fragment AutoPolicyParts on AutoPolicyType {
    basicSyncFailed
    canopyEmbeddedCarrier
    syncFailureReason
    wrongDocumentType
    namedInsured
    address
    sharedAccess {
      ...SharedAccessParts
    }
    user {
      ...UserParts
    }
    vehicles {
      id
      vehicleInfo
      vehicleVin
      year
      make
      model
      ownership
      primaryUse
      othersBodilyPerson
      othersBodilyAccident
      othersMedicalPayments
      othersPropertyAccident
      personalProtectionPerson
      uninsuredMotoristPerson
      uninsuredMotoristAccident
      uninsuredPropertyAccident
      uninsuredPropertyDeductible
      comprehensiveValue
      comprehensiveDeductible
      collisionValue
      collisionDeductible
      reimbursementDay
      roadsideAssistance
      roadsideAssistanceValue
      totalPremium
    }
    documents {
      ...DocumentParts
    }
    drivers {
      ...DriverParts
    }
  }
  ${DOCUMENT_FRAGMENT}
  ${DRIVER_FRAGMENT}
  ${SHARED_ACCESS_FRAGMENT}
`;

const HOME_POLICY_FRAGMENT = gql`
  fragment HomePolicyParts on HomePolicyType {
    user {
      ...UserParts
    }
    namedInsured
    address
    dwelling
    otherStructures
    personalProperty
    lossUse
    personalLiability
    medicalOthers
    deductible
    deductibleWind
    documents {
      ...DocumentParts
    }
    sharedAccess {
      ...SharedAccessParts
    }
    homeOwners {
      ...HomeOwnerParts
    }
    properties {
      id
      addressLine1
      addressLine2
      propertyCity
      propertyState {
        id
        name
      }
      propertyZipCode
      typeOfProperty
      typeOfResidence
      yearBuilt
      squareFootage
      construction
      foundation
      roof
      roofYearBuilt
      fireAlarm
    }
  }
  ${HOME_OWNER_FRAGMENT}
  ${SHARED_ACCESS_FRAGMENT}
`;

const LIFE_POLICY_FRAGMENT = gql`
  fragment LifePolicyParts on LifePolicyType {
    user {
      ...UserParts
    }
    namedInsured
    address
    planType
    otherTermYears
    deathBenefit
    conversionDate
    riders
    isGroup
    cashValue
    beneficiaries {
      ...BeneficiaryParts
    }
    sharedAccess {
      ...SharedAccessParts
    }
    documents {
      ...DocumentParts
    }
    lifeInsureds {
      ...LifeInsuredParts
    }
  }
  ${BENEFICIARY_FRAGMENT}
  ${LIFE_INSURED_FRAGMENT}
  ${SHARED_ACCESS_FRAGMENT}
`;

const PET_POLICY_FRAGMENT = gql`
  fragment PetPolicyParts on PetPolicyType {
    user {
      ...UserParts
    }
    address
    petParent
    petParentAddress
    petName
    petType
    petBreed
    petDob
    petSex
    hasBitten
    coinsurance
    reimbursement
    annualDeductible
    documents {
      ...DocumentParts
    }
    sharedAccess {
      ...SharedAccessParts
    }
    petOwners {
      id
      firstName
      lastName
      dob
      gender
      relationToMarbleMember
    }
  }
  ${SHARED_ACCESS_FRAGMENT}
`;

const RENTER_POLICY_FRAGMENT = gql`
  fragment RenterPolicyParts on RenterPolicyType {
    sharedAccess {
      ...SharedAccessParts
    }
    user {
      ...UserParts
    }
    namedInsured
    address
    personalProperty
    lossUse
    personalLiability
    medicalOthers
    deductible
    documents {
      ...DocumentParts
    }
    renters {
      ...RenterParts
    }
    rentals {
      id
      address
      addressLine
      city
      state {
        id
        name
      }
      zipCode
      typeOfProperty
    }
  }
  ${RENTER_FRAGMENT}
  ${SHARED_ACCESS_FRAGMENT}
`;

export const POLICY_FRAGMENT = gql`
  fragment PolicyParts on PolicyUnionType {
    ... on PolicyType {
      user {
        ...UserParts
      }
      id
      carrier {
        ...CarrierParts
      }
      policyHistory {
        id
        policyNumber
        startDate
        endDate
        total
        premiumPeriod
      }
      address
      isPolicyOwner
      isOnRenewal
      policyNumber
      policyPremium
      premiumPeriod
      fees
      total
      startDate
      endDate
      continuous
      synced
      coverageSynced
      category
      nextPaymentDue
      showSyncAnimation
      basicSyncFailed
      canopyEmbeddedCarrier
      syncFailureReason
      wrongDocumentType
      sharedAccess {
        ...SharedAccessParts
      }
      documents {
        ...DocumentParts
      }
    }
    ... on AutoPolicyType {
      id
      isOnRenewal
      isPolicyOwner
      carrier {
        ...CarrierParts
      }
      policyHistory {
        id
        policyNumber
        startDate
        endDate
        total
        premiumPeriod
      }
      policyNumber
      policyPremium
      premiumPeriod
      fees
      total
      startDate
      endDate
      continuous
      synced
      coverageSynced
      category
      nextPaymentDue
      showSyncAnimation
      basicSyncFailed
      canopyEmbeddedCarrier
      syncFailureReason
      wrongDocumentType
      ...AutoPolicyParts
    }
    ... on HomePolicyType {
      id
      isOnRenewal
      isPolicyOwner
      carrier {
        ...CarrierParts
      }
      policyHistory {
        id
        policyNumber
        startDate
        endDate
        total
        premiumPeriod
      }
      policyNumber
      policyPremium
      premiumPeriod
      fees
      total
      startDate
      endDate
      continuous
      synced
      coverageSynced
      category
      nextPaymentDue
      showSyncAnimation
      basicSyncFailed
      canopyEmbeddedCarrier
      syncFailureReason
      wrongDocumentType
      ...HomePolicyParts
    }
    ... on PetPolicyType {
      id
      isOnRenewal
      isPolicyOwner
      carrier {
        ...CarrierParts
      }
      policyHistory {
        id
        policyNumber
        startDate
        endDate
        total
        premiumPeriod
      }
      policyNumber
      policyPremium
      premiumPeriod
      fees
      total
      startDate
      endDate
      continuous
      synced
      coverageSynced
      category
      nextPaymentDue
      showSyncAnimation
      basicSyncFailed
      canopyEmbeddedCarrier
      syncFailureReason
      wrongDocumentType
      ...PetPolicyParts
    }
    ... on RenterPolicyType {
      id
      isOnRenewal
      isPolicyOwner
      carrier {
        ...CarrierParts
      }
      policyHistory {
        id
        policyNumber
        startDate
        endDate
        total
        premiumPeriod
      }
      policyNumber
      policyPremium
      premiumPeriod
      fees
      total
      startDate
      endDate
      continuous
      synced
      coverageSynced
      category
      nextPaymentDue
      showSyncAnimation
      basicSyncFailed
      canopyEmbeddedCarrier
      syncFailureReason
      wrongDocumentType
      ...RenterPolicyParts
    }
    ... on LifePolicyType {
      id
      isOnRenewal
      isPolicyOwner
      carrier {
        ...CarrierParts
      }
      policyHistory {
        id
        policyNumber
        startDate
        endDate
        total
        premiumPeriod
      }
      policyNumber
      policyPremium
      premiumPeriod
      fees
      total
      startDate
      endDate
      continuous
      synced
      coverageSynced
      category
      nextPaymentDue
      showSyncAnimation
      basicSyncFailed
      canopyEmbeddedCarrier
      syncFailureReason
      wrongDocumentType
      ...LifePolicyParts
    }
  }
  ${AUTO_POLICY_FRAGMENT}
  ${CARRIER_FRAGMENT}
  ${HOME_POLICY_FRAGMENT}
  ${PET_POLICY_FRAGMENT}
  ${RENTER_POLICY_FRAGMENT}
  ${LIFE_POLICY_FRAGMENT}
  ${SHARED_ACCESS_FRAGMENT}
  ${USER_FRAGMENT}
`;
