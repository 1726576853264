import { useQuery } from '@apollo/client';
import { FeaturesQuery } from 'graphql/generated';
import { FEATURES } from 'graphql/queries/feature';
import { normalizeFeature } from 'models/Feature';
import { useMemo } from 'react';

const useFeatures = () => {
  const { data, loading } = useQuery<FeaturesQuery>(FEATURES);

  const features = useMemo(
    () =>
      data?.featureItems
        ? data.featureItems.map(normalizeFeature).sort((a, b) => {
            if (a.isFeatured === b.isFeatured) return 0;
            return a.isFeatured ? -1 : 1;
          })
        : [],
    [data],
  );

  return {
    features,
    loading,
  };
};

export default useFeatures;
