const formatMoney = (input: number) => {
  if (Number.isInteger(input)) {
    return `$${input.toLocaleString('en', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    })}`;
  }

  return new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
  }).format(input);
};

export default formatMoney;
