import { gql } from '@apollo/client';
import { ON_POLICY_DATA_PULL_COMPLETED } from 'graphql/fragments/onPolicyDataPullCompleted';

// eslint-disable-next-line import/prefer-default-export
export const ON_USER_POLICY_CHANGE = gql`
  subscription OnPolicyDataPullCompleted {
    onPolicyDataPullCompleted {
      ...OnPolicyDataPullCompletedParts
    }
  }
  ${ON_POLICY_DATA_PULL_COMPLETED}
`;
