import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */
export const BANNERS = gql`
  query Banners($sourceType: SourceType!) {
    banners(source: $sourceType) {
      page
      text
    }
  }
`;
