import ClickAwayListener from 'react-click-away-listener';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  height: fit-content;
`;

export const Button = styled.button<{
  $alignLeft?: boolean;
  $disableOutline?: boolean;
}>`
  display: flex;
  margin-left: auto;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  border: none;
  align-items: center;
  gap: 0.25rem;

  &:focus {
    outline: 0.125rem solid ${({ theme }) => theme.colors.eastBay};
    outline-offset: 0.125rem;
  }

  ${({ $disableOutline }) =>
    $disableOutline &&
    `
    &:focus {
      outline: none;
      outline-offset: none;
    }
  `}

  ${({ $alignLeft }) =>
    $alignLeft &&
    `
    margin-left: inherit;
  `}
`;

export const TooltipBody = styled.div<{
  $isActive: boolean;
  $direction: string;
  $isMobile: boolean;
  $margin?: number;
}>`
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.bluewood};
  background-color: ${({ theme }) => theme.colors.white};
  border: 0.0625rem solid ${({ theme }) => theme.colors.desertStorm};
  border-radius: 0.375rem;
  display: inline;
  box-shadow: 0 0.9375rem 1.875rem -0.75rem rgba(148, 148, 146, 0.3);
  left: 50%;
  height: auto;
  width: 16.875rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 160%;
  position: absolute;
  padding: 1rem;
  z-index: 100;
  transform: translateX(-50%);
  white-space: pre-wrap;
  .bold {
    font-weight: 500;
  }

  ${({ $direction, $margin }) => {
    const margin = $margin && `${`${$margin}rem`}`;
    switch ($direction) {
      case 'top':
        return `
          margin-bottom: ${margin ?? '0.5rem'};
        `;
      case 'right':
        return css`
          margin-inline-start: ${margin ?? '0'};
          transform: translateX(0) translateY(-25%);
          inset-block-start: 50%;
          inset-inline: inherit 80%;
        `;
      case 'left':
        return css`
          inset-block-start: 50%;
          inset-inline-start: auto;
          transform: translateX(0) translateY(-25%);
        `;
      case 'bottomLeft':
        return css`
          margin-inline-start: -0.5rem;
          transform: translateX(0) translateY(25%);
          inset-inline-start: 0;
        `;
      case 'bottomExtraLeft':
        return css`
          margin-block-start: 2rem;
          transform: translateX(-50%) translateY(0);
          inset-inline-start: 0;
        `;
      case 'bottom':
      default:
        return `
          margin-top: ${margin ?? '2.25rem'};
        `;
    }
  }}

  ${({ $isActive }) =>
    $isActive &&
    `
      display: none;
  `}
  
  ${({ $isMobile }) =>
    $isMobile &&
    `
      top: 50%;
      transform: translateX(-95%) translateY(-15%);
    `}
`;

export const Wrapper = styled.div`
  display: contents;
`;

export const StyledClickAwayListener = styled(ClickAwayListener)`
  display: contents;
`;
