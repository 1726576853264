import useFeatures from 'graphql/hooks/useFeatures';
import usePolicies from 'graphql/hooks/usePolicies';
import useSyncedPolicyPull from 'graphql/hooks/usePolicyCanopyPull';
import useSyncedPolicySubscription from 'graphql/hooks/useSyncedPolicySubscription';

const useConnect = () => {
  const { features, loading: featuresLoading } = useFeatures();
  const { policies, loading: policiesLoading } = usePolicies();

  useSyncedPolicySubscription();
  useSyncedPolicyPull();

  return {
    features,
    hasPolicies:
      policies.length > 0 || policies.some((policy) => policy.isPolicyOwner),
    loading: featuresLoading || policiesLoading,
  };
};

export default useConnect;
