import { memo, useEffect, useState } from 'react';

import { Component, Container, InnerContainer, Title } from './styles';
import { Props } from './types';

const FullscreenLoader = ({ delay = 250, isFullPage = true, title }: Props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  return show ? (
    <Container $isFullPage={isFullPage}>
      <InnerContainer $isFullPage={isFullPage}>
        <Component />
        {title && <Title>{title}</Title>}
      </InnerContainer>
    </Container>
  ) : null;
};

export default memo(FullscreenLoader);
