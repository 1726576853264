import { useEffect } from 'react';
import useMe from 'graphql/hooks/useMe';
import { useLocation } from 'react-router-dom';
import {
  MOBILE_SHOP_PATH,
  REGISTER_CONFIRM_PATH,
  REGISTER_PATH,
  REGISTER_SHOP_FIRST_DETAILED_PATH,
  SHOP_QUOTE_PATH,
} from 'globals/constants';

export const Zendesk = () => {
  const { me } = useMe();
  const { pathname } = useLocation();

  useEffect(() => {
    if (me) {
      window.zE(
        'messenger',
        'loginUser',
        (callback: (arg?: string) => void) => {
          callback(me.zendeskToken);
        },
      );
    }
  }, [me]);

  useEffect(() => {
    // Hide ZenDesk chat on these exact pages
    const hidePaths = [
      MOBILE_SHOP_PATH,
      REGISTER_PATH,
      REGISTER_CONFIRM_PATH,
      SHOP_QUOTE_PATH,
    ];

    // Hide ZenDesk chat on any pages with these subpaths
    const hideSubPaths = [REGISTER_SHOP_FIRST_DETAILED_PATH];

    const matchesExactHidePath = hidePaths.includes(pathname);
    const containsHideSubPath = hideSubPaths.some((path) =>
      pathname.includes(path),
    );

    window.zE(() => {
      if (matchesExactHidePath || containsHideSubPath || !me) {
        window.zE('messenger', 'hide');
      } else if (me) {
        window.zE('messenger', 'show');
      }
    });
  }, [pathname, me]);

  return <></>;
};
