import {
  BodyMedium,
  OverlineBold,
  BodyBold as DefaultBodyBold,
} from 'components/Typography';
import styled from 'styled-components';
import { until } from 'styles/media';
import { ReactComponent as DefaultAutoPayIcon } from 'assets/images/icons/auto-pay.svg';
import { ReactComponent as DefaultAutoIcon } from 'assets/images/icons/auto.svg';
import { ReactComponent as DefaultHomeIcon } from 'assets/images/icons/home.svg';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem 1rem;
  padding-bottom: 3rem;
  border-block-end: 1px solid;
  border-color: ${({ theme }) => theme.colors.quillGray};
  div:first-child {
    grid-column: span 2;
  }
  ${until.mobile} {
    display: flex;
    flex-direction: column;
  }
`;

export const PriceAndLogoContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  padding-block-end: 2.5rem;
  border-block-end: 0.0625rem solid;
  border-color: ${({ theme }) => theme.colors.quillGray};
`;

export const OverviewContainer = styled.div`
  display: grid;
  gap: 1.5rem;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const PolicyTypeTitleContainer = styled.div`
  margin-right: auto;
`;

export const PolicyTypeTitle = styled(OverlineBold)`
  color: ${({ theme }) => theme.colors.starDust};
`;

export const PolicyType = styled(BodyMedium)``;

export const SectionTitle = styled(OverlineBold)``;

export const OverviewItem = styled.div`
  display: flex;
  align-items: center;
  *:first-child {
    margin-inline-end: 1rem;
  }
  svg {
    block-size: 1.25rem;
    inline-size: 1.25rem;
    stroke: ${({ theme }) => theme.colors.starDust};
    path {
      stroke: ${({ theme }) => theme.colors.starDust};
    }
  }
  p:last-of-type::after {
    white-space: pre;
    content: ' ';
  }
  p:last-of-type::before {
    white-space: pre;
    content: ' ';
  }
`;

export const AutoPayIcon = styled(DefaultAutoPayIcon)``;

export const AutoIcon = styled(DefaultAutoIcon)`
  path {
    stroke-width: 3;
  }
`;

export const HomeIcon = styled(DefaultHomeIcon)`
  path {
    stroke-width: 3;
  }
`;

export const IconContainer = styled.span`
  block-size: 1.25rem;
  inline-size: 1.25rem;
`;

export const ToolTipIconContainer = styled.span`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  svg {
    block-size: 1rem;
    inline-size: 1rem;
  }
`;

export const DetailsButton = styled.a`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
  color: ${({ theme }) => theme.colors.bluewood};
  font-weight: ${({ theme }) => theme.weights.semiBold};
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.white};
  border: 0.0625rem solid;
  border-color: ${({ theme }) => theme.colors.quillGray};
  border-radius: 0.375rem;
`;

export const DocumentIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.75rem;
  height: 3.75rem;
  background-color: ${({ theme }) => theme.colors.pampas};
  border-radius: 0.375rem;
  svg {
    block-size: 1.5rem;
    inline-size: 1.5rem;
  }
`;

export const BodyBold = styled(DefaultBodyBold)``;
