import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */

export const STATE_FRAGMENT = gql`
  fragment StateParts on StateType {
    id
    name
    abbreviation
  }
`;
