import { useReactiveVar } from '@apollo/client';
import { MODAL_FADE_DURATION } from 'globals/constants';
import { Modal, initialState, modals } from 'graphql/variables/modal';
import { useCallback, useEffect } from 'react';

const useModals = () => {
  const modal = useReactiveVar(modals);

  const animate = modal.isClosing;

  const openModal = useCallback((type: any, data?: Modal['data']) => {
    modals({
      ...initialState,
      active: [type, ...initialState.active],
      data,
      path: window.location.pathname,
    });
  }, []);

  const closeModal = useCallback(() => {
    const [, ...rest] = initialState.active;

    setTimeout(() => {
      modals({
        ...initialState,
        active: rest,
      });
    }, MODAL_FADE_DURATION);
    modals({ ...modal, isClosing: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transitionModal = useCallback((type: any) => {
    modals({
      ...initialState,
      active: [type],
    });
  }, []);

  const closeWithoutEffect = useCallback(() => {
    modals({
      ...initialState,
      active: [],
    });
  }, []);

  useEffect(() => {
    if (modal.path && modal.path !== window.location.pathname) {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    animate,
    closeModal,
    closeWithoutEffect,
    currentModal: modal.active.length > 0 ? modal.active[0] : undefined,
    openModal,
    optionalData: modal.data,
    transitionModal,
  };
};

export default useModals;
