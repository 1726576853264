/* eslint-disable import/prefer-default-export */
import {
  addDays,
  differenceInDays,
  differenceInSeconds,
  isWithinInterval,
} from 'date-fns';
import defaultFormatDistanceToNow from 'date-fns/formatDistanceToNow';
import { format, parse } from 'date-fns/fp';

// https://date-fns.org/docs/format
const DATE_SHORT_FORMAT = 'MMMM dd, yyyy'; // January 20, 2020
const DATE_SHORT_MONTH_FORMAT = 'MMM dd, yyyy'; // Jan 20, 2020
export const DATE_SLASH_FORMAT = 'P'; // 06/21/2020
const DATE_MONTH_FORMAT = 'MMMM'; // January
const BACKEND_FORMAT = 'yyyy-MM-dd'; // 2020-12-20

export const formatDateShort = format(DATE_SHORT_FORMAT);
export const formatDateShortMonth = format(DATE_SHORT_MONTH_FORMAT);
export const formatDateSlash = format(DATE_SLASH_FORMAT);
export const formatDateMonth = format(DATE_MONTH_FORMAT);
export const formatDateBackend = format(BACKEND_FORMAT);
export const formatDistanceToNow = (date: number) =>
  defaultFormatDistanceToNow(date, { addSuffix: true });

export const parseDateSlash = parse(new Date(), DATE_SLASH_FORMAT);
export const parseDateDash = parse(new Date(), BACKEND_FORMAT);

export const isUpcoming = (startDate: string) =>
  differenceInSeconds(new Date(startDate), new Date()) > 0;
export const isExpired = (endDate: string) =>
  differenceInSeconds(new Date(endDate), new Date()) < 0;

export const daysRemaining = (endDate: string) => {
  if (isExpired(endDate)) return undefined;
  const now = new Date();
  return differenceInDays(new Date(endDate), now) + 1;
};

const zeroPad = (num: number, digits: number) =>
  String(num).padStart(digits, '0');

export const formatDateSlashNoLocale = (date: Date) => {
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();

  return `${zeroPad(month, 2)}/${zeroPad(day, 2)}/${year}`;
};

export const isActive = (
  startDate: number | undefined,
  endDate: number | undefined,
) => {
  const today = new Date().getTime();
  if (endDate && startDate && endDate < startDate) return false;
  else if (startDate && startDate <= today) {
    return isWithinInterval(today, {
      end: endDate || addDays(today, 1),
      start: startDate,
    });
  } else {
    return false;
  }
};

export const subtractMonths = (date: Date, months: number) => {
  date.setMonth(date.getMonth() - months);
  return date;
};

export const isDateMoreThanYearsOld = (inputDate: Date, years = 1): boolean => {
  const currentDate = new Date();
  const oneYearAgo = new Date(
    currentDate.getFullYear() - years,
    currentDate.getMonth(),
    currentDate.getDate(),
  );

  return inputDate < oneYearAgo;
};
