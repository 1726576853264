import { FocusEvent, MouseEvent, useState } from 'react';
import { SegmentEvent, track } from 'services/segment';

const useConnect = (trackText: SegmentEvent | undefined) => {
  let timeout: number;
  const delay = 200;

  const [active, setActive] = useState(false);

  const showTip = (e: MouseEvent | FocusEvent) => {
    e.preventDefault();
    timeout = window.setTimeout(() => {
      setActive(true);
      if (trackText) {
        track(trackText);
      }
    }, delay);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  function createMarkup(text: string) {
    return { __html: text };
  }

  return {
    active,
    createMarkup,
    hideTip,
    showTip,
  };
};

export default useConnect;
