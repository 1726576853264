import styled, { css } from 'styled-components';
import addAlpha from 'utils/addAlpha';

import { ComponentProps } from './types';

/* eslint-disable import/prefer-default-export */

export const Component = styled.button<ComponentProps>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bluewood};
  border: none;
  display: flex;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 0.25rem;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fonts.inter};
  justify-content: center;
  line-height: 1.1875rem;
  letter-spacing: 0.03em;
  padding: 0 1rem;
  transition: background-color 500ms ease;
  text-decoration: none;

  & svg {
    inline-size: 1.25rem;
    block-size: 1.25rem;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover,
  &:focus {
    cursor: pointer;
  }

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      inline-size: 100%;
    `}

  ${({ $hasIcon, $iconPosition }) => {
    if ($hasIcon) {
      switch ($iconPosition) {
        case 'right':
          return css`
            flex-direction: row-reverse;

            & span {
              margin-inline-end: 0.75rem;
            }
          `;
        default:
        case 'left':
          return css`
            flex-direction: row;

            & span {
              margin-inline-start: 0.75rem;
            }
          `;
      }
    }

    return null;
  }}

    ${({ theme, $variant }) => {
    switch ($variant) {
      case 'secondary':
        return css`
          color: ${theme.colors.bluewood};
          background-color: transparent;
          border: 0.0625rem solid ${theme.colors.bluewood};

          &:hover,
          &:focus {
            background-color: ${addAlpha(theme.colors.bluewood, 0.06)};
          }
        `;
      case 'tertiary':
        return css`
          background-color: ${theme.colors.persimon};

          &:disabled {
            opacity: 0.6;
          }

          &:hover,
          &:focus {
            background-color: ${theme.colors.mojo};
          }
        `;
      case 'white':
        return css`
          color: ${theme.colors.bluewood};
          background-color: ${theme.colors.white};
        `;
      case 'green':
        return css`
          color: ${theme.colors.greenDark};
          background-color: ${theme.colors.greenSmooth};
        `;
      case 'primary':
      default:
        return css`
          &:hover,
          &:focus {
            background-color: ${theme.colors.mirage};
          }
        `;
    }
  }}

    ${({ $size }) => {
    switch ($size) {
      case 'small':
        return css`
          padding: 0.375rem 0.625rem;
          font-size: 0.875rem;
        `;
      default:
        return css`
          block-size: 2.75rem;
          font-size: 1rem;
        `;
    }
  }}
`;
