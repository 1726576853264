import { Color } from 'styles/themes';
import { ProgressModalContent } from './types';
import { AllCategories } from 'models/PolicyCategory';

// App
export const DAYS_TILL_REDEMPTION = 30;
export const MODAL_FADE_DURATION = 200;
export const FEEDBACK_TRACKER_DELAY = 1000;
export const AUTOCLOSE_MODAL = 2000;

export const isClickjacked = () => {
  return window.self !== window.top;
};

// REGEX
export const IS_PHONE =
  /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
export const STARTS_WITH_NUMBER = /^\d/;
export const IS_NUMBER = /[0-9]+/gi;

// global links
export const REFERRAL_TERMS_OF_SERVICE =
  'https://www.marblepay.com/referral-terms-of-use';
export const FAQ = 'https://joinmarble.zendesk.com/hc/en-us';
export const TERMS_OF_USE_LINK = 'https://www.marblepay.com/terms-of-use';

// PasswordLength
export const PASSWORD_LENGTH = 8;

export const PRIVACY_POLICY = 'https://www.marblepay.com/privacy-policy';
export const MARBLE_BLOG_URL = 'https://www.marblepay.com/blog';

// oauth providers
export const GOOGLE_PROVIDER = 'google-oauth2';
export const APPLE_PROVIDER = 'apple-id';

export const HOUSEHOLD_PROFILE = 'HOUSEHOLD_PROFILE';

// Insurance Types
export const AUTO = 'AUTO';
export const HOME = 'HOME';
export const RENTAL = 'RENTAL';
export const RENTER = 'RENTER';
export const PET = 'PET';
export const LIFE = 'LIFE';
export const OTHER = 'OTHER';
export const COMMERCIAL = 'COMMERCIAL';
export const TRAVEL = 'TRAVEL';
export const CYBER = 'CYBER';
export const JEWELRY = 'JEWELRY';

export const ALL_INSURANCE_TYPES = [AUTO, HOME, RENTER, PET, LIFE] as const;
export const HEALTH_INSURANCE_TYPES = ['HEALTH', 'DENTAL', 'VISION'] as const;
export const OTHER_INSURANCE_TYPES = [
  'UMBRELLA',
  'BOAT',
  'AD_D',
  'MOTORCYCLE',
  'COMMERCIAL',
  'GROUP_LIFE',
  'TRAVEL',
  'OTHER',
] as const;
export const SHOP_INSURANCE_TYPES = [
  AUTO,
  HOME,
  RENTER,
  PET,
  LIFE,
  OTHER,
] as const;
export const SHOP_INSURANCE_TYPES_NO_OTHER = [
  AUTO,
  HOME,
  RENTER,
  PET,
  LIFE,
] as const;

// User Motivations
export const MOTIVATIONS = {
  MANAGE: 'MANAGE',
  SHOP: 'SHOP',
};

// Policy Types

export const PREMIUM_MONTHLY = 'PREMIUM_MONTHLY';
export const PREMIUM_SIX = 'PREMIUM_SIX';
export const PREMIUM_5YEARS = 'PREMIUM_5YEARS';
export const PREMIUM_10YEARS = 'PREMIUM_10YEARS';
export const PREMIUM_15YEARS = 'PREMIUM_15YEARS';
export const PREMIUM_20YEARS = 'PREMIUM_20YEARS';
export const PREMIUM_25YEARS = 'PREMIUM_25YEARS';
export const PREMIUM_30YEARS = 'PREMIUM_30YEARS';
export const PREMIUM_YEARLY = 'PREMIUM_YEARLY';

export const ALL_PREMIUM_TYPES = [
  PREMIUM_MONTHLY,
  PREMIUM_SIX,
  PREMIUM_5YEARS,
  PREMIUM_10YEARS,
  PREMIUM_15YEARS,
  PREMIUM_20YEARS,
  PREMIUM_25YEARS,
  PREMIUM_30YEARS,
  PREMIUM_YEARLY,
];

export const ALL_INSURANCE_TYPES_COLORS: { [type: string]: Color } = {
  AUTO: 'persimon',
  HOME: 'eastBay',
  LIFE: 'pacificBlueDark',
  PET: 'goldenRod',
  RENTER: 'orange',
};

export const HEALTH_TYPES_COLORS: { [type: string]: Color } = {
  DENTAL: 'goldenRodDark',
  HEALTH: 'green',
  VISION: 'persimon',
};

export const otherCategoriesToShow = [
  'GROUP_LIFE',
  'UMBRELLA',
  'AD_D',
  'TRAVEL',
  'MOTORCYCLE',
  'BOAT',
  'COMMERCIAL',
];
export const CANOPY_CATEGORIES = [AUTO, HOME, RENTER];

// Marketing landing page types
export const PAGETYPE_ACCOUNT = 'ACCOUNT';
export const PAGETYPE_LEAD = 'LEAD';
export const PAGETYPE_ACCOUNT_SHOP = 'ACCOUNT_SHOP';
export const PAGETYPE_LEAD_SHOP = 'LEAD_SHOP';
export const PAGETYPE_LEAD_REDIRECT = 'LEAD_REDIRECT';

// Page Names
export const LOGIN_PAGE = 'LOGIN_PAGE';
export const WALLET = 'WALLET';
export const WALLET_PENDING_BASIC_SYNC = 'WALLET_PENDING_BASIC_SYNC';
export const INSURANCE_INSIDER = 'INSURANCE_INSIDER';
export const REGISTER_PAGE = 'REGISTER_PAGE';
export const REWARDS = 'REWARDS';
export const SHOPPING = 'SHOPPING';

// Routes
export const ARCHIVED_POLICIES_PATH = '/archived';
export const HOME_PATH = '/';
export const INSURANCE_INSIDER_PATH = '/insurance-insider';
export const MARKETING_LANDING_PATH = '/with';
export const MOBILE_SHOP_PATH = '/shop-mobile';
export const OTHER_POLICIES_PATH = '/other-policies';
export const POLICIES_PATH = '/policies';
export const REFER_FIVE_PATH = '/refer5';
export const REGISTER_PATH = '/register';
export const REGISTER_CONFIRM_PATH = '/register/confirm';
export const REGISTER_SHOP_FIRST_PATH = '/register/shop';
export const REGISTER_SHOP_FIRST_DETAILED_PATH = '/register/shop-form';
export const SHOP_QUOTE_PATH = '/shop/quote';

export const REGISTER_SHOP_FIRST_PATH_NEW_POLICY_LOGIN =
  '/register/shop/policies/new/login';

export const REGISTER_SHOP_FIRST_PATH_NEW_POLICY_LOGIN_SUCCESS =
  '/register/shop/policies/new/login/success';

export const REGISTER_SHOP_FIRST_PATH_CONFIRM_PERSONAL_INFO =
  '/register/shop/confirm-info';

export const REGISTER_SHOP_FIRST_PATH_CONFIRM_HOUSEHOLD_INFO =
  '/register/shop/confirm-household';

export const REGISTER_SHOP_FIRST_SKELETON_EMAIL = '@marbleshop.com';

export const REGISTER_GET_QUOTE_WAIT_TIME = 5000;
export const CANOPY_ANIMATION_ANIMATION_TIME = 4000;
export const CANOPY_CIRCLE_LOADER_ANIMATION_TIME = 8000;

export const REWARDS_PATH = '/rewards';
export const PAYMENT_PATH = '/payment';
export const SETTINGS_PATH = '/settings';
export const SHARE_POLICY_PATH = '/shareable';
export const SHOP_PATH = '/shop';
export const APP_PATH = 'https://click.marblepay.com/1wio/marble';
export const WHATS_NEW_PATH = '/whats-new';
export const HOUSEHOLD_PATH = '/settings/your-household?display=editHousehold';
export const MARKETPLACE_PATH = '/marketplace';
export const PERKS_PATH = '/perks';

// paths by attribute
export const ID_ATTRIBUTE = '/:id';
export const WILDCARD_PATH = '/*';
export const LOGIN_PATH = '/login';
export const NEW_PATH = '/new';
export const UPLOAD_PATH = '/upload';
export const SUCCESS_PATH = '/success';
export const ERROR_PATH = '/error';
export const HEALTH_PATH = '/health';

export const REGISTER_CONFIRM_NESTED_PATH = 'confirm';
export const REGISTER_SHOP_FIRST_NESTED_PATH = 'shop';
export const REGISTER_SHOP_FIRST_DETAILED_NESTED_PATH = 'shop-form';

// composite routes
export const NEW_ONBOARDING_PATH = '/new/onboarding';
export const NEW_POLICY = `${POLICIES_PATH}${NEW_PATH}`;
export const NEW_POLICY_UPLOAD = `${POLICIES_PATH}${NEW_PATH}${UPLOAD_PATH}`;
export const NEW_POLICY_LOGIN = `${POLICIES_PATH}${NEW_PATH}${LOGIN_PATH}`;
export const NEW_POLICY_LOGIN_SUCCESS = `${POLICIES_PATH}${NEW_PATH}${LOGIN_PATH}${SUCCESS_PATH}`;
export const NEW_LOGIN = `${NEW_PATH}${LOGIN_PATH}`;
export const NEW_HEALTH_POLICY = `${POLICIES_PATH}${NEW_PATH}${HEALTH_PATH}`;
export const NEW_ONBOARDING_LOGIN = `${NEW_ONBOARDING_PATH}${POLICIES_PATH}${NEW_LOGIN}`;
export const NEW_ONBOARDING_UPLOAD = `${NEW_ONBOARDING_PATH}${POLICIES_PATH}${NEW_PATH}${UPLOAD_PATH}`;
export const NEW_UPLOAD_SUCCESS = `${POLICIES_PATH}${NEW_PATH}${UPLOAD_PATH}${SUCCESS_PATH}`;

export const MODAL_ROUTES = [
  { path: `${OTHER_POLICIES_PATH}${WILDCARD_PATH}` },
  { path: `${POLICIES_PATH}${NEW_PATH}` },
];
export const POLICY_SUBROUTES = [
  { path: `${POLICIES_PATH}${NEW_PATH}${WILDCARD_PATH}` },
  { path: `${POLICIES_PATH}${UPLOAD_PATH}${WILDCARD_PATH}` },
  { path: `${POLICIES_PATH}${SUCCESS_PATH}` },
  { path: `${POLICIES_PATH}${ERROR_PATH}` },
  { path: `${SHARE_POLICY_PATH}${WILDCARD_PATH}` },
];

export const BANNER_PATHS = {
  [HOME_PATH]: [LOGIN_PAGE, WALLET, WALLET_PENDING_BASIC_SYNC],
  [INSURANCE_INSIDER_PATH]: [INSURANCE_INSIDER],
  [REGISTER_PATH]: [REGISTER_PAGE],
  [REWARDS_PATH]: [REWARDS],
  [SHOP_PATH]: [SHOPPING],
};

export const APP_ROUTES = [{ path: `${MOBILE_SHOP_PATH}${WILDCARD_PATH}` }];

export const HIDE_FOOTER_ROUTES = [
  { path: `${SHARE_POLICY_PATH}${WILDCARD_PATH}` },
  { path: `${MOBILE_SHOP_PATH}${WILDCARD_PATH}` },
];

export const ONBOARDING_ROUTES = [
  { path: `${NEW_ONBOARDING_PATH}${WILDCARD_PATH}` },
];

// Onboarding
export const POLICY_STEP = 'POLICY';
export const SUCCESS_STEP = 'SUCCESS';
export const UPLOAD_STEP = 'UPLOAD';
export const SKIP = 'SKIP';

export const ONBOARDING_EVENT_NAMES = {
  AUTO: 'Onboarding Q1Auto',
  HOME: 'Onboarding Q2Home',
  PET: 'Onboarding Q3Pet',
  RENTAL: 'Onboarding Q2bRenters',
};

// Archive Policy
export const ARCHIVE_REASON = {
  USER_ARCHIVE_CANCELED: 'USER_ARCHIVE_CANCELED',
  USER_ARCHIVE_CANCELED_REPLACED: 'USER_ARCHIVE_CANCELED_REPLACED',
  USER_ARCHIVE_DO_NOT_WANT: 'USER_ARCHIVE_DO_NOT_WANT',
  USER_ARCHIVE_OTHER: 'USER_ARCHIVE_OTHER',
  USER_REQUEST_FIX: 'USER_REQUEST_FIX',
};

export const ARCHIVE_REASON_OPEN_ZENDESK = [
  ARCHIVE_REASON.USER_ARCHIVE_DO_NOT_WANT,
  ARCHIVE_REASON.USER_REQUEST_FIX,
];

export const REMOVE = 'REMOVE';
export const REPLACE = 'REPLACE';
export const ISSUE = 'ISSUE';

export const CALL = 'CALL';
export const TEXT = 'TEXT';
export const EMAIL = 'EMAIL';

export const REMINDER_METHOD_TYPES = {
  EMAIL_METHOD: EMAIL,
  TEXT_METHOD: TEXT,
};

// Feedback tracker actions
export const POLICY_SYNC = 'POLICY_SYNC';
export const II_RATING = 'II_RATING';
export const REDEMPTION_COMPLETE = 'REDEMPTION_COMPLETE';

export const NAMED_INSURED_TEXT = {
  AUTO: 'Driver',
  HOME: 'Home Owner',
  LIFE: 'Life Insured',
  PET: 'Pet Owner',
  RENTER: 'Renter',
};

// Cache history state
export const UPLOAD_MODE = {
  CANOPY: 'CANOPY',
  MANUAL: 'MANUAL',
} as const;

export const ACCOUNT = 'ACCOUNT';
export const POLICY = 'POLICY';
export const ENGAGEMENT = 'ENGAGEMENT';
export const PROFILE = 'PROFILE';
export const REVIEW = 'REVIEW';
export const SHOP = 'SHOP';
export const APP = 'APP';

export const COMPLETED = 'COMPLETED';
export const SYNC_PENDING = 'SYNC PENDING';
export const PENDING = 'PENDING';

export const DOCUMENT_DEC = 'DOCUMENT_DEC';
export const DOCUMENT_ID = 'DOCUMENT_ID';
export const DOCUMENT_OTHER = 'DOCUMENT_OTHER';

// Progress Bar

const account: ProgressModalContent = {
  completedDescription: `We're thrilled that you’ve joined us on Marble! Get ready to manage, shop, and earn rewards on your existing insurance policies, all in one seamless digital wallet!`,
  description: '',
  directions: '',
  text: 'Create your account',
};

const policy: ProgressModalContent = {
  button: 'Import a policy',
  completedDescription:
    'Congrats on importing your first policy! Stay organized by importing all active insurance policies to your Marble Wallet.',
  description:
    'Import your policies to your Marble Wallet to be notified of upcoming renewal dates and potential rate increases.',
  directions: 'Import your first policy',
  minutesToComplete: 2,
  rewards: 500,
  syncPendingDescription: `We've got your upload! Once we securely confirm your policy, you'll be able to view your coverage details in your Marble Wallet.`,
  text: 'Import your first policy',
};

const engagement: ProgressModalContent = {
  button: "Let's start",
  description:
    'At the start of every month, you’ll find new questions you can answer to test your insurance knowledge, earn Marbles, and teach you something new.',
  directions: 'Learn something new',
  minutesToComplete: 1,
  rewards: 100,
  text: 'Complete an action card',
};
const profile: ProgressModalContent = {
  button: 'Complete my profile',
  completedDescription: 'Your profile info is now securely stored.',
  completedDirections: 'Profile Complete',
  description:
    'Securely set up your profile to optimize your Marble experience.',
  directions: 'Finish setting up your profile',
  minutesToComplete: 1,
  text: 'Update your personal info',
};
const householdProfile: ProgressModalContent = {
  button: 'Review household profile',
  completedDescription:
    'Your household profile reflects the family members who are also covered by your insurance.',
  completedDirections: 'Confirm your household details',
  description:
    'Your household profile reflects the family members who are also covered by your insurance.',
  directions: 'Review my household profile',
  minutesToComplete: 1,
  text: 'Confirm your household details',
};

const shop: ProgressModalContent = {
  button: 'Take me there',
  description:
    'Confirm that you have the best rate for your insurance needs, and view special offers from our partners!',
  directions: 'Shop the Marble marketplace',
  minutesToComplete: 2,
  rewards: 100,
  text: 'Explore the marketplace',
};

const app: ProgressModalContent = {
  button: 'Open in app',
  description: 'Log into the Marble app to access your insurance on-the-go.',
  directions: 'Marble on the go',
  minutesToComplete: 2,
  rewards: 100,
  text: 'Download the App',
};

export const PROGRESS_STATUS_DETAILS: Record<string, ProgressModalContent> = {
  [ACCOUNT]: account,
  [APP]: app,
  [ENGAGEMENT]: engagement,
  [HOUSEHOLD_PROFILE]: householdProfile,
  [POLICY]: policy,
  [PROFILE]: profile,
  [SHOP]: shop,
};

// NamedInsured

export const NAMED_INSURED_RELATIONSHIP_SELF = 'SELF';
export const NAMED_INSURED_RELATIONSHIP_OTHER = 'OTHER';
export const NAMED_INSURED_TYPE_DRIVER = 'DRIVER';
export const NAMED_INSURED_TYPE_HOME_OWNER = 'HOME_OWNER';
export const NAMED_INSURED_TYPE_RENTER = 'RENTER';

// Offers
export const RENEWAL = 'RENEWAL';
export const CALL_AGENT = 'CALL_AGENT';
export const FAST_QUOTE = 'FAST_QUOTE';
export const MORE_INFO = 'MORE_INFO';

export const OFFER_CATEGORY_QUOTES = 'all offers';
export const OFFER_CATEGORY_SAVED = 'saved';
export const OFFER_CATEGORY_ARCHIVED = 'archived';
export const STANDARD = 'STANDARD';

export const COVERAGE_BETTER = 'BETTER';
export const COVERAGE_WORSE = 'WORSE';
export const COVERAGE_SIMILAR = 'SIMILAR';

export const PARTNER_MATIC = 'MATIC';
export const PARTNER_NFP = 'NFP';
export const PARTNER_VIU = 'VIU by HUB';
export const PARTNER_ZEBRA = 'The Zebra';
export const MARBLE = 'MARBLE';
export const MARBLE_AGENCY_INTEGRATORS = [
  'Marble Agency',
  'MarbleviaNFP',
  'RPS',
  'EZLynx',
  'Marble Agency via EZAPI',
];

export const PARTNER_ZEBRA_FALLBACK_URL = 'https://www.thezebra.com';

// Cobranding
export const LOGO_DIMO =
  'http://info.marblepay.com/media/partner_logos/DIMO_Logo_Black.png';
export const LOGO_SOLO =
  'http://info.marblepay.com/media/partner_logos/solo_logo.png';
export const LOGO_VALON =
  'http://info.marblepay.com/media/partner_logos/logo-valon.png';
export const LOGO_OPENBAY =
  'http://info.marblepay.com/media/partner_logos/openbay.svg';

export const DISCLAIMER_TEXT = `
<p>We have legal information and disclosures down here. They may or may not be interesting to you, but they are most certainly important.</p> 
<p>Insurance quotes and policies are offered through Marble Insurance Agency Inc. A New York Resident Producer Agency. P&C License # PC-1743470. Life, Accident, and Health License # LA-1743470. Here is a <a href="https://marble-backend-production.s3.amazonaws.com/media/MarbleAgencyLicense%23s.pdf" rel="noopener nofollow" target="_blank">full list</a> of Marble Insurance Agency Inc.’s license numbers.</p>
<p>We work hard to make sure that the information our members see is accurate. However, any insurance prices quoted or ranges displayed are not binding or final. The final insurance policy premium is determined by the insurance company that is underwriting the policy, reflecting the information in your finished application.</p>
<p>Additionally, you may see placements and offers throughout the Marble app. These are from third-party advertisers or affiliates. These affiliates include Matic and NFP InsurChoice.</p>
<p>Marble Pay Inc, is a family of companies. Marble Pay Inc provides insurance technology services, and is not an agent, broker, or representative of any advertiser; Marble Insurance Agency Inc provides property & casualty and life insurance quotes and policies.</p>`;

export const SUPPORT_MAIL_ADDRESS = 'support@marblepay.com';
export const AGENCY_MAIL_ADDRESS = 'agencyteam@marblepay.com';

export const SUPPORT_DISCLAIMER_TEXT = `
    Although we work hard to gather complete and accurate information about your
    insurance, we make no warranties or representations as to the accuracy of
    the insurance policy information displayed here. Marble assumes no liability
    or responsibility for any error or omissions in the information contained or
    displayed. Please refer to the policy documents provided to you by your
    insurance company for the most accurate information about your insurance. If
    you notice any incorrect information here, we would love to correct it for
    you! Please notify us at
    <a href=mailto:${SUPPORT_MAIL_ADDRESS}>${SUPPORT_MAIL_ADDRESS}</a>.
`;

export const DISABLED_NAME_INPUTS_MESSAGE =
  'Legal Names cannot be edited once they are set. Please reach out to Marble Support for any modifications';

export const CATEGORY_PARTNER_PAGES: {
  category: AllCategories;
  link: string;
}[] = [
  {
    category: RENTER,
    link: 'http://insurchoice.marble.getcoveredinsurance.com',
  },
  {
    category: PET,
    link: 'https://fletch.co/marble/',
  },
  {
    category: LIFE,
    link: 'https://partners.policygenius.com/pro/referral/marble?&uuid=f3f9bbb2-ae94-4cb1-8ed2-6d5b237c8c23',
  },
  {
    category: COMMERCIAL,
    link: 'https://commercial.marblepay.com',
  },
  {
    category: TRAVEL,
    link: 'https://www.withfaye.com/?utm_medium=gd-partnerships&utm_source=marble&utm_campaign=general_us&utm_content=getquote',
  },
  {
    category: CYBER,
    link: 'https://usportal.boxxinsurance.com/us/home/MarblePay/init',
  },
  {
    category: JEWELRY,
    link: 'https://my.jewelersmutual.com/jewelry-insurance-quote-apply/Partner?partnerName=Marble&utm_source=marble-get-a-quote-flow',
  },
];

export const CUSTOMER_TESTIMONIAL = {
  name: 'Jenny Wilson',
  quote: `“I love using the Marble app. They compare my insurance and look for
better rates for me. They do the work so I don't have too!”`,
  rating: 4.9,
};

export const CALENDLY_LINK = 'https://calendly.com/marble-agency/offer-details';

// Stock Images
export const LEARNING_STOCK_IMAGE =
  'https://info.marblepay.com/media/stock_images/close-up-hand-taking-notes.png';
export const SAVING_STOCK_IMAGE =
  'https://info.marblepay.com/media/stock_images/hand-putting-coin-piggy-bank-growth-chart.png';

export const SHOPPING_CART_EXPLAINER_CARD_STATUS = {
  INFO_NEEDED: 'INFO_NEEDED',
  LOOKING_FOR_SAVINGS: 'LOOKING_FOR_SAVINGS',
  REVIEW_YOUR_OFFERS: 'REVIEW_YOUR_OFFERS',
};

export const ZEBRA_ACQUISITION_LEARN_MORE_LINK =
  'https://www.marblepay.com/blog/announcing-marble-is-acquired-by-the-zebra';
