import { MenuButton, MenuPopover } from '@reach/menu-button';
import { positionRight } from '@reach/popover';
import styled from 'styled-components';
import addAlpha from 'utils/addAlpha';

export const Content = styled(MenuPopover).attrs(() => ({
  position: positionRight,
}))`
  z-index: ${({ theme }) => theme.zIndex.dropdown};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-width: 12.625rem;
  max-width: 22rem;
  margin-top: 0.625rem;
  padding: 1rem 2rem;
  background: ${({ theme }) => theme.colors.white};
  border: 0.0625rem solid ${({ theme }) => theme.colors.desertStorm};
  border-radius: 0.375rem;
  box-shadow: 0 1.125rem 2.25rem -0.75rem ${({ theme }) => addAlpha(theme.colors.starDust, 0.16)};

  &[hidden] {
    display: none;
  }
`;

export const Trigger = styled(MenuButton)`
  display: flex;
  padding: 0;
  background-color: transparent;
  border: none;
`;
