import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */
export const REWARDS = gql`
  query Rewards {
    rewards {
      title
      identifier
      marbleValue
    }
  }
`;
