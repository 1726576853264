import '@reach/dialog/styles.css';

import App from 'containers/App';
import { isClickjacked } from 'globals/constants';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

declare global {
  interface Window {
    grecaptcha: any;
    analytics: any;
    zE: any;
  }
}

ReactDOM.render(
  <StrictMode>
    {!isClickjacked() ? (
      <App />
    ) : (
      <div>
        Please visit us directly by entering https://marblepay.com into your
        browser!
      </div>
    )}
  </StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
