import { memo } from 'react';
import ClickAwayListener from 'react-click-away-listener';

import Logo from 'components/Logo';
import {
  HOME_PATH,
  INSURANCE_INSIDER_PATH,
  MARBLE_BLOG_URL,
  PERKS_PATH,
  REWARDS_PATH,
  SHOP_PATH,
} from 'globals/constants';

import Menu from './Menu';
import useConnect from './connect';
import {
  Avatar,
  Bar,
  Chevron,
  ClickOutIcon,
  Container,
  CurrentRoute,
  Dropdown,
  ExternalLink,
  Link,
  Links,
  LockIcon,
  LogoLink,
  MenuContainer,
} from './styles';
import { Props } from './types';
import { track } from 'services/segment';

const Mobile = ({
  avatarUrl,
  firstName,
  initials,
  lastName,
  onHomeClick,
  onShopClick,
  showWhatsNew,
  viewAs,
}: Props) => {
  const {
    currentRouteName,
    closeRoutesSelector,
    openRoutesSelector,
    handleCloseMenu,
    handleClickAwayListener,
    handleDropdownOpen,
    handleHomeRedirect,
    handleLogout,
    handleOpenMenu,
    handleRewardDropdownOpen,
    handleRewardsClick,
    handleShopClick,
    isDropdownOpen,
    isLocked,
    isMenuOpen,
    isRewardsDropdownOpen,
    isShoppingReady,
    pathname,
    routesSelectorOpen,
  } = useConnect({
    onHomeClick,
    onShopClick,
  });

  return (
    <ClickAwayListener onClickAway={handleClickAwayListener}>
      <Container>
        <Bar>
          <LogoLink to="/">
            <Logo isBeta variant="isotype" withColor />
          </LogoLink>
          {routesSelectorOpen ? (
            <CurrentRoute onClick={closeRoutesSelector}>
              <span>Select</span>
              <Chevron $isOpen />
            </CurrentRoute>
          ) : (
            <CurrentRoute onClick={openRoutesSelector}>
              <span>{currentRouteName}</span>
              <Chevron $isOpen={false} />
            </CurrentRoute>
          )}
          <Avatar
            initials={initials}
            onClick={handleOpenMenu}
            url={avatarUrl}
          />
          {isMenuOpen ? (
            <Menu
              firstName={firstName}
              handleLogout={handleLogout}
              showWhatsNew={showWhatsNew}
              initials={initials}
              lastName={lastName}
              onClose={handleCloseMenu}
              viewAs={viewAs}
            />
          ) : null}
        </Bar>
        {routesSelectorOpen ? (
          <Links>
            <Link
              $active={pathname === HOME_PATH}
              to="/"
              onClick={handleHomeRedirect}
            >
              Home
            </Link>
            <Dropdown
              $isOpen={isRewardsDropdownOpen}
              onClick={handleRewardDropdownOpen}
            >
              <MenuContainer>
                <span>Rewards</span>
                <Chevron $isDropdown $isOpen={isRewardsDropdownOpen} />
              </MenuContainer>
              {isRewardsDropdownOpen && (
                <>
                  <Link
                    $active={pathname === PERKS_PATH}
                    $isDropdown
                    onClick={closeRoutesSelector}
                    to={PERKS_PATH}
                  >
                    Perks & Discounts
                  </Link>
                  <Link
                    $active={pathname === REWARDS_PATH}
                    $isDropdown
                    onClick={handleRewardsClick}
                    to={REWARDS_PATH}
                  >
                    Rewards&nbsp;{isLocked && <LockIcon />}
                  </Link>
                </>
              )}
            </Dropdown>
            <Dropdown $isOpen={isDropdownOpen} onClick={handleDropdownOpen}>
              <MenuContainer>
                <span>Resources</span>
                <Chevron $isDropdown $isOpen={isDropdownOpen} />
              </MenuContainer>
              {isDropdownOpen && (
                <>
                  <Link
                    $active={pathname === INSURANCE_INSIDER_PATH}
                    $isDropdown
                    onClick={closeRoutesSelector}
                    to="/insurance-insider"
                  >
                    Insurance Insider
                  </Link>
                  <ExternalLink
                    href={MARBLE_BLOG_URL}
                    rel="noopener noreferrer"
                    target="_blank"
                    onClick={() => track('Navbar - Blog Link Clicked')}
                  >
                    Blog <ClickOutIcon />
                  </ExternalLink>
                </>
              )}
            </Dropdown>
            {isShoppingReady && (
              <Link
                $active={pathname === SHOP_PATH}
                to="/shop"
                onClick={handleShopClick}
              >
                Shop
              </Link>
            )}
          </Links>
        ) : null}
      </Container>
    </ClickAwayListener>
  );
};

export default memo(Mobile);
