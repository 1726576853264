import {
  AUTO,
  CYBER,
  HOME,
  JEWELRY,
  LIFE,
  OTHER,
  PET,
  RENTER,
} from 'globals/constants';

type Input = string | null | undefined;

export const normalizeOtherPolicyCategory = (input: Input) => {
  const category = (input || '').toUpperCase();

  switch (category) {
    case 'BOAT':
      return 'BOAT';
    case 'DENTAL':
      return 'DENTAL';
    case 'HEALTH':
      return 'HEALTH';
    case 'LIFE':
      return 'LIFE';
    case 'MOTORCYCLE':
      return 'MOTORCYCLE';
    case 'OTHER':
      return 'OTHER';
    case 'TRAVEL':
      return 'TRAVEL';
    case 'UMBRELLA':
      return 'UMBRELLA';
    case 'VISION':
      return 'VISION';
    case 'COMMERCIAL':
      return 'COMMERCIAL';
    case 'GROUP_LIFE':
      return 'GROUP_LIFE';
    case 'AD_D':
    case 'AD&D':
      return 'AD_D';
    default:
      return undefined;
  }
};

export const normalizePolicyCategory = (input: Input) => {
  const category = (input || '').toUpperCase();

  switch (category) {
    case 'AUTO':
      return AUTO;
    case 'HOME':
      return HOME;
    case 'PET':
      return PET;
    case 'RENTER':
      return RENTER;
    case 'LIFE':
      return LIFE;
    default:
      return undefined;
  }
};

export const normalizeHealthCategory = (input: Input) => {
  const category = (input || '').toUpperCase();

  switch (category) {
    case 'HEALTH':
      return 'HEALTH';
    case 'DENTAL':
      return 'DENTAL';
    case 'VISION':
      return 'VISION';
    default:
      return undefined;
  }
};

export type OtherPolicyCategory = NonNullable<
  ReturnType<typeof normalizeOtherPolicyCategory>
>;

export type PolicyCategory = NonNullable<
  ReturnType<typeof normalizePolicyCategory>
>;

export type ShopFirstPolicyCategory = Exclude<PolicyCategory, 'LIFE'>;

export type HealthPolicyCategory = NonNullable<
  ReturnType<typeof normalizeHealthCategory>
>;

export type OtherPolicyCategoryRestringed = Exclude<
  OtherPolicyCategory,
  'LIFE' | 'HEALTH' | 'DENTAL' | 'VISION' | 'CYBER'
>;

export type AllCategories =
  | PolicyCategory
  | HealthPolicyCategory
  | OtherPolicyCategoryRestringed
  | typeof OTHER
  | typeof CYBER // FE Only Category
  | typeof JEWELRY; // FE Only Category
