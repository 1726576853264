import { useState } from 'react';

const useConnect = () => {
  const [clicked, setClicked] = useState(false);

  const handleFirstClick = () => {
    if (!clicked) setClicked(true);
  };

  return {
    clicked,
    handleFirstClick,
  };
};

export default useConnect;
