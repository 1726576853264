import { gql } from '@apollo/client';

export const PAYMENT_DETAILS_FRAGMENT = gql`
  fragment PaymentDetailsParts on PaymentDetailsType {
    paymentToken
    paymentMethod
    accountNumber
    routingNumber
    expiryYear
    expiryMonth
    cardBrand
    cardType
  }
`;

export const PAYMENT_PAGE_FRAGMENT = gql`
  fragment PaymentPageParts on PaymentPageType {
    offerId
    email
    isPaymentSubmitted
    termMonths
    expiryDate
    category
    carrier
    carrierLogo
    offerDetails
    namedInsureds
    assetName
    monthlyPremium
    paymentDetails {
      ...PaymentDetailsParts
    }
  }
  ${PAYMENT_DETAILS_FRAGMENT}
`;
