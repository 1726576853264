import useAuth from 'graphql/hooks/useAuth';
import useMe from 'graphql/hooks/useMe';
import useModals from 'graphql/hooks/useModals';
import useSiteConfiguration from 'graphql/hooks/useSiteConfiguration';
import { ModalEnumTypes } from 'graphql/variables/modal';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { useMatch, useLocation, useNavigate } from 'react-router-dom';
import { track } from 'services/segment';

import { ConnectProps } from './types';
import usePolicies from 'graphql/hooks/usePolicies';

const useConnect = ({ onHomeClick, onShopClick }: ConnectProps) => {
  const { logout } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { me } = useMe();
  const { featureToggles } = useSiteConfiguration();
  const { openModal } = useModals();
  const { policies } = usePolicies({ variables: { isCurrent: true } });
  const rewardsRoute = useMatch('/rewards');
  const homeRoute = useMatch('/');
  const insuranceInsiderRoute = useMatch('/insurance-insider');
  const policiesRoute = useMatch('/policies/*');
  const settingsRoute = useMatch('/settings');
  const whatsNewRoute = useMatch('/whats-new');
  const shopRoute = useMatch('/shop/*');
  const perksRoute = useMatch('/perks');
  const [routesSelectorOpen, setRoutesSelectorOpen] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isRewardsDropdownOpen, setRewardsIsDropdownOpen] = useState(false);

  const currentRouteName = useMemo(() => {
    if (rewardsRoute || perksRoute) {
      return 'Rewards';
    }

    if (homeRoute) {
      return 'Home';
    }

    if (insuranceInsiderRoute) {
      return 'Insurance Insider';
    }

    if (policiesRoute) {
      return 'Policy';
    }

    if (settingsRoute) {
      return 'Settings';
    }

    if (whatsNewRoute) {
      return "What's new";
    }

    if (shopRoute) {
      return 'Shop';
    }

    return '-';
  }, [
    rewardsRoute,
    homeRoute,
    insuranceInsiderRoute,
    policiesRoute,
    settingsRoute,
    whatsNewRoute,
    shopRoute,
    perksRoute,
  ]);

  const openRoutesSelector = useCallback(() => {
    setRoutesSelectorOpen(true);
  }, [setRoutesSelectorOpen]);

  const closeRoutesSelector = useCallback(() => {
    setRoutesSelectorOpen(false);
    setIsDropdownOpen(false);
  }, [setRoutesSelectorOpen]);

  const handleClickAwayListener = useCallback(
    () => (routesSelectorOpen ? closeRoutesSelector : () => {}),
    [routesSelectorOpen, closeRoutesSelector],
  );

  const handleCloseMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, [setIsMenuOpen]);

  const handleLogout = useCallback(() => logout(), [logout]);

  const isLocked =
    policies.length === 0 || !policies.some((policy) => policy.isPolicyOwner);

  const handleRewardsClick = useCallback(
    (event: any) => {
      event.preventDefault();
      if (me && !me.isViewedAs) {
        track('Navbar - Clicked', {
          tab: 'BALANCE',
        });
      }

      if (isLocked) {
        openModal(ModalEnumTypes.BALANCE);
      } else {
        navigate('/rewards');
        closeRoutesSelector();
      }
    },
    [me, isLocked, openModal, navigate, closeRoutesSelector],
  );

  const handleHomeRedirect = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      onHomeClick();
      setRoutesSelectorOpen(false);
      navigate('/');
    },
    [onHomeClick, navigate, setRoutesSelectorOpen],
  );

  const handleOpenMenu = useCallback(() => {
    if (me && !me.isViewedAs) track('Avatar - Clicked');
    setIsMenuOpen(true);
  }, [me, setIsMenuOpen]);

  const isShoppingReady = featureToggles?.find(
    (toggle) => toggle.name === 'SHOPPING_NAVBAR',
  );

  const handleDropdownOpen = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleRewardDropdownOpen = () => {
    setRewardsIsDropdownOpen(!isRewardsDropdownOpen);
  };

  const handleShopClick = useCallback(() => {
    onShopClick();
    closeRoutesSelector();
  }, [onShopClick, closeRoutesSelector]);

  return {
    closeRoutesSelector,
    currentRouteName,
    handleClickAwayListener,
    handleCloseMenu,
    handleDropdownOpen,
    handleHomeRedirect,
    handleLogout,
    handleOpenMenu,
    handleRewardDropdownOpen,
    handleRewardsClick,
    handleShopClick,
    isDropdownOpen,
    isLocked,
    isMenuOpen,
    isRewardsDropdownOpen,
    isShoppingReady: isShoppingReady?.isEnabled,
    openRoutesSelector,
    pathname,
    routesSelectorOpen,
  };
};

export default useConnect;
