import { useBasisTheory } from '@basis-theory/basis-theory-react';
import useModals from 'graphql/hooks/useModals';
import { ModalEnumTypes } from 'graphql/variables/modal';
import { ConnectProps } from './types';
import { until, useMediaQuery } from 'styles/media';

const useConnect = ({ submitBankDetails }: ConnectProps) => {
  const { bt: btBank } = useBasisTheory(
    process.env.REACT_APP_BASIS_THEORY_BANK_API_KEY,
    {
      elements: true,
    },
  );

  const { openModal, closeModal } = useModals();

  const isMobile = useMediaQuery(until.mobile);

  const handleOpenModal = async () => {
    openModal(ModalEnumTypes.CONFIRM_PAYMENT_METHOD, {
      handleSubmit: async () => {
        if (btBank) await submitBankDetails({ btBank });
        closeModal();
      },
    });
  };

  return {
    btBank,
    handleOpenModal,
    isMobile,
  };
};

export default useConnect;
