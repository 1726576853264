import PolicyCategoryIllustration from 'components/PolicyCategoryIllustration';
import CarrierThumbnail from 'components/CarrierThumbnail';
import {
  FiCalendar as CalendarIcon,
  FiAlertOctagon as AlertOctagonIcon,
  FiUser as UserIcon,
  FiFileText as FileTextIcon,
  FiHelpCircle as HelpIcon,
  FiBox as BoxIcon,
} from 'react-icons/fi';
import {
  AutoIcon,
  AutoPayIcon,
  BodyBold,
  Container,
  DetailsButton,
  DetailsContainer,
  DocumentIconContainer,
  HomeIcon,
  IconContainer,
  OverviewContainer,
  OverviewItem,
  PolicyType,
  PolicyTypeTitle,
  PolicyTypeTitleContainer,
  PriceAndLogoContainer,
  SectionTitle,
  ToolTipIconContainer,
} from './styles';
import { capitalize } from 'utils/capitalize';
import Tooltip from 'components/Tooltip';
import { AUTO, HOME } from 'globals/constants';
import { Props } from './types';
import { PREMIUM_TOOLTIP_MESSAGE } from './constants';
import { formatDateSlash } from 'utils/dates';

const OfferSummary = ({
  assetName,
  category,
  termMonths,
  namedInsureds,
  carrier,
  carrierLogo,
  expiryDate,
  offerDetails,
  monthlyPremium,
}: Props) => {
  const getAssetIcon = () => {
    if (category === AUTO) {
      return <AutoIcon />;
    }
    if (category === HOME) {
      return <HomeIcon />;
    }
    return <BoxIcon />;
  };

  return (
    <Container>
      <PriceAndLogoContainer>
        <PolicyCategoryIllustration
          height="3.75rem"
          width="3.75rem"
          policyCategory={category}
        />
        <PolicyTypeTitleContainer>
          <PolicyTypeTitle>Policy Type</PolicyTypeTitle>
          <PolicyType>{`${capitalize(category)} Insurance`}</PolicyType>
        </PolicyTypeTitleContainer>
        {carrier && <CarrierThumbnail name={carrier} url={carrierLogo} />}
      </PriceAndLogoContainer>
      <OverviewContainer>
        <SectionTitle>Policy Overview</SectionTitle>
        {termMonths && (
          <OverviewItem>
            <CalendarIcon />
            {termMonths} month term
          </OverviewItem>
        )}
        {monthlyPremium && (
          <OverviewItem>
            <AutoPayIcon />
            <BodyBold>${monthlyPremium} / month</BodyBold>premium
            <Tooltip alignLeft content={PREMIUM_TOOLTIP_MESSAGE} disableOutline>
              <ToolTipIconContainer>
                <HelpIcon size={'1rem'} aria-hidden="true" focusable="false" />
              </ToolTipIconContainer>
            </Tooltip>
          </OverviewItem>
        )}
        {namedInsureds && (
          <OverviewItem>
            <IconContainer>
              <UserIcon />
            </IconContainer>
            {namedInsureds}
          </OverviewItem>
        )}
        {assetName && (
          <OverviewItem>
            {getAssetIcon()}
            {` ${assetName}`}
          </OverviewItem>
        )}
        {expiryDate && (
          <OverviewItem>
            <AlertOctagonIcon /> Offer expires
            <BodyBold>{formatDateSlash(new Date(expiryDate))}</BodyBold>
          </OverviewItem>
        )}
      </OverviewContainer>
      {offerDetails && (
        <DetailsContainer>
          <SectionTitle>Offer Details</SectionTitle>
          <DetailsButton href={offerDetails} target="_blank">
            <DocumentIconContainer>
              <FileTextIcon />
            </DocumentIconContainer>
            Coverage & price details
          </DetailsButton>
        </DetailsContainer>
      )}
    </Container>
  );
};

export default OfferSummary;
