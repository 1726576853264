import styled, { css } from 'styled-components';
import { from } from 'styles/media';

import { BodyBold } from 'components/Typography';

import { ComponentProps, FallbackProps } from './types';

export const Component = styled.img<ComponentProps>`
  max-width: 100%;
  object-fit: contain;

  ${({ $margin }) =>
    $margin &&
    css`
      margin-inline-start: 1rem;
    `}

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          block-size: 2.5rem;
        `;
      case 'large':
        return css`
          block-size: 5rem;
        `;
      case 'medium':
      default:
        return css`
          block-size: 3.75rem;
        `;
    }
  }}

  ${({ theme, variant }) => {
    switch (variant) {
      case 'grayNickel':
        return css`
          filter: ${theme.filters.grayNickel};
        `;
      case 'white':
        return css`
          filter: ${theme.filters.white};
        `;
      case 'bluewood':
        return css`
          filter: ${theme.filters.bluewood};
        `;
      default:
        return '';
    }
  }}
`;

export const FallbackContainer = styled.div<FallbackProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 6.25rem;

  ${({ $margin }) =>
    $margin &&
    css`
      margin-left: 1rem;
    `}

  ${from.tablet} {
    max-inline-size: unset;
  }
`;

export const Fallback = styled(BodyBold)`
  /* stylelint-disable */
  display: -webkit-box;
  /* stylelint-enable */
  overflow: hidden;
  color: ${({ theme }) => theme.colors.bluewood};
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;
