import styled, { css } from 'styled-components';
import addAlpha from 'utils/addAlpha';

import { ComponentProps } from './types';

/* eslint-disable import/prefer-default-export */

export const Component = styled.a<ComponentProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.75rem;
  padding: 0 1rem;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  font-size: 1rem;
  font-family: Inter, sans-serif;
  line-height: 1.1875rem;
  letter-spacing: 0.03em;
  text-align: center;
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.bluewood};
  border: none;
  border-radius: 0.25rem;
  transition: background-color 500ms ease;
  & svg {
    inline-size: 1.25rem;
    block-size: 1.25rem;
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &:hover,
  &:focus {
    cursor: pointer;
  }
  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      inline-size: 100%;
    `}
  ${({ $hasIcon, $iconPosition }) => {
    if ($hasIcon) {
      switch ($iconPosition) {
        case 'right':
          return css`
            flex-direction: row-reverse;
            & span {
              margin-inline-end: 0.75rem;
            }
          `;
        default:
        case 'left':
          return css`
            flex-direction: row;
            & span {
              margin-inline-start: 0.75rem;
            }
          `;
      }
    }
    return null;
  }}
  ${({ theme, $variant }) => {
    switch ($variant) {
      case 'join':
        return css`
          color: ${theme.colors.white};
          background-color: ${theme.colors.persimon};
          border: 0.0625rem solid transparent;

          &:hover,
          &:focus {
            border: 0.0625rem solid ${theme.colors.white};
          }
        `;
      case 'secondary':
        return css`
          color: ${theme.colors.bluewood};
          background-color: transparent;
          border: 0.0625rem solid ${theme.colors.bluewood};
          &:hover,
          &:focus {
            background-color: ${addAlpha(theme.colors.bluewood, 0.06)};
          }
        `;
      case 'tertiary':
        return css`
          background-color: ${theme.colors.persimon};
          &:disabled {
            opacity: 0.6;
          }
          &:hover,
          &:focus {
            background-color: ${theme.colors.mojo};
          }
        `;
      case 'primary':
      default:
        return css`
          border: 0.0625rem solid transparent;
          &:hover,
          &:focus {
            background-color: ${theme.colors.mirage};
          }
        `;
    }
  }}
  
 ${({ $size }) => {
    switch ($size) {
      case 'small':
        return css`
          block-size: 2.375rem;
          font-size: 0.875rem;
        `;
      default:
        return css`
          block-size: 2.75rem;
          font-size: 1rem;
        `;
    }
  }}
`;
