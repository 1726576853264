function extractColors(hex: string) {
  if (hex.length === 7) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return { b, g, r };
  }

  const r = parseInt(hex.slice(1, 2), 16);
  const g = parseInt(hex.slice(2, 3), 16);
  const b = parseInt(hex.slice(3, 4), 16);

  return { b, g, r };
}

export default extractColors;
