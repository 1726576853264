import ButtonLink from 'components/ButtonLink';
import { Container, Content } from './styles';
import Logo from 'components/Logo';
import { Link } from 'react-router-dom';

const GuestTopBar = () => {
  return (
    <Container>
      <Content>
        <Link to="/">
          <Logo colorVariant="light" withColor />
        </Link>
        <ButtonLink href={'/'} size="small" variant="tertiary">
          Log in
        </ButtonLink>
      </Content>
    </Container>
  );
};

export default GuestTopBar;
