import { ReactComponent as DefaultBetaTag } from 'assets/images/brand/Beta_tag.svg';
import { ReactComponent as DefaultIsotypeColor } from 'assets/images/brand/Isotype.svg';
import { ReactComponent as DefaultIsotypeMono } from 'assets/images/brand/Isotype_mono.svg';
import { ReactComponent as DefaultLogotype } from 'assets/images/brand/Logotype.svg';
import { FiLock } from 'react-icons/fi';
import styled, { css } from 'styled-components';

import { IsotypeProps, LogotypeProps } from './types';

const IsotypeMargin = css<IsotypeProps>`
  ${({ $size }) => {
    switch ($size) {
      case 'small':
        return css`
          margin-inline-end: 0.375rem;
        `;
      case 'big':
        return css`
          margin-inline-end: 1rem;
        `;
      case 'medium':
      default:
        return css`
          margin-inline-end: 0.75rem;
        `;
    }
  }}
`;

const IsotypeSize = css<IsotypeProps>`
  ${({ $size }) => {
    switch ($size) {
      case 'small':
        return css`
          width: 1.625rem;
        `;
      case 'big':
        return css`
          width: 3.125rem;
        `;
      case 'medium':
      default:
        return css`
          width: 2rem;
        `;
    }
  }}
`;

const LogotypeSize = css<LogotypeProps>`
  ${({ $size }) => {
    switch ($size) {
      case 'small':
        return css`
          width: 4.375rem;
        `;
      case 'big':
        return css`
          width: 7.875rem;
        `;
      case 'medium':
      default:
        return css`
          width: 5.3125rem;
        `;
    }
  }}
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const Isotype = styled(DefaultIsotypeColor)<IsotypeProps>`
  ${IsotypeSize}
  ${({ $variant }) => $variant !== 'isotype' && IsotypeMargin}
`;

export const IsotypeMono = styled(DefaultIsotypeMono)<IsotypeProps>`
  ${IsotypeSize}
  ${({ $variant }) => $variant !== 'isotype' && IsotypeMargin}
  color: inherit;
`;

export const Logotype = styled(DefaultLogotype)<LogotypeProps>`
  ${LogotypeSize}
  color: inherit;

  ${({ theme, $colorVariant }) => {
    switch ($colorVariant) {
      case 'dark':
        return `
          color: ${theme.colors.bluewood};
        `;
      case 'grey':
        return `
          color: ${theme.colors.starDust};
        `;
      case 'light':
      default:
        return `
          color: ${theme.colors.white};
        `;
    }
  }}
`;

export const BetaTag = styled(DefaultBetaTag)<{ $absolutePosition: boolean }>`
  margin-left: 0.9931rem;
  margin-top: 0.2rem;
  ${({ $absolutePosition }) =>
    $absolutePosition &&
    css`
      position: absolute;
      inset-block-end: -1.5rem;
      inset-inline-start: -1.46rem;
    `}
`;

export const LockIcon = styled(FiLock)`
  width: 0.75rem;
  height: 0.75rem;
  margin-left: 0.5rem;
  color: inherit;
`;
