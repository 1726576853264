import {
  APP_ROUTES,
  MODAL_ROUTES,
  ONBOARDING_ROUTES,
  POLICY_SUBROUTES,
} from 'globals/constants';
import useFeatures from 'graphql/hooks/useFeatures';
import useMe from 'graphql/hooks/useMe';
import usePolicies from 'graphql/hooks/usePolicies';
import { useCallback } from 'react';
import { RouteObject, matchRoutes, useLocation } from 'react-router-dom';
import { track } from 'services/segment';
import { from, useMediaQuery } from 'styles/media';

const useConnect = () => {
  const { pathname } = useLocation();
  const { features } = useFeatures();
  const { me } = useMe();
  const { policies } = usePolicies({ variables: { isCurrent: true } });
  const isMobile = !useMediaQuery(from.tablet);

  const isAppRoute = matchRoutes(APP_ROUTES as RouteObject[], pathname);

  const isModalRoute = matchRoutes(MODAL_ROUTES as RouteObject[], pathname);

  const isOnboardingRoute = matchRoutes(
    ONBOARDING_ROUTES as RouteObject[],
    pathname,
  );

  const isPolicySubroute = matchRoutes(
    POLICY_SUBROUTES as RouteObject[],
    pathname,
  );

  const handleTrackHome = useCallback(() => {
    if (me && !me.isViewedAs) {
      track('Navbar - Clicked', {
        tab: 'HOME',
      });
    }
  }, [me]);

  const handleTrackInsuranceInsider = useCallback(() => {
    if (me && !me.isViewedAs) {
      track('Navbar - Clicked', {
        tab: 'INSURANCE_INSIDER',
      });
    }
  }, [me]);

  const handleTrackShop = useCallback(() => {
    if (me && !me.isViewedAs) {
      track('Navbar - Clicked', {
        tab: 'SHOP',
      });
    }
  }, [me]);

  return {
    avatarUrl: me?.avatar,
    firstName: me?.firstName,
    handleTrackHome,
    handleTrackInsuranceInsider,
    handleTrackShop,
    hasFeatures: !!features.length,
    hasPolicies: !!policies.length,
    initials: me?.initials,
    isMobile,
    isModalRoute,
    isNavHidden: isAppRoute || isOnboardingRoute || isPolicySubroute,
    lastName: me?.lastName,
    viewAs: me?.viewedAs,
  };
};

export default useConnect;
