import { parseISO } from 'date-fns';
import { PolicyPartsFragment } from 'graphql/generated';
import formatNumber from 'utils/formatMoney';
import {
  daysRemaining,
  formatDateSlash,
  formatDateSlashNoLocale,
} from 'utils/dates';
import notEmpty from 'utils/notEmpty';
import { WithoutNulls } from 'utils/removeNulls';

import { normalizeBeneficiary } from './Beneficiary';
import { normalizeCarrier } from './Carrier';
import { normalizeVehicle } from './Vehicle';
import { normalizeVehicleCoverage } from './VehicleCoverage';
import { normalizePolicyDocument } from './PolicyDocument';
import {
  normalizeDriver,
  normalizeLifeInsured,
  normalizeNamedInsuredBase,
} from './NamedInsured';
import { AUTO, HOME, LIFE, PET, RENTER } from 'globals/constants';

type InputPolicy = WithoutNulls<PolicyPartsFragment>;

const parsePremiumPeriod = (period: string | undefined) => {
  if (period === 'PREMIUM_YEARLY') return 'year';
  if (period === 'PREMIUM_MONTHLY') return 'month';
  if (period === 'PREMIUM_SIX') return '6 months';
  if (period === 'PREMIUM_5YEARS,') return '5 Years';
  if (period === 'PREMIUM_10YEARS') return '10 Years';
  if (period === 'PREMIUM_15YEARS') return '15 Years';
  if (period === 'PREMIUM_20YEARS') return '20 Years';
  if (period === 'PREMIUM_25YEARS') return '25 Years';
  if (period === 'PREMIUM_30YEARS') return '30 Years';
  return 'year';
};

export const parsePremiumPeriodToMonths = (period: string | undefined) => {
  if (period === 'PREMIUM_YEARLY') return 12;
  if (period === 'PREMIUM_MONTHLY') return 1;
  if (period === 'PREMIUM_SIX') return 6;
  if (period === 'PREMIUM_5YEARS,') return 60;
  if (period === 'PREMIUM_10YEARS') return 120;
  if (period === 'PREMIUM_15YEARS') return 180;
  if (period === 'PREMIUM_20YEARS') return 240;
  if (period === 'PREMIUM_25YEARS') return 300;
  if (period === 'PREMIUM_30YEARS') return 360;
  return 12;
};

export const parseLifePlanType = (
  planType: string | undefined,
  otherTermYears: number | undefined,
) => {
  if (planType === 'PLAN_UNIVERSAL_LIFE') return 'Universal Life';
  if (planType === 'PLAN_WHOLE_LIFE') return 'Whole Life ';
  if (planType === 'PLAN_10YEAR_TERM_LIFE') return '10 year Term Life';
  if (planType === 'PLAN_15YEAR_TERM_LIFE') return '15 year Term Life';
  if (planType === 'PLAN_20YEAR_TERM_LIFE') return '20 year Term Life';
  if (planType === 'PLAN_25YEAR_TERM_LIFE') return '25 year Term Life';
  if (planType === 'PLAN_30YEAR_TERM_LIFE') return '30 year Term Life';
  if (planType === 'PLAN_TERM_OTHER' && otherTermYears)
    return `${otherTermYears} year Term Life`;
  if (planType === 'PLAN_TERM_OTHER') return 'Term Life';
  return '';
};

const getYesOrNo = (value: boolean) => {
  if (value) return 'Yes';
  return 'No';
};

const normalizePolicyHistory = (input: {
  id: string;
  total?: number;
  startDate?: string;
  endDate?: string;
  policyNumber?: string;
  premiumPeriod?: string;
}) => ({
  endDate: input.endDate ? parseISO(input.endDate).getTime() : undefined,
  id: input.id,
  policyNumber: input.policyNumber,
  premiumPeriod: parsePremiumPeriod(input.premiumPeriod),
  startDate: input.startDate ? parseISO(input.startDate).getTime() : undefined,
  total: input.total ? formatNumber(input.total) : '0.00',
});

export const normalizePolicy = (input: InputPolicy) => {
  const { category } = input;
  const commons = {
    address: input.address,
    basicSyncFailed: input.basicSyncFailed,
    canopyEmbeddedCarrier: input.canopyEmbeddedCarrier,
    carrier: input.carrier ? normalizeCarrier(input.carrier) : undefined,
    continuous: input.continuous,
    daysRemaining: input.endDate ? daysRemaining(input.endDate) : undefined,
    documents: input.documents
      ? input.documents.map((document) =>
          normalizePolicyDocument({ ...document, policyId: input.id }),
        )
      : [],
    endDate: input.endDate ? parseISO(input.endDate).getTime() : undefined,
    fees: input.fees ? formatNumber(input.fees) : undefined,
    id: input.id,
    isCoverageSynced: input.coverageSynced,
    isInRenewal: input?.isOnRenewal,
    isPolicyOwner: input.isPolicyOwner,
    isSynced: input.synced,
    nextPaymentDate: input.nextPaymentDue
      ? parseISO(input.nextPaymentDue).getTime()
      : undefined,
    number: input.policyNumber || 'Unknown policy number',
    policyHistory: input.policyHistory
      ? input.policyHistory.map((history) => normalizePolicyHistory(history))
      : [],
    premium: input.policyPremium
      ? formatNumber(input.policyPremium)
      : undefined,
    premiumPeriod: input.premiumPeriod || 'PREMIUM_YEARLY',
    sharedAccess: input.sharedAccess || [],
    showSyncAnimation: input.showSyncAnimation,
    startDate: input.startDate
      ? parseISO(input.startDate).getTime()
      : undefined,
    syncFailureReason: input.syncFailureReason,
    total: input.total ? formatNumber(input.total) : '0.00',
    user: input?.user,
    wrongDocumentType: input.wrongDocumentType,
  };

  if (input.__typename === 'AutoPolicyType' && category === AUTO) {
    return {
      ...commons,
      category,
      coverages: input.vehicles.map(normalizeVehicleCoverage),
      namedInsured: input.namedInsured
        ? input.namedInsured.filter(notEmpty)
        : [],
      namedInsureds: input.drivers.map(normalizeDriver),
      vehicles: input.vehicles.map(normalizeVehicle),
    };
  }

  if (input.__typename === 'HomePolicyType' && category === HOME) {
    return {
      ...commons,
      category,
      deductible: input.deductible ? formatNumber(input.deductible) : undefined,
      deductibleWind: input.deductibleWind
        ? formatNumber(input.deductibleWind)
        : undefined,
      dwelling: input.dwelling ? formatNumber(input.dwelling) : undefined,
      lossUse: input.lossUse ? formatNumber(input.lossUse) : undefined,
      medicalOthers: input.medicalOthers
        ? formatNumber(input.medicalOthers)
        : undefined,
      namedInsured: (input.namedInsured || []).filter(notEmpty),
      namedInsureds: input.homeOwners.map(normalizeNamedInsuredBase),
      otherStructures: input.otherStructures
        ? formatNumber(input.otherStructures)
        : undefined,
      personalLiability: input.personalLiability
        ? formatNumber(input.personalLiability)
        : undefined,
      personalProperty: input.personalProperty
        ? formatNumber(input.personalProperty)
        : undefined,
      property:
        input.properties && input.properties[0]
          ? {
              address: input.address,
              addressLine: input.properties[0].addressLine1,
              addressLine2: input.properties[0].addressLine2,
              city: input.properties[0].propertyCity,
              construction: input.properties[0].construction,
              fireAlarm:
                input.properties[0].fireAlarm === true ||
                input.properties[0].fireAlarm === false
                  ? getYesOrNo(input.properties[0].fireAlarm)
                  : undefined,
              foundation: input.properties[0].foundation,
              id: input.properties[0].id,
              roof: input.properties[0].roof,
              roofYearBuilt: input.properties[0].roofYearBuilt,
              squareFootage: input.properties[0].squareFootage,
              state: input.properties[0].propertyState?.name,
              typeOfProperty: input.properties[0].typeOfProperty,
              typeOfResidence: input.properties[0].typeOfResidence,
              yearBuilt: input.properties[0].yearBuilt,
              zipCode: input.properties[0].propertyZipCode,
            }
          : undefined,
    };
  }

  if (input.__typename === 'LifePolicyType' && category === LIFE) {
    return {
      ...commons,
      beneficiaries: input.beneficiaries.map(normalizeBeneficiary),
      cashValue: input.cashValue,
      category,
      conversionDate: input.conversionDate
        ? formatDateSlash(parseISO(input.conversionDate).getTime())
        : '',
      deathBenefit: input.deathBenefit
        ? formatNumber(input.deathBenefit)
        : undefined,
      isGroup: input.isGroup,
      namedInsured: input.namedInsured
        ? input.namedInsured.filter(notEmpty)
        : [],
      namedInsureds: input.lifeInsureds.map(normalizeLifeInsured),
      planType: parseLifePlanType(input.planType, input.otherTermYears),
      riders: input.riders,
    };
  }

  if (input.__typename === 'PetPolicyType' && category === PET) {
    return {
      ...commons,
      annualDeductible: input.annualDeductible
        ? formatNumber(input.annualDeductible)
        : undefined,
      breed: input.petBreed,
      category,
      coinsurance: input.coinsurance,
      dob: input.petDob
        ? formatDateSlashNoLocale(new Date(input.petDob))
        : undefined,
      hasBitten:
        input.hasBitten === true || input.hasBitten === false
          ? getYesOrNo(input.hasBitten)
          : undefined,
      name: input.petName,
      namedInsured: input.petOwners.map(
        (owner) =>
          `${owner?.firstName ? `${owner.firstName} ` : ''}${
            owner?.lastName ?? ''
          }`,
      ),
      namedInsureds: input.petOwners.map((owner) => ({
        dob: owner.dob,
        firstName: owner.firstName,
        gender: owner.gender,
        id: owner.id,
        lastName: owner.lastName,
        relationToMarbleMember: owner.relationToMarbleMember,
      })),
      parent: input.petParent,
      parentAddress: input.petParentAddress,
      reimbursement: input.reimbursement,
      sex: input.petSex,
      type: input.petType,
    };
  }

  if (input.__typename === 'RenterPolicyType' && category === RENTER) {
    return {
      ...commons,
      category,
      deductible: input.deductible ? formatNumber(input.deductible) : undefined,
      lossUse: input.lossUse ? formatNumber(input.lossUse) : undefined,
      medicalOthers: input.medicalOthers
        ? formatNumber(input.medicalOthers)
        : undefined,
      namedInsured: input.namedInsured
        ? input.namedInsured.filter(notEmpty)
        : [],
      namedInsureds: input.renters.map(normalizeNamedInsuredBase),
      personalLiability: input.personalLiability
        ? formatNumber(input.personalLiability)
        : undefined,
      personalProperty: input.personalProperty
        ? formatNumber(input.personalProperty)
        : undefined,
      rental: input.rentals
        ? {
            address: input.rentals[0]?.address,
            addressLine: input.rentals[0]?.addressLine,
            city: input.rentals[0]?.city,
            id: input.rentals[0]?.id,
            state: input.rentals[0]?.state?.name,
            typeOfProperty: input.rentals[0]?.typeOfProperty,
            zipCode: input.rentals[0]?.zipCode,
          }
        : undefined,
    };
  }

  return {
    ...commons,
    category: undefined,
    namedInsureds: undefined,
  };
};

export type Policy = ReturnType<typeof normalizePolicy>;
