export default {
  animate: false,
  colors: {
    blueLight: '#dde2f6',
    bluewood: '#2B3253',
    bluewoodLight: '#ECEEF9',
    bluewoodMedium: '#9598A9',
    branchDark: '#101F16',
    branchGreen: '#586F5B',
    branchLight: '#F2F0EC',
    brightGrey: '#F1F2F7',
    cosmos: '#FFD7D7',
    desertStorm: '#EDEDEA',
    eastBay: '#545C99',
    eastBaySmooth: '#EFF1FE',
    error: '#FF6F4D',
    goldenRod: '#FFCE6C',
    goldenRodDark: '#FAA700',
    gray500: '#909196',
    grayBlue: '#F7F7F8',
    grayNickel: '#BFBFBD',
    green: '#72D18D',
    greenDark: '#2EA465',
    greenSmooth: '#E8F8ED',
    karry: '#FFEAD7',
    lightGrey: '#CCCCCC',
    mirage: '#1D2135',
    mojo: '#BE4E34',
    mojoSmooth: '#F9EDEB',
    mySin: '#FFB829',
    newPampas: '#F6F6F4',
    oasis: '#FFF2D7',
    orange: '#FF9E4B',
    orangeSaturated: '#ff9416',
    pacificBlue: '#00CFCF',
    pacificBlueDark: '#0093A0',
    pacificBlueSmooth: '#DFF8F8',
    pampas: '#FAFAF7',
    peachSchnapps: '#FFDED7',
    persimon: '#FF6F4D',
    persimonLight: '#FFEDEB',
    quillGray: '#DEDEDB',
    springWood: '#FCFCF9',
    starDust: '#949492',
    supportGreen: '#72D18D',
    supportGreenDark: '#2EA465',
    supportGreenLight: '#E8F8ED',
    supportMojo: '#BE4E34',
    white: '#ffffff',
  },
  filters: {
    bluewood:
      'brightness(0) saturate(100%) invert(16%) sepia(8%) saturate(4199%) hue-rotate(193deg) brightness(93%) contrast(87%)',
    grayNickel:
      'brightness(0) saturate(100%) invert(92%) sepia(0%) saturate(0%) hue-rotate(170deg) brightness(85%) contrast(87%)',
    white: 'brightness(0) saturate(100%) invert(1)',
  },
  fonts: {
    drukTextWide: 'DrukTextWide',
    inter: 'Inter',
  },
  weights: {
    bold: 700,
    bolder: 800,
    medium: 500,
    regular: 400,
    semiBold: 600,
  },
  zIndex: {
    background: -10,
    default: 0,
    dropdown: 25,
    foreground: 10,
    modal: 50,
    select: 20,
  },
};
