import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { appConfig } from 'config';
import { getToken, removeToken } from 'services/token';

import introspectionResult from './generated';
import { modals } from './variables/modal';

const authLink = new ApolloLink((operation, forward) => {
  const token = getToken();

  // @ts-ignore
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Accept: 'application/json',
      Authorization: `JWT ${token}`,
    },
  }));

  return forward(operation);
});

const cache = new InMemoryCache({
  possibleTypes: introspectionResult.possibleTypes,
  typePolicies: {
    AutoPolicyType: {
      fields: {
        documents: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
    HealthPolicyType: {
      fields: {
        documents: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
    OtherPolicyType: {
      fields: {
        documents: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
    Query: {
      fields: {
        modals: {
          read() {
            return modals();
          },
        },
        offers: {
          merge(_, incoming) {
            return incoming;
          },
        },
        otherPolicies: {
          merge(_, incoming) {
            return incoming;
          },
        },
        policies: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
    SharedAccessType: {
      keyFields: ['namedInsuredId', 'id'],
    },
  },
});

const wsLink = new WebSocketLink({
  options: {
    connectionParams: () => ({ authToken: getToken() || {} }),

    lazy: true,
    reconnect: true,
  },
  uri: `${appConfig.websocketUrl}`,
});

const httpLink = new HttpLink({ uri: `${appConfig.apiUrl}/graphql/` });

const externalHttpLink = new HttpLink({
  uri: `${appConfig.authMachineUrl}/graphql/`,
});

const logoutLink = onError(({ networkError, graphQLErrors }) => {
  const invalidTokenErrors = [
    'Error decoding signature',
    'Signature has expired',
  ];
  const error =
    (networkError &&
      'statusCode' in networkError &&
      networkError.statusCode === 401) ||
    (graphQLErrors && invalidTokenErrors.includes(graphQLErrors[0]?.message));

  if (error) {
    removeToken();
  }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([logoutLink, authLink, splitLink]),
});

const externalClient = new ApolloClient({
  cache,
  link: ApolloLink.from([externalHttpLink]),
});

export default client;

export { externalClient };
