import parse, {
  DOMNode,
  Element,
  HTMLReactParserOptions,
  domToReact,
} from 'html-react-parser';
import { ReactNode, useCallback } from 'react';

import {
  BoldText,
  BreakLine,
  Container,
  ExternalLink,
  InternalLink,
  OlItem,
  Text,
  Title1,
  Title2,
  Title3,
  Title4,
  Title5,
  Title6,
  UlItem,
} from './styles';
import Props, { TagMap } from './types';

export const baseTagMap = {
  a: (children: ReactNode) => <span>{children}</span>,
  br: () => <BreakLine />,
  h1: (children: ReactNode) => <Title1>{children}</Title1>,
  h2: (children: ReactNode) => <Title2>{children}</Title2>,
  h3: (children: ReactNode) => <Title3>{children}</Title3>,
  h4: (children: ReactNode) => <Title4>{children}</Title4>,
  h5: (children: ReactNode) => <Title5>{children}</Title5>,
  h6: (children: ReactNode) => <Title6>{children}</Title6>,
  olItem: (children: ReactNode) => <OlItem>{children}</OlItem>,
  p: (children: ReactNode) => <Text>{children}</Text>,
  strong: (children: ReactNode) => <BoldText>{children}</BoldText>,
  ulItem: (children: ReactNode) => <UlItem>{children}</UlItem>,
};

export const tagList: { [tag: string]: string } = {
  a: 'a',
  br: 'br',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  olItem: 'li',
  p: 'p',
  strong: 'strong',
  ulItem: 'li',
};

const HTMLRenderer = ({ content, custom = {}, className, ...rest }: Props) => {
  const parseTagMap = useCallback(
    (element: Element) => {
      const tagMap: TagMap = { ...baseTagMap, ...custom };
      const reactEl = domToReact(element.children as DOMNode[], {
        replace: (domNode) => {
          if (domNode instanceof Element && tagMap) {
            return parseTagMap(domNode);
          }

          return domNode;
        },
      });

      for (const key of Object.keys(tagMap)) {
        if (tagList[key] === element.name) {
          if ((element?.parent as Element)?.name === 'ol') {
            return tagMap.olItem(reactEl);
          }
          if ((element?.parent as Element)?.name === 'ul') {
            return tagMap.ulItem(reactEl);
          }

          if (element.name === 'a') {
            const { href } = element.attribs;
            return href?.startsWith('/') ? (
              <InternalLink to={href}>{tagMap[key](reactEl)}</InternalLink>
            ) : (
              <ExternalLink target="_blank" href={element.attribs.href}>
                {tagMap[key](reactEl)}
              </ExternalLink>
            );
          }

          return tagMap[key](reactEl);
        }
      }

      return element;
    },
    [custom],
  );

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element && custom) {
        return parseTagMap(domNode);
      }

      return domNode;
    },
  };
  return (
    <Container className={className} {...rest}>
      {parse(content, options)}
    </Container>
  );
};

export default HTMLRenderer;
