import { NavLink, Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import DefaultLogo from 'components/Logo';
import MaxWidth from 'components/MaxWidth';

export const Container = styled.nav`
  background-color: ${({ theme }) => theme.colors.bluewood};
`;

export const Content = styled(MaxWidth)`
  display: flex;
  align-items: center;
  height: 5rem;
`;

export const LogoLink = styled(RouterLink)`
  margin-right: auto;
`;

export const Logo = styled(DefaultLogo)``;

export const Links = styled.div`
  display: flex;
  gap: 2.5rem;
  align-items: center;
`;

export const Link = styled(NavLink)<{ $active: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ $active, theme }) =>
    $active ? `${theme.colors.goldenRod};` : `${theme.colors.white}`};
  font-weight: ${({ theme }) => theme.weights.semiBold};
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-decoration: none;

  :hover,
  :focus {
    color: ${({ theme }) => theme.colors.goldenRod};
  }
`;
