import { gql } from '@apollo/client';

export const DRIVER_FRAGMENT = gql`
  fragment DriverParts on DriverType {
    id
    created
    firstName
    lastName
    dob
    gender
    maritalStatus
    careerStatus
    educationLevel
    ageFirstLicensed
    driversLicenseNumber
    driversLicenseState {
      id
      name
    }
    relationToMarbleMember
  }
`;

export const HOME_OWNER_FRAGMENT = gql`
  fragment HomeOwnerParts on HomeOwnerType {
    id
    created
    firstName
    lastName
    dob
    gender
    maritalStatus
    educationLevel
    relationToMarbleMember
    driversLicenseNumber
    driversLicenseState {
      id
      name
    }
  }
`;

export const RENTER_FRAGMENT = gql`
  fragment RenterParts on RenterType {
    id
    firstName
    lastName
    dob
    gender
    relationToMarbleMember
    driversLicenseNumber
    driversLicenseState {
      id
      name
    }
  }
`;

export const PET_OWNER_FRAGMENT = gql`
  fragment PetOwnerParts on PetOwnerType {
    id
    firstName
    lastName
    dob
    gender
    relationToMarbleMember
    driversLicenseNumber
    driversLicenseState {
      id
      name
    }
  }
`;

export const LIFE_INSURED_FRAGMENT = gql`
  fragment LifeInsuredParts on LifeInsuredType {
    id
    firstName
    lastName
    dob
    gender
    maritalStatus
    educationLevel
    relationToMarbleMember
    driversLicenseNumber
    driversLicenseState {
      id
      name
    }
  }
`;

export const SHARED_ACCESS_FRAGMENT = gql`
  fragment SharedAccessParts on SharedAccessType {
    namedInsuredId
    id
    status
    fullName
    email
  }
`;

export const NAMED_INSURED_FRAGMENT = gql`
  fragment NameInsuredParts on NamedInsuredType {
    id
    gender
    dob
    relationToMarbleMember
    driversLicenseNumber
    driversLicenseState {
      id
      name
    }
  }
`;

export const SPLIT_NAMED_INSURED_FRAGMENT = gql`
  fragment SplitNameInsuredParts on NamedInsuredType {
    id
  }
`;

export const MERGE_NAMED_INSURED_FRAGMENT = gql`
  fragment MergeNameInsuredParts on NamedInsuredType {
    id
  }
`;
