import { gql } from '@apollo/client';
import { ME_FRAGMENT } from './me';
import {
  DRIVER_FRAGMENT,
  HOME_OWNER_FRAGMENT,
  PET_OWNER_FRAGMENT,
  RENTER_FRAGMENT,
} from './namedInsured';
import {
  PET_ASSET_CART_FRAGMENT,
  PROPERTY_ASSET_CART_FRAGMENT,
  RENTAL_ASSET_CART_FRAGMENT,
  VEHICLE_ASSET_CART_FRAGMENT,
} from './asset';
import { OFFER_FRAGMENT } from './offers';

export const SHOPPING_CART_FRAGMENT = gql`
  fragment ShoppingCartParts on ShoppingCartType {
    id
    isActive
    category
    zipCode
    isInterestedInBundling
    isOnboardingCart
    isUserCreatedCart
    user {
      ...MeParts
    }
    drivers {
      ...DriverParts
    }
    homeOwners {
      ...HomeOwnerParts
    }
    renters {
      ...RenterParts
    }
    petOwners {
      ...PetOwnerParts
    }
    vehicles {
      ...VehicleCartTypeParts
    }
    properties {
      ...PropertyCartTypeParts
    }
    rentals {
      ...RentalTypeParts
    }
    pets {
      ...PetTypeParts
    }
    policy {
      policyId
      policyNumber
      endDate
      continuous
      assets {
        name
        addressLine
        ownership
        model
        make
        year
      }
      namedInsureds {
        firstName
        lastName
      }
      coverageDetails {
        planType
        otherTermYears
      }
    }
    offers {
      ...OfferParts
    }
    dismissedOffers {
      ...OfferParts
    }
  }
  ${ME_FRAGMENT}
  ${DRIVER_FRAGMENT}
  ${HOME_OWNER_FRAGMENT}
  ${PET_OWNER_FRAGMENT}
  ${RENTER_FRAGMENT}
  ${VEHICLE_ASSET_CART_FRAGMENT}
  ${PROPERTY_ASSET_CART_FRAGMENT}
  ${RENTAL_ASSET_CART_FRAGMENT}
  ${PET_ASSET_CART_FRAGMENT}
  ${OFFER_FRAGMENT}
`;
