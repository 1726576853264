import { gql } from '@apollo/client';

import { MARKETING_LANDING_COMMON_FRAGMENT } from './marketingLandingCommon';

/* eslint-disable import/prefer-default-export */

export const MARKETING_LANDING_FRAGMENT = gql`
  fragment MarketingLandingParts on MarketingLandingDataType {
    id
    partnerSlug
    pageType
    partnerLogo
    title
    subtitle
    metadata
    popupTitle
    popupSubtitle
    popupCta
    popupRedirect
    commonData {
      ...MarketingLandingCommonParts
    }
  }
  ${MARKETING_LANDING_COMMON_FRAGMENT}
`;
