import { useField } from 'formik';

import { LogicProps } from './types';

const useLogic = ({ defaultChecked, name, role, value }: LogicProps) => {
  const [{ onBlur, onChange, value: current }] = useField<string | string[]>(
    name,
  );

  const cur = current instanceof Array ? current : String(current);

  const checked =
    role === 'checkbox' ? cur === value || cur?.includes(value) : cur === value;

  return {
    checked: defaultChecked || checked,
    onBlur,
    onChange,
  };
};

export default useLogic;
