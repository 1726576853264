import styled from 'styled-components';

export const H1 = styled.h1`
  color: inherit;
  font-weight: ${({ theme }) => theme.weights.bold};
  font-size: 2.25rem;
  line-height: 3.125rem;
`;

export const H2 = styled.h2`
  color: inherit;
  font-weight: ${({ theme }) => theme.weights.bold};
  font-size: 1.75rem;
  line-height: 2.4375rem;
`;

export const H3 = styled.h3`
  color: inherit;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  font-size: 1.5rem;
  line-height: 2.125rem;
`;

export const H4 = styled.h4`
  color: inherit;
  font-weight: ${({ theme }) => theme.weights.bold};
  font-size: 1.25rem;
  line-height: 1.75rem;
`;

export const Subtitle = styled.p`
  color: inherit;
  font-size: 1.375rem;
  line-height: 1.9375rem;
`;

export const SubtitleBold = styled(Subtitle)`
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const PBig = styled.p`
  color: inherit;
  font-size: 1.25rem;
  line-height: 2rem;
`;

export const PBigSemi = styled.p`
  color: inherit;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  font-size: 1.25rem;
  line-height: 1.75rem;
`;

export const Body = styled.p`
  color: inherit;
  font-size: 1rem;
  line-height: 1.375rem;
`;

export const BodyMedium = styled(Body)`
  font-weight: ${({ theme }) => theme.weights.medium};
`;

export const BodyBold = styled(Body)`
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const BodyLink = styled(Body)`
  color: ${({ theme }) => theme.colors.eastBay};
  text-decoration-line: underline;
  cursor: pointer;
`;

export const SpanBold = styled.span`
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const PSemi = styled(Body)`
  font-weight: ${({ theme }) => theme.weights.semiBold};
`;

export const Small = styled.span`
  color: inherit;
  font-size: 0.875rem;
  line-height: 1.375rem;
`;

export const SmallSemi = styled(Small)`
  font-weight: ${({ theme }) => theme.weights.semiBold};
`;

export const SmallBold = styled(Small)`
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const SmallLink = styled(Small)`
  color: ${({ theme }) => theme.colors.eastBay};
  text-decoration-line: underline;
`;

export const Caption = styled.span`
  color: inherit;
  font-size: 0.75rem;
  line-height: 1.05rem;
`;

export const CaptionSemi = styled(Caption)`
  font-weight: ${({ theme }) => theme.weights.semiBold};
`;

export const Overline = styled.span`
  color: inherit;
  font-size: 0.75rem;
  line-height: 1.0625rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
`;

export const OverlineSemi = styled(Overline)`
  font-weight: ${({ theme }) => theme.weights.medium};
`;

export const OverlineBold = styled(Overline)`
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const Tiny = styled.span`
  font-size: 0.625rem;
  line-height: 0.75rem;
  letter-spacing: 0.03em;
`;

export const UndoText = styled.span`
  font-weight: 500;
  letter-spacing: 0.01875rem;
`;

export const CardH2 = styled(H2)`
  font-size: 1.375rem;
  line-height: 2.1rem;
`;

export const Notice = styled(Small).attrs({ as: 'p' })`
  line-height: 1.4rem;
`;
