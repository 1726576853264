import { parseISO } from 'date-fns';
import { FeatureItemType } from 'graphql/generated';
import { Color } from 'styles/themes';

type InputFeature = FeatureItemType;

export const normalizeFeature = (input: InputFeature) => ({
  date: input.date ? parseISO(input.date).getTime() : undefined,
  description: input.description,
  id: input.id,
  imageBackgroundColor: (input.imageBgcolor || 'goldenRod') as Color,
  imageUrl: input.image,
  isFeatured: input.featured,
  isRead: input.read,
  title: input.title,
  type: input.featureType,
});

export type Feature = ReturnType<typeof normalizeFeature>;
