import {
  CardNumberElement,
  CardExpirationDateElement,
  CardVerificationCodeElement,
  BasisTheoryProvider,
} from '@basis-theory/basis-theory-react';
import { Form, Formik } from 'formik';
import {
  ButtonContainer,
  Container,
  FormContent,
  FormFields,
  LoaderContainer,
} from './styles';
import Button from 'components/Button';
import BasisTheoryInputContainer from 'components/BasisTheoryInputContainer';
import PaymentDetailsFootnote from 'components/PaymentDetailsFootnote';
import { Props } from './types';
import PaymentDetailsVisibilityToggle from 'components/PaymentDetailsVisibilityToggle';
import FullscreenLoader from 'components/FullscreenLoader';
import { CVC_TOOLTIP_MESSAGE } from './constants';
import useConnect from './connect';

const CollectCardDetails = ({
  details,
  isPaymentSubmitted,
  cardNumberRef,
  cardExpirationRef,
  cardVerificationRef,
  currentCardBrand,
  submitCard,
  isDetailsVisible,
  handleSetCurrentCardBrand,
  isSubmitting,
  isFetching,
  handleToggleDetailsVisible,
  fieldErrors,
}: Props) => {
  const fieldsReadOnly = isPaymentSubmitted;
  const fieldsDisabled = isDetailsVisible && isPaymentSubmitted;

  const { btCard, expirationDateErrorNames, handleOpenModal, isMobile } =
    useConnect({ submitCard });

  if (!btCard) return <FullscreenLoader isFullPage={false} />;

  return (
    <BasisTheoryProvider bt={btCard}>
      <Container>
        <PaymentDetailsVisibilityToggle
          handleToggleDetailsVisible={handleToggleDetailsVisible}
          bt={btCard}
          tokenId={details?.paymentToken}
          isDetailsVisible={isDetailsVisible}
        />
        <Formik onSubmit={handleOpenModal} initialValues={{}}>
          <Form>
            <FormContent>
              {isFetching && (
                <LoaderContainer>
                  <FullscreenLoader isFullPage={false} />
                </LoaderContainer>
              )}
              <FormFields>
                <BasisTheoryInputContainer
                  name="Card Number"
                  readOnly={fieldsReadOnly}
                  disabled={fieldsDisabled}
                  error={
                    fieldErrors?.find(
                      (error) => error.fieldName === 'datanumber',
                    )?.message
                  }
                >
                  <CardNumberElement
                    placeholder={details?.accountNumber || undefined}
                    value={isDetailsVisible ? undefined : ''}
                    readOnly={fieldsReadOnly}
                    disabled={fieldsDisabled}
                    validateOnChange
                    id="cardNumber"
                    ref={cardNumberRef}
                    onChange={({ cardBrand }: { cardBrand: string }) =>
                      handleSetCurrentCardBrand(cardBrand)
                    }
                  />
                </BasisTheoryInputContainer>
                <BasisTheoryInputContainer
                  name="Expiry Date"
                  readOnly={fieldsReadOnly}
                  disabled={fieldsDisabled}
                  error={
                    fieldErrors?.find((error) =>
                      expirationDateErrorNames.includes(error.fieldName),
                    )?.message
                  }
                >
                  <CardExpirationDateElement
                    id="cardExpiration"
                    ref={cardExpirationRef}
                    value={isDetailsVisible ? undefined : ''}
                    readOnly={fieldsReadOnly}
                    disabled={fieldsDisabled}
                    validateOnChange
                  />
                </BasisTheoryInputContainer>
                <BasisTheoryInputContainer
                  name="CVC"
                  readOnly={fieldsReadOnly}
                  disabled={fieldsDisabled}
                  toolTipMessage={CVC_TOOLTIP_MESSAGE}
                  error={
                    fieldErrors?.find((error) => error.fieldName === 'datacvc')
                      ?.message
                  }
                >
                  <CardVerificationCodeElement
                    id="cardVerification"
                    ref={cardVerificationRef}
                    cardBrand={currentCardBrand}
                    value={isDetailsVisible ? undefined : ''}
                    readOnly={fieldsReadOnly}
                    disabled={fieldsDisabled}
                    validateOnChange
                  />
                </BasisTheoryInputContainer>
              </FormFields>
              <PaymentDetailsFootnote submitted={isPaymentSubmitted} />
              {!isPaymentSubmitted && (
                <ButtonContainer>
                  <Button
                    fullWidth={isMobile}
                    id="submitCard"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Confirm payment details
                  </Button>
                </ButtonContainer>
              )}
            </FormContent>
          </Form>
        </Formik>
      </Container>
    </BasisTheoryProvider>
  );
};

export default CollectCardDetails;
