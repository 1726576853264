import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  HOME_PATH,
  ID_ATTRIBUTE,
  INSURANCE_INSIDER_PATH,
  MARKETING_LANDING_PATH,
  PAYMENT_PATH,
  REGISTER_CONFIRM_NESTED_PATH,
  REGISTER_PATH,
  SHARE_POLICY_PATH,
  WILDCARD_PATH,
  REGISTER_SHOP_FIRST_DETAILED_NESTED_PATH,
  REGISTER_SHOP_FIRST_NESTED_PATH,
} from 'globals/constants';
import Loader from 'components/FullscreenLoader';
import Banner from 'containers/Shared/Banner';
import GuestTopBar from 'components/GuestTopBar';
import Footer from 'components/Footer';
import useSiteConfiguration from 'graphql/hooks/useSiteConfiguration';

const Authentication = lazy(() => import('containers/Guest/Authentication'));

const DefaultRegister = lazy(
  () => import('containers/Guest/Authentication/Register/Flows/Default'),
);

const InsuranceInsider = lazy(
  () => import('containers/Guest/InsuranceInsider'),
);
const MarketingLanding = lazy(
  () => import('containers/Guest/MarketingLanding'),
);
const SharePolicy = lazy(() => import('containers/Guest/SharePolicy'));

const PaymentDetails = lazy(() => import('containers/Guest/PaymentDetails'));

const ShopFirstRegister = lazy(
  () => import('containers/Guest/Authentication/Register/Flows/ShopFirstSync'),
);

const ShopFirstDetailedRegister = lazy(
  () =>
    import('containers/Guest/Authentication/Register/Flows/ShopFirstDetailed'),
);

const renderLoader = () => <Loader />;
const Guest = () => {
  const { featureToggles } = useSiteConfiguration();
  const isShopFirstEnabled = featureToggles?.find(
    (toggle) => toggle.name === 'SHOP_FIRST_REGISTER_FLOWS',
  )?.isEnabled;
  const isSignupEnabled = featureToggles?.find(
    (toggle) => toggle.name === 'SIGNUP',
  )?.isEnabled;

  return (
    <Suspense fallback={renderLoader()}>
      <Routes>
        <Route
          element={
            <>
              <GuestTopBar />
              <PaymentDetails />
              <Footer />
            </>
          }
          path={`${PAYMENT_PATH}/:offerId`}
        />
        <Route
          element={<MarketingLanding />}
          path={`${MARKETING_LANDING_PATH}${WILDCARD_PATH}`}
        />
        <Route element={<InsuranceInsider />} path={INSURANCE_INSIDER_PATH} />
        <Route
          element={<SharePolicy />}
          path={`${SHARE_POLICY_PATH}${ID_ATTRIBUTE}`}
        />
        {isShopFirstEnabled && (
          <Route
            path={`${REGISTER_PATH}/${REGISTER_SHOP_FIRST_DETAILED_NESTED_PATH}/:formPath?/:sectionPath?/:stepPath?`}
            element={<ShopFirstDetailedRegister />}
          />
        )}
        {isSignupEnabled && (
          <Route path={`${REGISTER_PATH}${ID_ATTRIBUTE}?`}>
            <Route index element={<DefaultRegister />} />
            <Route
              path={REGISTER_CONFIRM_NESTED_PATH}
              element={<DefaultRegister defaultStepNumber={3} />}
            />
            {isShopFirstEnabled && (
              <>
                <Route
                  path={`${REGISTER_SHOP_FIRST_NESTED_PATH}/confirm`}
                  element={<ShopFirstRegister defaultStepNumber={3} />}
                />
                <Route
                  path={`${REGISTER_SHOP_FIRST_NESTED_PATH}/*`}
                  element={<ShopFirstRegister />}
                />
              </>
            )}
          </Route>
        )}
        <Route element={<Authentication />} path={WILDCARD_PATH} />
        <Route path="*" element={<Navigate to={HOME_PATH} />} />
      </Routes>
      <Banner isGuest />
    </Suspense>
  );
};

export default Guest;
