import styled, { css } from 'styled-components';

/* eslint-disable import/prefer-default-export */

export const Component = styled.div<{ $isClickable: boolean }>`
  width: 2rem;
  height: 2rem;
  border: 0.125rem solid ${({ theme }) => theme.colors.white};
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.persimon};
  color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  display: flex;
  object-fit: cover;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  ${({ $isClickable }) =>
    $isClickable &&
    css`
      cursor: pointer;
      &:hover,
      &:focus {
        border-color: ${({ theme }) => theme.colors.goldenRod};
      }
    `}
`;
