import usePaymentPageDetails from 'graphql/hooks/usePaymentPageDetails';
import { until, useMediaQuery } from 'styles/media';
import { ConnectProps } from './types';

const useConnect = ({ isLoggedIn, offerId }: ConnectProps) => {
  const { details, loading } = usePaymentPageDetails({
    offerId,
  });

  const { paymentDetails, isPaymentSubmitted } = details || {};

  const hasNoAccessToPaymentDetails =
    isLoggedIn && isPaymentSubmitted && !paymentDetails;

  return {
    details,
    hasNoAccessToPaymentDetails,
    isMobile: useMediaQuery(until.tablet),
    loading,
  };
};

export default useConnect;
