import { memo } from 'react';

import { Component } from './styles';
import { Props } from './types';

const Avatar = ({ className, initials, onClick, url, ...props }: Props) => {
  if (url) {
    return (
      <Component
        alt="User profile image"
        as="img"
        className={className}
        onClick={onClick}
        $isClickable={!!onClick}
        src={url}
        {...props}
      />
    );
  }

  return (
    <Component
      className={className}
      onClick={onClick}
      $isClickable={!!onClick}
      {...props}
    >
      {initials}
    </Component>
  );
};

export default memo(Avatar);
