import { PropsWithChildren, cloneElement, isValidElement } from 'react';
import { Props } from './types';
import {
  Container,
  InputContainer,
  Label,
  Error,
  LabelContainer,
  ToolTipIconContainer,
} from './styles';
import { useTheme } from 'styled-components';
import Tooltip from 'components/Tooltip';
import { FiHelpCircle as HelpIcon } from 'react-icons/fi';
import convertSnakeCase from 'utils/convertSnakeCase';

const BasisTheoryInputContainer = ({
  name,
  children,
  readOnly,
  disabled,
  error,
  toolTipMessage,
}: PropsWithChildren<Props>) => {
  const theme = useTheme();
  const child =
    isValidElement(children) &&
    cloneElement<any>(children, {
      inputMode: 'numeric',
      style: {
        base: {
          ':disabled': {
            backgroundColor: theme.colors.bluewoodLight,
          },
          fontSize: '1rem',
          padding: '0.5rem 0',
        },
        invalid: { color: theme.colors.persimon },
      },
    });
  if (!child) {
    return null;
  }
  return (
    <Container>
      <LabelContainer>
        <Label>{name}</Label>
        {toolTipMessage && (
          <Tooltip alignLeft content={toolTipMessage} disableOutline>
            <ToolTipIconContainer>
              <HelpIcon size={'1rem'} aria-hidden="true" focusable="false" />
            </ToolTipIconContainer>
          </Tooltip>
        )}
      </LabelContainer>
      <InputContainer
        $readOnly={readOnly}
        $disabled={disabled}
        $error={!!error}
      >
        {child}
      </InputContainer>
      {error && <Error>{convertSnakeCase(error)}</Error>}
    </Container>
  );
};
export default BasisTheoryInputContainer;
