import { useApolloClient, useSubscription } from '@apollo/client';
import {
  MeQuery,
  OnPolicyDataPullCompletedSubscription,
  PoliciesQuery,
} from 'graphql/generated';
import { COMPLETED, POLICY, SYNC_PENDING } from 'globals/constants';
import { ME } from 'graphql/queries/me';
import { POLICIES } from 'graphql/queries/policy';
import { ON_USER_POLICY_CHANGE } from 'graphql/subscriptions/policyChange';

const usePolicyCanopyPull = () => {
  const rawClient = useApolloClient();

  const { data } = useSubscription<OnPolicyDataPullCompletedSubscription>(
    ON_USER_POLICY_CHANGE,
    {
      onData: async ({ client }) => {
        await client.query<PoliciesQuery>({
          fetchPolicy: 'network-only',
          query: POLICIES,
          variables: { isCurrent: true },
        });
      },
    },
  );

  if (data) {
    const cache = rawClient.readQuery<MeQuery>({
      query: ME,
    });

    if (cache?.me) {
      const updatedProgress = cache.me?.progressBar?.map((x) => {
        if (x.action === POLICY && x.status !== COMPLETED) {
          return {
            __typename: x.__typename,
            action: x.action,
            status: SYNC_PENDING,
          };
        }
        return x;
      });
      rawClient.writeQuery<MeQuery>({
        data: {
          me: {
            ...cache?.me,
            progressBar: updatedProgress,
          },
        },
        query: ME,
      });
    }
  }

  return { data };
};

export default usePolicyCanopyPull;
