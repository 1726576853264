import { useField, useFormikContext } from 'formik';
import { useEffect } from 'react';

import { LogicProps } from './types';

const useLogic = ({ name }: LogicProps) => {
  const [{ value }, { error }] = useField<string | string[]>(name);

  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (value?.length === 0 && !Array.isArray(value)) {
      setFieldValue(name, []);
    }
  }, [value?.length, name, setFieldValue, value]);

  return {
    error,
  };
};

export default useLogic;
