import { Dispatch, SetStateAction } from 'react';
import { makeVar } from '@apollo/client';
import {
  ArchivePolicy,
  Information,
  InsiderShare,
  MarbleOffer,
  NewShoppingCart,
  Placement,
  Progress,
  Redemption,
  Reminder,
  RemoveConfirmation,
  ShareAccess,
  ShoppingSegment,
  ShoppingZipCode,
  Tracker,
  VideoPlayer,
  AddPolicy,
  EditHealthPolicy,
  UpdateCryptoAddress,
  MarketingLander,
  DismissOffer,
  ShoppingCartId,
  OfferId,
} from 'graphql/hooks/useModals/types';

export enum ModalEnumTypes {
  ADD_POLICY = 'add-policy',
  ARCHIVE_HDV_POLICY = 'archive-hdv-policy',
  ARCHIVE_POLICY = 'archive-policy',
  ARCHIVE_SHOPPING_CART = 'archive-shopping-cart',
  AVATAR_PICKER = 'avatar-picker',
  BALANCE = 'balance',
  DEC_UPLOAD = 'dec-upload',
  COMPARE_CARRIER = 'compare-carrier',
  CONFIRM_NAME = 'confirm-name',
  CONFIRM_PAYMENT_METHOD = 'confirm-payment-method',
  DISMISS_OFFER = 'dismiss-offer',
  DOCUMENT_INFORMATION = 'document-information',
  GET_QUOTE_CATEGORY_PICKER = 'get-quote-category-picker',
  GET_QUOTE_EXISTING_CART = 'get-quote-existing-cart',
  GET_QUOTE_LOADING = 'get-quote-loading',
  GET_APP = 'get-app',
  FEAT = 'feat',
  FEATURED_PARTNER = 'featured-partner',
  FEEDBACK = 'feedback',
  FEEDBACK_TRACKER = 'feedback-tracker',
  FEEDBACK_SUBMIT = 'feedbackSubmit',
  INFORMATION = 'information',
  INFORMATION_REASON = 'information-reason',
  INSURANCE_INSIDER = 'insurance-insider',
  INSURANCE_INSIDER_SHARE = 'insurance-insider-share-report',
  MARKETING_LANDING = 'marketing-landing',
  PHONE_NUMBER_VERIFICATION = 'phone-verification',
  PROGRESS = 'progress-modal',
  REDEMPTION = 'redemption',
  REFERRAL = 'referral',
  REMINDER = 'reminder',
  REMINDER_CONFIRMATION = 'reminder-confirmation',
  REMOVE_CONFIRMATION = 'remove-confirmation',
  REQUEST_OFFER_INFO = 'request-offer-info',
  SAVE_SHOP_FIRST_DETAILED_FORM = 'save-shop-first-detailed-form',
  SHARE_ACCESS = 'share-access',
  VIDEO_PLAYER = 'video-player',
  VIEW_CARRIER = 'view-carrier',
  PLACEMENT = 'placement',
  SHOP_ZIP_CODE = 'shop-zip-code',
  EDIT_HEALTH_POLICY = 'edit-health-policy',
}

export const initialState = {
  active: [],
  data: undefined,
  isClosing: false,
  path: undefined,
};

export type Modal = {
  data?: {
    archivePolicy?: ArchivePolicy;
    handleSubmit?: () => Promise<void>;
    information?: Information;
    insiderShare?: InsiderShare;
    marbleOffer?: MarbleOffer;
    progressModal?: Progress;
    redemption?: Redemption;
    reminder?: Reminder;
    removeConfirmation?: RemoveConfirmation;
    tracker?: Tracker;
    videoPlayer?: VideoPlayer;
    placement?: Placement;
    shoppingZipCode?: ShoppingZipCode;
    newShoppingCart?: NewShoppingCart;
    shareAccess?: ShareAccess;
    shoppingSegment?: ShoppingSegment;
    addPolicy?: AddPolicy;
    editHealthPolicy?: EditHealthPolicy;
    updateCryptoAddress?: UpdateCryptoAddress;
    setVerificationSuccess?: Dispatch<SetStateAction<boolean>> | undefined;
    marketingLander?: MarketingLander;
    dismissOffer?: DismissOffer;
    shoppingCartId?: ShoppingCartId;
    offerId?: OfferId;
    onSuccess?: () => void;
  };

  active: string[];
  isClosing: boolean;
  path?: string;
};

export const modals = makeVar<Modal>(initialState);
