import { gql } from '@apollo/client';
import { POLICY_FRAGMENT } from 'graphql/fragments';

export const ARE_CARRIER_CREDENTIALS_VALID = gql`
  query areCarrierCredentialsValid(
    $carrierId: UUID!
    $username: String!
    $password: String!
  ) {
    areCarrierCredentialsValid(
      carrierId: $carrierId
      username: $username
      password: $password
    )
  }
`;

export const POLICIES = gql`
  query Policies($isCurrent: Boolean) {
    policies(isCurrent: $isCurrent) {
      ...PolicyParts
    }
  }
  ${POLICY_FRAGMENT}
`;
