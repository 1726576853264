import { CarrierPartsFragment } from 'graphql/generated';
import notEmpty from 'utils/notEmpty';

import { PolicyCategory, normalizePolicyCategory } from './PolicyCategory';

const defaultScores = [
  { category: 'Customer Satisfaction', score: 'B' },
  { category: 'Price', score: 'B' },
  { category: 'Claims', score: 'B' },
  { category: 'Financial Health', score: 'B' },
  { category: 'Digital Experience', score: 'B' },
];

type InputCarrier = CarrierPartsFragment;

const formatScores = (scores: any) => {
  const sanitizedScores = scores.map((cat: any) => {
    const { category, score } = cat;
    return {
      category: category.toLowerCase().replace('_', ' '),
      score,
    };
  });
  return sanitizedScores;
};

export const normalizeCarrier = (input: InputCarrier) => ({
  __typename: input.__typename || '',
  avgRating: input.avgRating,
  canopyId: input.canopyId || undefined,
  carrierSymbol: input.carrierSymbol ? input.carrierSymbol : '',
  categories: input.categories
    ? input.categories
        .filter(notEmpty)
        .map(normalizePolicyCategory)
        .filter((category): category is PolicyCategory => !!category)
    : [],
  claimsRank: input.claimsScore || 0,
  claimsScore: input.claimsScore || 0,
  claimUrl: input.claimUrl,
  considerations: input.considerations
    ? input.considerations.filter(notEmpty)
    : [],
  contactUrl: input.contactUrl,
  createAccountUrl: input.createAccountUrl || undefined,
  decTipsUrl: input.decTipsUrl,
  description: input.description || '',
  disablePasswordField: input.disablePasswordField || undefined,
  discounts: input.discounts || [],
  goodFor: input.goodFor ? input.goodFor.filter(notEmpty) : [],
  hideInsider: input.hideInsider || false,
  id: input.id,
  logo: input.logo,
  marbleScore: input.marbleScore || 'B',
  name: input.name,
  numRatings: input.numRatings || 0,
  rating: input?.rating,
  resetPasswordUrl: input.resetPasswordUrl || undefined,
  scores:
    input?.scores && input.scores.length > 4
      ? formatScores(input.scores)
      : defaultScores,
  thirdFieldPlaceholder: input.thirdFieldPlaceholder || undefined,
  usernameFieldPlaceholder: input.usernameFieldPlaceholder || undefined,
});

export type Carrier = ReturnType<typeof normalizeCarrier>;
