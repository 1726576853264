import { gql } from '@apollo/client';

export const OFFER_FRAGMENT = gql`
  fragment OfferParts on OfferType {
    id
    partner
    policyIds
    premium
    policyType
    termMonths
    offerDocument
    link
    carrier
    expiryDate
    logo
    savings
    partnerQuoteId
    shoppingCartId
    userSavedDate
    tier
    status
    template {
      headline
      description
      logo
      ctaCopy
      ctaIcon
      finePrint
    }
    coverages {
      othersBodilyPerson
      othersBodilyAccident
      othersPropertyAccident
      othersMedicalPayments
      uninsuredMotoristPerson
      comprehensiveDeductible
      collisionDeductible
      aDwelling
      bOtherStructures
      cPersonalProperty
      dLossUse
      ePersonalLiability
      fMedicalOthers
      deductibleWind
      deductible
      coinsurancePercentage
      reimbursementPercentage
      deathBenefit
      cashValue
      comparisonData {
        othersBodilyPerson {
          label
          value
        }
        othersBodilyAccident {
          label
          value
        }
        othersPropertyAccident {
          label
          value
        }
        othersMedicalPayments {
          label
          value
        }
        uninsuredMotoristPerson {
          label
          value
        }
        comprehensiveDeductible {
          label
          value
        }
        collisionDeductible {
          label
          value
        }
        aDwelling {
          label
          value
        }
        bOtherStructures {
          label
          value
        }
        cPersonalProperty {
          label
          value
        }
        dLossUse {
          label
          value
        }
        ePersonalLiability {
          label
          value
        }
        fMedicalOthers {
          label
          value
        }
        deductibleWind {
          label
          value
        }
        deductible {
          label
          value
        }
        coinsurancePercentage {
          label
          value
        }
        reimbursementPercentage {
          label
          value
        }
        deathBenefit {
          label
          value
        }
        cashValue {
          label
          value
        }
      }
    }
  }
`;
