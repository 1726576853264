/* eslint-disable import/prefer-default-export */

export const appConfig = {
  apiUrl: process.env.REACT_APP_API_URL,
  appleClientId: process.env.REACT_APP_APPLE_ID,
  appleRedirectUrl: process.env.REACT_APP_APPLE_REDIRECT,
  authMachineUrl: process.env.REACT_APP_AUTH_MACHINE_URL,
  environment: process.env.REACT_APP_ENVIRONMENT,
  fullStoryOrganization: process.env.REACT_APP_FULLSTORY_ORGANIZATION,
  googleClientId: process.env.REACT_APP_GOOGLE_ID,
  recaptchaKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  segmentApiKey: process.env.REACT_APP_SEGMENT_API_KEY,
  smartyApiKey: process.env.REACT_APP_SMARTY_WEBSITE_KEY,
  websocketUrl: process.env.REACT_APP_WS_ENDPOINT,
  zendeskKey: process.env.REACT_APP_ZENDESK_KEY,
};
