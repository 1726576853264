import { MouseEvent, useCallback } from 'react';

import { LogicProps } from './types';

const useLogic = ({ defaultOnClick }: LogicProps) => {
  const onClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (defaultOnClick) {
        event.preventDefault();
        event.stopPropagation();

        defaultOnClick(event);
      }
    },
    [defaultOnClick],
  );

  return {
    onClick,
  };
};

export default useLogic;
