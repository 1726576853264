import styled, { css } from 'styled-components';
import { InputContainerProps } from './types';
import { OverlineSemi } from 'components/Typography';

export const Container = styled.div`
  width: 100%;
`;

export const Label = styled(OverlineSemi).attrs({ as: 'label' })`
  color: ${({ theme }) => theme.colors.starDust};
  text-transform: uppercase;
`;

export const LabelContainer = styled.div`
  display: flex;
  svg {
    color: ${({ theme }) => theme.colors.starDust};
  }
`;

export const ToolTipIconContainer = styled.span`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
`;

export const InputContainer = styled.div<InputContainerProps>`
  > div {
    inline-size: 100%;
  }
  inline-size: 100%;
  display: flex;
  align-items: stretch;
  min-height: 2.4rem;
  margin-top: 0.25rem;
  padding: 0 0.5rem;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.375rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: 0.0625rem solid;
  border-color: ${({ theme }) => theme.colors.quillGray};
  border-radius: 0.25rem;

  ${({ $readOnly, theme }) =>
    $readOnly
      ? css`
          border-color: ${theme.colors.desertStorm};
        `
      : null}

  ${({ $error, theme }) =>
    $error
      ? css`
          border-color: ${theme.colors.persimon};
        `
      : null}

  ${({ $disabled }) =>
    $disabled
      ? css`
          background-color: ${({ theme }) => theme.colors.bluewoodLight};
        `
      : null}

  ${({ $placeholderColor, theme }) =>
    $placeholderColor &&
    css`
      & ::placeholder {
        color: ${theme.colors[$placeholderColor]};
      }
    `}
`;

export const Error = styled.span`
  min-height: 0.75rem;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.persimon};
  font-size: 0.625rem;
  line-height: 0.75rem;

  ::first-letter {
    text-transform: uppercase;
  }
`;
