import TopBar from 'containers/Main/TopBar';
import {
  ARCHIVED_POLICIES_PATH,
  HOME_PATH,
  ID_ATTRIBUTE,
  INSURANCE_INSIDER_PATH,
  MOBILE_SHOP_PATH,
  OTHER_POLICIES_PATH,
  PAYMENT_PATH,
  PERKS_PATH,
  POLICIES_PATH,
  REFER_FIVE_PATH,
  REWARDS_PATH,
  SETTINGS_PATH,
  SHARE_POLICY_PATH,
  SHOP_PATH,
  WHATS_NEW_PATH,
  WILDCARD_PATH,
} from 'globals/constants';
import { Suspense, lazy, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Banner from 'containers/Shared/Banner';
import Footer from 'components/Footer';
import Loader from 'components/FullscreenLoader';

import useConnect from './connect';
import { CommonModals, HasPolicyModals, PaymentModals } from './modals';
import useModals from 'graphql/hooks/useModals';
import { ModalEnumTypes } from 'graphql/variables/modal';
import useMe from 'graphql/hooks/useMe';
import PaymentDetails from 'containers/Guest/PaymentDetails';

const ArchivedPolicies = lazy(() => import('./ArchivedPolicies'));
const Home = lazy(() => import('./Home'));
const InsuranceInsider = lazy(() => import('../Guest/InsuranceInsider'));
const Policies = lazy(() => import('./Policies'));
const Settings = lazy(() => import('./Settings'));
const SharePolicy = lazy(() => import('containers/Guest/SharePolicy'));
const Shop = lazy(() => import('./Shop'));

const OtherPolicies = lazy(() => import('./OtherPolicies'));
const Rewards = lazy(() => import('./Rewards'));
const WhatsNew = lazy(() => import('./WhatsNew'));
const Perks = lazy(() => import('./Perks'));

const Main = () => {
  const { features, loading, hasPolicies } = useConnect();
  const { openModal } = useModals();
  const { me } = useMe();
  const { CONFIRM_NAME } = ModalEnumTypes;

  useEffect(() => {
    if (!me?.legalNameConfirmed) openModal(CONFIRM_NAME);
  }, [CONFIRM_NAME, me?.legalNameConfirmed, openModal]);

  if (loading) return <></>;

  const policyRoutes = [
    <Route
      element={<OtherPolicies />}
      path={`${OTHER_POLICIES_PATH}${WILDCARD_PATH}`}
      key="other-policies-component"
    />,
    <Route element={<Rewards />} path={REWARDS_PATH} key="rewards-component" />,
    <Route element={<Home />} path={REFER_FIVE_PATH} key="refer-component" />,
  ];

  const featuresRoutes = [
    <Route element={<WhatsNew />} path={WHATS_NEW_PATH} key="new-component" />,
  ];

  return (
    <>
      <TopBar />
      <Suspense fallback={<Loader isFullPage={true} />}>
        <Routes>
          <Route element={<Home />} path={HOME_PATH} />
          <Route
            element={<PaymentDetails userId={me?.id} />}
            path={`${PAYMENT_PATH}/:offerId`}
          />
          <Route
            element={<ArchivedPolicies />}
            path={`${ARCHIVED_POLICIES_PATH}${WILDCARD_PATH}`}
          />
          <Route
            element={<InsuranceInsider />}
            path={`${INSURANCE_INSIDER_PATH}${WILDCARD_PATH}`}
          />
          <Route
            element={<Policies />}
            path={`${POLICIES_PATH}${WILDCARD_PATH}`}
          />
          <Route
            element={<Settings />}
            path={`${SETTINGS_PATH}${WILDCARD_PATH}`}
          />
          <Route element={<Shop />} path={`${SHOP_PATH}${WILDCARD_PATH}`} />
          <Route
            element={<Shop />}
            path={`${MOBILE_SHOP_PATH}${WILDCARD_PATH}`}
          />
          <Route
            element={<SharePolicy />}
            path={`${SHARE_POLICY_PATH}${ID_ATTRIBUTE}`}
          />
          <Route element={<Perks />} path={PERKS_PATH} key="perks-component" />
          {features.length > 0 && featuresRoutes}
          {hasPolicies && policyRoutes}
          {!loading && (
            <Route path={WILDCARD_PATH} element={<Navigate to={HOME_PATH} />} />
          )}
        </Routes>
        <Footer />
        {!loading && <Banner />}
        {!loading && CommonModals}
        {!loading && HasPolicyModals}
        {!loading && PaymentModals}
      </Suspense>
    </>
  );
};

export default Main;
