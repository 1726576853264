import { gql } from '@apollo/client';
import { PAYMENT_PAGE_FRAGMENT } from 'graphql/fragments/payments';

export const GET_PAYMENT_PAGE_DETAILS = gql`
  query PaymentPageDetails($offerId: UUID!) {
    paymentPageDetails(offerId: $offerId) {
      ...PaymentPageParts
    }
  }
  ${PAYMENT_PAGE_FRAGMENT}
`;
