import { useQuery } from '@apollo/client';
import { PoliciesQuery, PoliciesQueryVariables } from 'graphql/generated';
import { POLICIES } from 'graphql/queries/policy';
import { Policy, normalizePolicy } from 'models/Policy';
import { useEffect, useState } from 'react';

import showSyncedAnimation from './cache';

const usePolicies = ({
  variables,
  skip,
}: {
  variables?: PoliciesQueryVariables;
  skip?: boolean;
} = {}) => {
  const { data, refetch: rawRefetch } = useQuery<
    PoliciesQuery,
    PoliciesQueryVariables
  >(POLICIES, { skip, variables });

  const [normalizedPolicies, setNormalizedPolicies] = useState<Policy[]>();
  const [hasSyncedPolicy, setHasSyncedPolicy] = useState(false);
  const [hasSyncedCurrentPolicy, setHasSyncedCurrentPolicy] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (data?.policies) {
      const normalized = data.policies.map(normalizePolicy);
      setNormalizedPolicies(normalized);
    }
  }, [data]);

  useEffect(() => {
    if (normalizedPolicies) {
      setIsLoading(false);
    }
  }, [normalizedPolicies]);

  useEffect(() => {
    if (normalizedPolicies) {
      const hasAnimation = normalizedPolicies.filter(
        (policy) => policy.showSyncAnimation,
      );
      if (hasAnimation.length > 0) {
        showSyncedAnimation(hasAnimation.length * 500);
      }
    }
  }, [normalizedPolicies]);

  useEffect(() => {
    if (normalizedPolicies) {
      const syncedPolicy = normalizedPolicies?.find(
        (policy) => policy && policy?.isSynced,
      );
      setHasSyncedPolicy(!!syncedPolicy);
    }
  }, [normalizedPolicies]);

  useEffect(() => {
    if (normalizedPolicies) {
      const hasCurrentPolicy = normalizedPolicies.find(
        (policy) =>
          policy.isSynced &&
          !!policy?.isPolicyOwner &&
          ((policy.endDate === undefined && policy.continuous) ||
            (policy.endDate && policy.endDate > new Date().getTime())),
      );
      setHasSyncedCurrentPolicy(!!hasCurrentPolicy);
    }
  }, [normalizedPolicies]);

  return {
    hasSyncedCurrentPolicy,
    hasSyncedPolicy,
    loading: isLoading,
    policies: normalizedPolicies || [],
    refetch: rawRefetch,
  };
};

export default usePolicies;
