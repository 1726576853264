import useMe from 'graphql/hooks/useMe';
import useModals from 'graphql/hooks/useModals';
import useRewards from 'graphql/hooks/useRewards';
import { ModalEnumTypes } from 'graphql/variables/modal';
import { useCallback, useLayoutEffect } from 'react';
import { track } from 'services/segment';

import { ConnectProps } from './types';

const useConnect = ({ onClose }: ConnectProps) => {
  const { me } = useMe();
  const { openModal } = useModals();
  const { rewards } = useRewards();

  const handleTrackSettings = useCallback(() => {
    if (me && !me.isViewedAs) track('Settings - Clicked');
    onClose();
  }, [me, onClose]);

  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  });

  const handleReferralModal = () => {
    onClose();
    openModal(ModalEnumTypes.REFERRAL);
  };

  return {
    handleTrackSettings,
    openReferralModal: handleReferralModal,
    referralValue: rewards?.REA0?.value || 500,
  };
};

export default useConnect;
