import { VehicleAssetType } from 'graphql/generated';

type InputVehicle = Omit<VehicleAssetType, 'policy'>;

export const normalizeVehicle = (input: InputVehicle) => ({
  id: input.id,
  make: input.make,
  model: input.model,
  ownership: input.ownership,
  primaryUse: input.primaryUse,
  vehicleInfo: input.vehicleInfo,
  vehicleVin: input.vehicleVin,
  year: input.year,
});

export type Vehicle = ReturnType<typeof normalizeVehicle>;
