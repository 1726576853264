import { useQuery } from '@apollo/client';
import { SiteConfigurationQuery } from 'graphql/generated';
import { SITE_CONFIGURATION } from 'graphql/queries/siteConfiguration';
import { normalizeSiteConfiguration } from 'models/SiteConfiguration';
import { useMemo } from 'react';

const useSiteConfiguration = () => {
  const { data } = useQuery<SiteConfigurationQuery>(SITE_CONFIGURATION);

  const siteConfiguration = useMemo(
    () => normalizeSiteConfiguration(data?.siteConfiguration),
    [data],
  );

  return siteConfiguration;
};

export default useSiteConfiguration;
