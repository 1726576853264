import { AUTO, HOME, LIFE, PET, RENTER } from 'globals/constants';
import { ReactComponent as AutoIllustration } from 'assets/images/illustrations/auto.svg';
import { ReactComponent as HomeIllustration } from 'assets/images/illustrations/home.svg';
import { ReactComponent as LifeIllustration } from 'assets/images/illustrations/life.svg';
import { ReactComponent as PetIllustration } from 'assets/images/illustrations/pet.svg';
import { ReactComponent as RentIllustration } from 'assets/images/illustrations/rent.svg';
import { Props } from './types';
import { Container } from './styles';

const PolicyCategoryIllustration = ({
  height,
  policyCategory,
  width,
}: Props) => {
  return (
    <Container $height={height} $width={width}>
      {policyCategory === AUTO && <AutoIllustration />}
      {policyCategory === HOME && <HomeIllustration />}
      {policyCategory === LIFE && <LifeIllustration />}
      {policyCategory === PET && <PetIllustration />}
      {policyCategory === RENTER && <RentIllustration />}
    </Container>
  );
};

export default PolicyCategoryIllustration;
