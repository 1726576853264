import { FiChevronDown, FiLock } from 'react-icons/fi';
import { NavLink, Link as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import addAlpha from 'utils/addAlpha';

import DefaultAvatar from 'components/Avatar';
import { ReactComponent as DefaultClickOutIcon } from 'assets/images/icons/box-arrow-up-right.svg';

export const Container = styled.nav`
  background-color: ${({ theme }) => theme.colors.bluewood};
`;

export const Bar = styled.div`
  display: flex;
  align-items: center;
  height: 3.75rem;
  padding: 0 1.25rem;
`;

export const LogoLink = styled(RouterLink)`
  margin-right: 1.25rem;
`;

export const Avatar = styled(DefaultAvatar)`
  margin-left: auto;
`;

export const Links = styled.div`
  position: absolute;
  top: 3rem;
  left: 0;
  z-index: 9;
  width: 100%;
  padding: 1.5rem;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  background-color: ${({ theme }) => theme.colors.bluewood};
`;

const linkStyles = css`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.semiBold};
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-decoration: none;
`;

export const CurrentRoute = styled.button`
  ${linkStyles}
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin-right: 1.25rem;
  overflow: hidden;

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const Chevron = styled(FiChevronDown)<{
  $isDropdown?: boolean;
  $isOpen: boolean;
}>`
  ${({ $isDropdown }) =>
    $isDropdown ? 'margin-left: auto;' : 'margin-left: 0.375rem;'}

  color: ${({ theme }) => theme.colors.white};
  font-size: 1.25rem;
  flex-shrink: 0;

  ${({ $isOpen }) =>
    $isOpen ? 'transform: rotate(180deg);' : 'transform: rotate(0);'}
`;

export const Link = styled(NavLink)<{
  $active?: boolean;
  $isDropdown?: boolean;
}>`
  ${linkStyles}
  padding: 0.875rem 0 1rem 5rem;

  ${({ $isDropdown }) =>
    $isDropdown &&
    css`
      inline-size: 100%;
      padding: 1rem;
      &:not(:last-child) {
        margin-block-start: 0.5rem;
      }
    `}

  background-color: ${({ $active, theme }) =>
    $active
      ? `${addAlpha(theme.colors.white, 0.04)}`
      : `${theme.colors.bluewood}`};

  &:hover,
  &:focus {
    background-color: ${({ theme }) => addAlpha(theme.colors.white, 0.04)};
  }
`;

export const ExternalLink = styled.a<{ $active?: boolean }>`
  ${linkStyles}
  width: 100%;
  padding: 1rem;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => addAlpha(theme.colors.white, 0.04)};
  }
`;

export const LockIcon = styled(FiLock)`
  width: 1rem;
  height: 1rem;
  color: inherit;
`;

export const Dropdown = styled.button<{ $isOpen: boolean }>`
  ${linkStyles}
  background-color: inherit;
  border: none;
  flex-wrap: wrap;
  padding: 0.875rem 0 ${({ $isOpen }) => ($isOpen ? '0rem' : '1rem')} 5rem;
  width: 100%;

  &.active {
    background-color: ${({ theme }) => addAlpha(theme.colors.white, 0.04)};
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ClickOutIcon = styled(DefaultClickOutIcon)`
  margin-left: 0.5rem;
  > path {
    stroke: ${({ theme }) => theme.colors.white};
    stroke-width: 0.05rem;
  }
`;
