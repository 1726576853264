import { SiteConfigurationQuery } from 'graphql/generated';

export const normalizeSiteConfiguration = (
  input?: SiteConfigurationQuery['siteConfiguration'],
) => ({
  appleStoreLink: input?.appleStoreLink,
  featureToggles: input?.featureToggles,
  googleStoreLink: input?.googleStoreLink,
  mobileAppLive: !!input?.mobileAppLive,
});

export type OtherPolicy = ReturnType<typeof normalizeSiteConfiguration>;
