import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */

export const CARRIER_FRAGMENT = gql`
  fragment CarrierParts on CarrierType {
    avgRating
    id
    name
    carrierSymbol
    categories
    claimsRank
    claimsScore
    claimUrl
    considerations
    contactUrl
    description
    discounts
    goodFor
    hideInsider
    logo
    marbleScore
    numRatings
    rating
    scores {
      category
      score
    }
    canopyId
    createAccountUrl
    resetPasswordUrl
    thirdFieldPlaceholder
    usernameFieldPlaceholder
    disablePasswordField
    decTipsUrl
  }
`;
