import { CheckIcon, Container, IconContainer, Title, Subtitle } from './styles';
import { Props } from './types';
import ButtonLink from 'components/ButtonLink';

const PaymentDetailsConfirmation = ({
  carrier,
  email = 'the registered email.',
}: Props) => {
  return (
    <Container>
      <IconContainer>
        <CheckIcon />
      </IconContainer>
      <Title>Payment details submitted</Title>
      <Subtitle>
        {`Thanks so much for submitting your payment details! ${carrier} will
        reach out with policy details and next steps within 1-2 hours at ${email}`}
      </Subtitle>
      <ButtonLink href="/">Go to wallet</ButtonLink>
    </Container>
  );
};

export default PaymentDetailsConfirmation;
