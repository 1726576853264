import useModals from 'graphql/hooks/useModals';

const useConnect = () => {
  const { animate } = useModals();

  return {
    animate,
  };
};

export default useConnect;
