import { gql } from '@apollo/client';

export const SITE_CONFIGURATION = gql`
  query SiteConfiguration {
    siteConfiguration {
      appleStoreLink
      googleStoreLink
      mobileAppLive
      featureToggles {
        name
        isEnabled
      }
    }
  }
`;
