import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */

export const MARKETING_LANDING_TESTIMONIALS_FRAGMENT = gql`
  fragment MarketingLandingTestimonialsParts on TestimonialType {
    image
    location
    rating
    text
    title
    userName
  }
`;

export const MARKETING_LANDING_COMMON_FRAGMENT = gql`
  fragment MarketingLandingCommonParts on CommonLandingDataType {
    mainImage
    headerTitle
    subheader1Title
    subheader1Text
    subheader2Title
    subheader2Text
    subheader3Title
    subheader3Text
    subheader4Title
    subheader4Text
    footerTitle
    footerCta
    testimonials {
      ...MarketingLandingTestimonialsParts
    }
  }
  ${MARKETING_LANDING_TESTIMONIALS_FRAGMENT}
`;
