// eslint-disable-next-line import/prefer-default-export
export const getCode = (path: string) => {
  if (path === '/register') {
    return '';
  }
  const nextParam = path.split('/register/')[1];
  if (nextParam && !nextParam.includes('shop')) {
    return nextParam;
  }
};
