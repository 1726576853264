import segmentPlugin from '@analytics/segment';
import fullStoryPlugin from '@analytics/fullstory';
import Analytics from 'analytics';
import { appConfig } from 'config';
import { isClickjacked } from 'globals/constants';

export type SegmentEvent =
  | 'Avatar - Clicked'
  | 'Balance Amount - Clicked'
  | 'Navbar - Clicked'
  | 'Navbar - Blog Link Clicked'
  | 'Document - Viewed'
  | 'Other Documents - Viewed'
  | 'Policy - Archive'
  | 'Policy - Carrier Selected'
  | 'Policy - Contact CTA'
  | 'Policy - DEC Upload Help'
  | 'Policy - Details Viewed'
  | 'Policy - Doc Download'
  | 'Policy - Show Details'
  | 'Policy - Type Selected'
  | 'Referral - Copy Link'
  | 'Referral - Share your URL Click'
  | 'Renewal - Learn More'
  | 'Rewards - Partner Offer Card Click'
  | 'Rewards - Partner Offer Modal Click'
  | 'Shopping - Get Quote Clicked'
  | 'Shopping - Get Quote Offer Clicked'
  | 'Shopping - Shop Now Click on Wallet'
  | 'Shopping - Remind Me Later Wallet Click'
  | 'Shopping - Remind Me - Save Reminder'
  | 'Shopping - Remind Me - Cancel'
  | 'Shopping - Learn How To Shop'
  | 'Shopping - Learn more'
  | 'Shopping - Create Shopping Cart From Shop Page'
  | 'Shopping - Create Reminder From Shop Page'
  | 'Shopping - Create Shopping Cart Policy Type Select'
  | 'Shopping - Create Shopping Cart Policy Select'
  | 'Shopping - Get a Quote Clicked'
  | 'Shopping - Get a Quote Type Clicked'
  | 'Shopping - Get a quote assets selected'
  | 'Shopping - Get a quote named insureds selected'
  | 'Shopping - Get a quote driver assignments selected'
  | 'Shopping - Show Details on Offer'
  | 'Settings - Clicked'
  | 'User - Logout'
  | 'Temp Shopping - YA Link Clicked'
  | 'Temp Shopping - Banner CTA Clicked'
  | 'Onboarding Q1Auto'
  | 'Onboarding Q2Home'
  | 'Onboarding Q2bRenters'
  | 'Onboarding Q3Pet'
  | 'Onboarding - Shopping - Assets Added'
  | 'Onboarding - Shopping - Active Policy Q Answered'
  | 'Onboarding - Shopping - Carrier Selected'
  | 'Onboarding - Shopping - Driver Assignments'
  | 'Onboarding - Shopping - Named Insured Added'
  | 'Onboarding - Shopping - Type Selected'
  | 'Onboarding - Success to Wallet'
  | 'Onboarding - Success to Add Policy';

export const analytics = Analytics({
  ...(!isClickjacked() && {
    plugins: [
      appConfig.fullStoryOrganization && !process.env.CI
        ? fullStoryPlugin({
            org: appConfig.fullStoryOrganization,
          })
        : [],
      segmentPlugin({
        addBundledMetadata: true,
        retryQueue: true,
        writeKey: appConfig.segmentApiKey,
      }),
    ].flat(),
  }),
});

export const identify = (userId: string, traits: any) => {
  analytics.identify(userId, traits);
};

export const reset = () => {
  analytics.reset();
};

export const track = (event: SegmentEvent, properties?: any) => {
  analytics.track(event, properties);
};

export const showZendesk = () => {
  window.zE('messenger', 'open');
};

export const pageView = () => {
  analytics.page();
};
