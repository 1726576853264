import { Portal } from 'components/BasePortal';
import { Notice } from 'components/Typography';
import useBanners from 'graphql/hooks/useBanners';

import {
  BannerContainer,
  BannerWrapper,
  CloseIcon,
  HTMLRenderer,
} from './styles';

const Banner = ({ isGuest = false }: { isGuest?: boolean }) => {
  const { banner, handleClose, isClosing } = useBanners();
  if (!banner) return null;

  const component = (
    <BannerContainer role="alert" $isClosing={isClosing} $isGuest={isGuest}>
      <BannerWrapper>
        <HTMLRenderer
          content={banner}
          custom={{
            p: (children) => <Notice>{children}</Notice>,
          }}
        />
        <CloseIcon onClick={handleClose} />
      </BannerWrapper>
    </BannerContainer>
  );
  return <Portal>{component}</Portal>;
};

export default Banner;
