import { ApolloProvider } from '@apollo/client';
import { Zendesk } from 'components/Zendesk';
import Root from 'containers/Root';
import client from 'graphql/client';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'styles/GlobalStyle';
import theme from 'styles/themes';

import useConnect from './connect';

const App = () => {
  const { animate } = useConnect();

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={{ ...theme, animate }}>
        <GlobalStyle />
        <Toaster />
        <BrowserRouter>
          <Zendesk />
          <HelmetProvider>
            <Root />
          </HelmetProvider>
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;
