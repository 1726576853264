import useMe from 'graphql/hooks/useMe';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { identify } from 'services/segment';
import { removeToken, setToken } from 'services/token';
import usePolicyActions from 'graphql/hooks/usePolicyActions';
import useUrlParams from 'hooks/useUrlParams';

const useConnect = () => {
  const { pathname } = useLocation();
  const { loading, me } = useMe();
  const { acceptSharedAccess } = usePolicyActions();
  const { cioLinkId, token, removeUrlParam, shared } = useUrlParams();

  if (token) {
    removeToken();
    setToken(token);
    window.location.replace(window.location.pathname);
  }

  useEffect(() => {
    // track universal links back to cio
    if (cioLinkId) {
      fetch(`http://email.marblepay.com/click/${cioLinkId}`, {
        headers: { 'Access-Control-Allow-Origin': '*' },
        method: 'POST',
        mode: 'no-cors',
      }).then(() => removeUrlParam('link_id'));
    }
    // eslint-disable-next-line
  }, [cioLinkId]);

  useEffect(() => {
    // accept sharedPolicyAccess if non-owner is already logged in when clicking the link
    if (shared && me) {
      acceptSharedAccess(shared);
      removeUrlParam('shared');
    }
    // eslint-disable-next-line
  }, [me]);

  useEffect(() => {
    // separate useEffect so that we don't call identify on every page view
    if (me && !me.isViewedAs) {
      identify(me.id, {
        email: me.email,
        first_name: me.firstName,
        last_name: me.lastName,
        name: `${me.firstName} ${me.lastName}`,
      });
    }
  }, [me]);

  useEffect(() => {
    if (me && !me.isViewedAs) {
      window.analytics.page(pathname);
    }
  }, [me, pathname]);

  return {
    loading,
    location,
    me,
  };
};

export default useConnect;
