import { OverlineBold } from 'components/Typography';
import styled from 'styled-components';
import { until } from 'styles/media';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const FormFields = styled.div`
  display: grid;
  flex-direction: row;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  div:first-child {
    grid-column: span 2;
  }
  ${until.mobile} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Title = styled(OverlineBold)``;

export const TitleContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const FormContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`;
