import { OverlineBold } from 'components/Typography';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const PaymentTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const PaymentFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Error = styled.span`
  min-height: 0.75rem;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.persimon};
  font-size: 0.625rem;
  line-height: 0.75rem;

  ::first-letter {
    text-transform: uppercase;
  }
`;

export const Title = styled(OverlineBold)``;
