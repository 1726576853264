import { gql } from '@apollo/client';

import { DOCUMENT_FRAGMENT } from './document';

/* eslint-disable import/prefer-default-export */

export const OTHER_POLICY_FRAGMENT = gql`
  fragment OtherPolicyParts on OtherPolicyType {
    id
    category
    documents {
      ...DocumentParts
    }
    otherCategory
  }
  ${DOCUMENT_FRAGMENT}
`;
