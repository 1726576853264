import { Link as DefaultInternalLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { from } from 'styles/media';
import addAlpha from 'utils/addAlpha';

import Button from 'components/Button';
import DefaultLogo from 'components/Logo';
import MaxWidth from 'components/MaxWidth';
import { Caption } from 'components/Typography';

const LinkCommonStyles = css`
  color: ${({ theme }) => theme.colors.starDust};
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-decoration: none;
`;

export const Container = styled.footer`
  margin-top: auto;
  background-color: ${({ theme }) => theme.colors.newPampas};
`;

export const Content = styled(MaxWidth)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  ${from.tablet} {
    height: 3.625rem;
  }
`;

export const Logo = styled(DefaultLogo)`
  color: ${({ theme }) => theme.colors.starDust};
`;

export const Links = styled.div`
  margin-left: auto;
  padding: 1.25rem 0;

  & > * {
    margin-inline-start: 2rem;

    ${from.mobile} {
      margin-inline-start: 2.5rem;
    }
  }

  ${from.tablet} {
    margin-inline-start: 0;
    padding: 0;
  }
`;

export const ExternalLink = styled.a`
  ${LinkCommonStyles}
`;

export const InternalLink = styled(DefaultInternalLink)`
  ${LinkCommonStyles}
`;

export const ReferButton = styled(Button)`
  ${LinkCommonStyles}
  background-color: inherit;
  border: none;
  display: inline;
  font-size: 0.875rem;
  font-weight: 400;
  height: auto;
  line-height: 1.25rem;
  &:hover,
  &:focus {
    background-color: inherit;
  }
`;

export const Copyright = styled(Caption)`
  width: 100%;
  padding: 1.5rem 0;
  color: ${({ theme }) => theme.colors.starDust};
  text-align: center;
  border-top: 0.0625rem solid
    ${({ theme }) => addAlpha(theme.colors.starDust, 0.1)};

  ${from.tablet} {
    width: auto;
    margin-inline-start: auto;
    padding: 0;
    text-align: end;
    border: 0;
  }
`;
