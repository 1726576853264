import { HOME_PATH } from 'globals/constants';
import { CheckIcon, Container, Title, Subtitle } from './styles';
import ButtonLink from 'components/ButtonLink';

const PaymentDetailsNotFound = () => {
  return (
    <Container>
      <CheckIcon />
      <Title>{`Sorry, we couldn’t find that offer`}</Title>
      <Subtitle>
        This is usually due to an incorrect or expired link. If you believe this
        message is incorrect, please reach out to Marble support.
      </Subtitle>
      <ButtonLink href={HOME_PATH}>Back to wallet</ButtonLink>
    </Container>
  );
};

export default PaymentDetailsNotFound;
