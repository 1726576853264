import useAuth from 'graphql/hooks/useAuth';
import useMe from 'graphql/hooks/useMe';
import useModals from 'graphql/hooks/useModals';
import useRewards from 'graphql/hooks/useRewards';
import { ModalEnumTypes } from 'graphql/variables/modal';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { track } from 'services/segment';
import usePolicies from 'graphql/hooks/usePolicies';
import {
  INSURANCE_INSIDER_PATH,
  PERKS_PATH,
  REWARDS_PATH,
  SETTINGS_PATH,
  WHATS_NEW_PATH,
} from 'globals/constants';

const useConnect = () => {
  const { logout } = useAuth();
  const { me } = useMe();
  const navigate = useNavigate();
  const { pathname: currentPathName } = useLocation();

  const { openModal } = useModals();
  const { rewards } = useRewards();
  const { policies } = usePolicies({ variables: { isCurrent: true } });
  const [hidden, setHidden] = useState<boolean>(true);

  const handleClickAway = useCallback(() => {
    setHidden(true);
  }, [setHidden]);

  const handleHide = useCallback(() => {
    setHidden((prev) => !prev);
  }, [setHidden]);

  const handleLogout = useCallback(logout, [logout]);

  const isLocked =
    policies.length === 0 || !policies.some((policy) => policy.isPolicyOwner);

  const handleRewardsClick = useCallback(
    (event: any) => {
      event.preventDefault();
      if (me && !me.isViewedAs) {
        track('Navbar - Clicked', {
          tab: 'BALANCE',
        });
      }

      if (isLocked) {
        openModal(ModalEnumTypes.BALANCE);
      } else {
        navigate(REWARDS_PATH);
      }
    },
    [me, isLocked, openModal, navigate],
  );

  const handleClick = (action: string) => {
    if (action === 'insuranceinsider') {
      navigate(INSURANCE_INSIDER_PATH);
    } else if (action === 'refer') {
      openModal(ModalEnumTypes.REFERRAL);
    } else if (action === 'whatsnew') {
      navigate(WHATS_NEW_PATH);
    } else if (action === 'settings') {
      if (me && !me.isViewedAs) track('Settings - Clicked');
      navigate(SETTINGS_PATH);
    } else if (action === 'perks') {
      navigate(PERKS_PATH);
    }
    handleHide();
  };

  return {
    currentPathName,
    handleClick,
    handleClickAway,
    handleHide,
    handleLogout,
    handleRewardsClick,
    hidden,
    isLocked,
    referralValue: rewards?.REA0?.value || 500,
    viewAs: me?.viewedAs,
  };
};

export default useConnect;
