import { memo } from 'react';

import Avatar from 'components/Avatar';

import Dropdown from './Dropdown';
import useConnect from './connect';
import { Container, Content, Link, Links, Logo, LogoLink } from './styles';
import { Props } from './types';
import {
  HOME_PATH,
  INSURANCE_INSIDER_PATH,
  PERKS_PATH,
  REWARDS_PATH,
  SHOP_PATH,
} from 'globals/constants';

const Desktop = ({
  avatarUrl,
  initials,
  onHomeClick,
  onShopClick,
  showWhatsNew,
}: Props) => {
  const { handleTrackAvatar, pathname } = useConnect();

  return (
    <Container>
      <Content>
        <LogoLink to="/">
          <Logo isBeta colorVariant="light" withColor />
        </LogoLink>
        <Links>
          <Link $active={pathname === HOME_PATH} onClick={onHomeClick} to="/">
            Home
          </Link>
          <Dropdown
            isRewards
            title="Rewards"
            paths={[REWARDS_PATH, PERKS_PATH]}
          />
          <Dropdown
            isTools
            title="Resources"
            paths={[INSURANCE_INSIDER_PATH]}
          />
          <Link
            $active={pathname === SHOP_PATH}
            to="/shop"
            onClick={onShopClick}
          >
            Shop
          </Link>

          <Dropdown
            isUserSettings
            showWhatsNew={showWhatsNew}
            title={
              <Avatar
                initials={initials}
                onClick={handleTrackAvatar}
                url={avatarUrl}
              />
            }
          />
        </Links>
      </Content>
    </Container>
  );
};

export default memo(Desktop);
