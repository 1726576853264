import { useSubscription } from '@apollo/client';
import { OnPolicySyncedSubscription, PoliciesQuery } from 'graphql/generated';
import useMe from 'graphql/hooks/useMe';
import { POLICIES } from 'graphql/queries/policy';
import { SYNCED_POLICY } from 'graphql/subscriptions/syncedPolicy';
import { useMemo } from 'react';
import { removeNulls } from 'utils/removeNulls';

const useSyncedPolicySubscription = () => {
  const { refetch } = useMe();
  const { data: raw } = useSubscription<OnPolicySyncedSubscription>(
    SYNCED_POLICY,
    {
      onData: async ({ client }) => {
        await client.query<PoliciesQuery>({
          fetchPolicy: 'network-only',
          query: POLICIES,
          variables: { isCurrent: true },
        });
      },
    },
  );

  if (raw) {
    refetch();
  }

  const syncedPolicyId = useMemo(() => {
    const data = removeNulls(raw);
    return data?.onPolicySynced?.policyId;
  }, [raw]);

  return { syncedPolicyId };
};

export default useSyncedPolicySubscription;
