import { IconContainer, Title, Container } from './styles';
import { Props } from './types';
import { FiEye as EyeIcon, FiEyeOff as EyeOffIcon } from 'react-icons/fi';

const PaymentDetailsVisibilityToggle = ({
  handleToggleDetailsVisible,
  bt,
  tokenId,
  isDetailsVisible,
}: Props) => {
  return (
    <Container>
      <Title>Payment Details</Title>
      {tokenId && (
        <IconContainer
          onClick={() => handleToggleDetailsVisible({ bt, tokenId })}
        >
          {isDetailsVisible ? (
            <EyeOffIcon size={'1rem'} />
          ) : (
            <EyeIcon size={'1rem'} />
          )}
        </IconContainer>
      )}
    </Container>
  );
};

export default PaymentDetailsVisibilityToggle;
