import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */

export const GET_UPLOAD_URL = gql`
  mutation GetUploadUrl($input: UploadInput!) {
    getUploadUrl(input: $input) {
      retrieveUrl
      uploadUrl
    }
  }
`;
