import styled, { css, keyframes } from 'styled-components';
import { FiX } from 'react-icons/fi';
import DefaultHTMLRenderer from 'components/HtmlRenderer';
import MaxWidth from 'components/MaxWidth';
import { MODAL_FADE_DURATION } from 'globals/constants';
import addAlpha from 'utils/addAlpha';

export const CloseIcon = styled(FiX)`
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.25rem;
  color: inherit;
  cursor: pointer;
`;

const fadeIn = keyframes`
  0% {
    transform: translateY(.5rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const BannerContainer = styled(MaxWidth)<{
  $isClosing: boolean;
  $isGuest: boolean;
}>`
  opacity: 0;
  position: absolute;
  flex-direction: row;
  z-index: 8;
  top: ${({ $isGuest }) => ($isGuest ? '2.25rem' : '4.25rem')};
  left: 0;
  right: 0;
  padding: 0 1.25rem;
  ${({ $isGuest }) => $isGuest && 'max-width: 66rem;'}
  ${({ $isClosing }) =>
    $isClosing
      ? css`
          animation: ${fadeOut} ${MODAL_FADE_DURATION}ms forwards 0.3s;
        `
      : css`
          animation: ${fadeIn} ${MODAL_FADE_DURATION}ms forwards 0.3s;
        `}
`;

export const BannerWrapper = styled.div`
  position: relative;
  display: flex;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.oasis};
  border: 0.0625rem solid ${({ theme }) => theme.colors.goldenRodDark};
  border-radius: 0.5rem;

  box-shadow: 0 1.125rem 2.25rem -0.75rem ${({ theme }) => addAlpha(theme.colors.starDust, 0.2)};
`;

export const HTMLRenderer = styled(DefaultHTMLRenderer)`
  display: flex;
  flex-basis: 100%;
  align-self: center;
`;
