import { memo } from 'react';

import useLogic from './logic';
import {
  Component,
  Label,
  Icon,
  IconContainer,
  Input,
  LeftIconContainer,
  BasicInput,
} from './styles';
import { Props } from './types';

const Checkbox = ({
  className,
  customComponent,
  disabled = false,
  id,
  label,
  name,
  role,
  value,
  withHighlight = false,
  variant,
  leftIcon,
  defaultChecked,
  ...props
}: Props) => {
  const { checked, onBlur, onChange } = useLogic({
    defaultChecked,
    name,
    role,
    value,
  });

  return (
    <Label
      $active={checked}
      className={className}
      $disabled={disabled}
      $withHighlight={withHighlight}
      $variant={variant}
      as="label"
      htmlFor={id || name}
      $hasCustomComponent={!!customComponent}
    >
      <Input
        data-testid={id || name}
        aria-hidden
        checked={checked}
        id={id || name}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        type={role}
        role={role}
        aria-checked={checked}
        value={value}
        {...props}
      />
      {leftIcon && !customComponent && (
        <LeftIconContainer>{leftIcon}</LeftIconContainer>
      )}
      <Component
        $active={checked}
        className={className}
        aria-checked={checked}
        aria-disabled={disabled}
        role={role}
        tabIndex={-1}
        aria-hidden="true"
        $variant={variant}
        $leftIcon={leftIcon}
        $hasCustomComponent={!!customComponent}
      >
        {!customComponent && !leftIcon && (
          <IconContainer>
            {role === 'checkbox' ? (
              <Icon $active={checked} $disabled={disabled} />
            ) : null}
          </IconContainer>
        )}
      </Component>
      {customComponent ? customComponent : label}
      {role === 'radio' && leftIcon && !customComponent && (
        <BasicInput checked={checked} readOnly type="radio" />
      )}
    </Label>
  );
};

export default memo(Checkbox);
