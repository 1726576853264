import { OverlineBold } from 'components/Typography';
import styled from 'styled-components';

export const IconContainer = styled.div`
  cursor: pointer;
`;

export const Container = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const Title = styled(OverlineBold)``;
