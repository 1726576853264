import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */

export const BENEFICIARY_FRAGMENT = gql`
  fragment BeneficiaryParts on BeneficiaryType {
    firstName
    lastName
    percentage
  }
`;
