export const normalizeReward = (
  data: Array<{
    __typename: 'RewardType';
    title: string;
    marbleValue: number;
    identifier: string;
  }>,
) => {
  const rewards: any = {};

  if (data.length > 0) {
    data.forEach((reward) => {
      rewards[reward.identifier] = {
        title: reward.title,
        value: reward.marbleValue,
      };
    });
  }

  return {
    ...rewards,
  };
};

export type Reward = ReturnType<typeof normalizeReward>;
