import { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export function Portal({ children }: { children: ReactNode }) {
  const [portalRoot, setPortalRoot] = useState<Element>();

  useEffect(() => {
    const portalRootEl = document.getElementById('app-portal');
    if (portalRootEl) {
      setPortalRoot(portalRootEl);
    }
  }, []);

  if (typeof window === 'undefined' || !portalRoot) return null;

  return createPortal(children, portalRoot);
}
