import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */

export const FEATURE_FRAGMENT = gql`
  fragment FeatureParts on FeatureItemType {
    id
    title
    description
    image
    imageBgcolor
    featureType
    date
    featured
    read
  }
`;
