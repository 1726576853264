import styled, { css } from 'styled-components';

import { spin } from './animations';

import { H1 } from 'components/Typography';

import { from } from 'styles/media';

export const InnerContainer = styled.div<{ $isFullPage: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ $isFullPage }) =>
    $isFullPage === true
      ? css`
          block-size: 5rem;
        `
      : css`
          block-size: inherit;
        `}
`;

export const Container = styled.div<{ $isFullPage: boolean }>`
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ $isFullPage }) =>
    $isFullPage === true
      ? css`
          position: absolute;
          block-size: 100vh;
        `
      : css`
          block-size: 100%;
        `}
`;

export const Component = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  width: 3.125rem;
  height: 3.125rem;
  margin: -1.5625rem 0 0 -1.5625rem;
  border: 0.125rem solid transparent;
  border-top-color: ${({ theme }) => theme.colors.goldenRod};
  border-radius: 50%;
  animation: ${spin} 2s linear infinite;

  @media screen and (prefers-reduced-motion: reduce) {
    &:before {
      position: absolute;
      inset: 0.4375rem;
      border: 0.125rem solid transparent;
      border-block-start-color: ${({ theme }) => theme.colors.orange};
      border-radius: 50%;
      animation: none;
      content: '';
    }
  }

  &:before {
    position: absolute;
    inset: 0.4375rem;
    border: 0.125rem solid transparent;
    border-block-start-color: ${({ theme }) => theme.colors.orange};
    border-radius: 50%;
    animation: ${spin} 3s linear infinite;
    content: '';
  }

  @media screen and (prefers-reduced-motion: reduce) {
    &:after {
      position: absolute;
      inset: 0.9375rem;
      border: 0.125rem solid transparent;
      border-block-start-color: ${({ theme }) => theme.colors.persimon};
      border-radius: 50%;
      animation: none;
      content: '';
    }
  }

  &:after {
    position: absolute;
    inset: 0.9375rem;
    border: 0.125rem solid transparent;
    border-block-start-color: ${({ theme }) => theme.colors.persimon};
    border-radius: 50%;
    animation: ${spin} 1.5s linear infinite;
    content: '';
  }
`;

export const Title = styled(H1)`
  margin-top: 5rem;
  padding: 2.5rem 1rem 0 1rem;
  text-align: center;
  ${from.mobile} {
    padding: 2.5rem 2rem 0 2rem;
  }
`;
