import { useBasisTheory } from '@basis-theory/basis-theory-react';
import useModals from 'graphql/hooks/useModals';
import { ModalEnumTypes } from 'graphql/variables/modal';
import { ConnectProps } from './types';
import { useMediaQuery } from '@material-ui/core';
import { until } from 'styles/media';

const useConnect = ({ submitCard }: ConnectProps) => {
  const { bt: btCard } = useBasisTheory(
    process.env.REACT_APP_BASIS_THEORY_CARD_API_KEY,
    {
      elements: true,
    },
  );

  const { openModal, closeModal } = useModals();

  const isMobile = useMediaQuery(until.mobile);

  const handleOpenModal = async () => {
    openModal(ModalEnumTypes.CONFIRM_PAYMENT_METHOD, {
      handleSubmit: async () => {
        if (btCard) await submitCard({ btCard });
        closeModal();
      },
    });
  };

  const expirationDateErrorNames = [
    'dataexpirationmonth',
    'dataexpirationyear',
    'expiration_month',
    'expiration_year',
  ];

  return {
    btCard,
    expirationDateErrorNames,
    handleOpenModal,
    isMobile,
  };
};

export default useConnect;
