/**
 * Creates a localStorage key based on the provided key and optional userId.
 * If userId is provided, it will be appended to the key. Otherwise, 'guest' will be used.
 * @param key - The base key for the localStorage item.
 * @param userId - Optional. The user ID to append to the key. (prevents clashes between different users on the same browser)
 * @returns The generated localStorage key.
 */
function createLocalStorageKey(key: string, userId?: string): string {
  const user = userId ? userId.slice(-4) : 'user';
  return `marble-${user}-${key}`;
}

/**
 * Sets an item in the local storage with the specified key and value.
 * Optionally, a userId can be provided to create a unique key for the user.
 *
 * @param key - The key of the item to be set in the local storage.
 * @param value - The value of the item to be set in the local storage.
 * @param userId - (Optional) The user ID to create a unique key for the user.
 */
export function setLocalStorageItem(
  key: string,
  value: string | boolean,
  userId?: string,
): void {
  localStorage.setItem(
    String(createLocalStorageKey(key, userId)),
    String(value),
  );
}

/**
 * Removes an item from the local storage.
 * @param key - The key of the item to be removed.
 * @param userId - Optional. The user ID associated with the item.
 */
export function removeLocalStorageItem(key: string, userId?: string): void {
  localStorage.removeItem(createLocalStorageKey(key, userId));
}

/**
 * Retrieves the value of a specific item from the local storage.
 *
 * @param key - The key of the item to retrieve.
 * @param userId - Optional. The user ID associated with the item.
 * @returns The value of the item as a string, or 'undefined' if the item does not exist.
 */
export function getLocalStorageItem(
  key: string,
  userId?: string,
): string | null {
  if (localStorage.getItem(createLocalStorageKey(key, userId)) !== null) {
    return String(localStorage.getItem(createLocalStorageKey(key, userId)));
  }

  return null;
}

/**
 * Retrieves a boolean value from the local storage based on the provided key and optional user ID.
 * @param key - The key used to store the boolean value in the local storage.
 * @param userId - Optional. The user ID used to create a unique key for the boolean value in the local storage.
 * @returns The boolean value retrieved from the local storage. Returns false if the value is not found or is undefined.
 */
export function getLocalStorageBool(key: string, userId?: string): boolean {
  if (
    String(localStorage.getItem(createLocalStorageKey(key, userId))) !==
    'undefined'
  ) {
    return (
      String(localStorage.getItem(createLocalStorageKey(key, userId))) ===
      'true'
    );
  }

  return false;
}
