import { useRef, useState } from 'react';
import { ConnectProps, PaymentFormName } from './types';
import { until, useMediaQuery } from 'styles/media';
import { PaymentDetailsType } from 'graphql/generated';
import { BasisTheoryReact } from '@basis-theory/basis-theory-react';
import usePaymentsActions from 'graphql/hooks/usePaymentsActions';
import { DISPLAY_GENERAL_ERROR, SUBMIT_GENERAL_ERROR } from './constants';

const useConnect = ({
  submittedPaymentMethod,
  offerId,
  isLoggedIn,
}: ConnectProps) => {
  const [visibleForm, setVisibleForm] = useState<
    null | PaymentDetailsType['paymentMethod']
  >(submittedPaymentMethod || null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [generalError, setGeneralError] = useState<string | null>(null);
  const [fieldErrors, setFieldErrors] = useState<any | null>(null);
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const [currentCardBrand, setCurrentCardBrand] = useState<
    string | undefined
  >();

  const isMobile = useMediaQuery(until.mobile);

  const handleSetVisibleForm = (formName: PaymentFormName) => {
    setVisibleForm(formName);
    setGeneralError(null);
  };

  // Card ref elements
  const cardNumberRef = useRef(null);
  const cardExpirationRef = useRef(null);
  const cardVerificationRef = useRef(null);

  // Bank ref elements
  const bankRoutingNumberRef = useRef(null);
  const bankAccountNumberRef = useRef(null);

  const { saveToken, authorizeDisplay } = usePaymentsActions();

  const handleResetErrors = () => {
    setFieldErrors(null);
    setGeneralError(null);
  };

  const handleSetErrors = (e: any, action: 'SUBMIT' | 'DISPLAY') => {
    const errors = e.data?.errors;

    if (errors) {
      setFieldErrors(
        Object.entries<{ key: string; value: string[] }[]>(errors).map(
          ([key, value]) => {
            return {
              fieldName: String(key).toLowerCase(),
              message: value[0] ? String(value[0]) : 'Error',
            };
          },
        ),
      );
    }

    if (action === 'SUBMIT') setGeneralError(SUBMIT_GENERAL_ERROR);
    if (action === 'DISPLAY') setGeneralError(DISPLAY_GENERAL_ERROR);
  };

  const handleSetCurrentCardBrand = (brand: string) => {
    setCurrentCardBrand(brand);
  };

  const submitCard = async ({
    btCard,
  }: {
    btCard: BasisTheoryReact<boolean>;
  }) => {
    setIsSubmitting(true);
    handleResetErrors();
    try {
      const token = await btCard?.tokens.create({
        data: {
          cvc: cardVerificationRef.current,
          // @ts-ignore
          expiration_month: cardExpirationRef.current.month(),
          // @ts-ignore
          expiration_year: cardExpirationRef.current.year(),
          number: cardNumberRef.current,
        },
        type: 'card',
      });
      if (token?.id) {
        await saveToken({
          offerId,
          paymentTokenId: token?.id,
        });
        setHasSubmitted(true);
      }
    } catch (e) {
      Error(e);
      handleSetErrors(e, 'SUBMIT');
    }
    setIsSubmitting(false);
  };

  const submitBankDetails = async ({
    btBank,
  }: {
    btBank: BasisTheoryReact<boolean>;
  }) => {
    setIsSubmitting(true);
    handleResetErrors();
    try {
      const token = await btBank?.tokens.create({
        data: {
          account_number: bankAccountNumberRef.current,
          routing_number: bankRoutingNumberRef.current,
        },
        type: 'bank',
      });
      if (token?.id) {
        await saveToken({
          offerId,
          paymentTokenId: token?.id,
        });
        setHasSubmitted(true);
      }
    } catch (e) {
      Error(e);
      handleSetErrors(e, 'SUBMIT');
    }
    setIsSubmitting(false);
  };

  const displayCard = async ({
    btCard,
    tokenId,
  }: {
    btCard: BasisTheoryReact<boolean>;
    tokenId?: string;
  }) => {
    handleResetErrors();
    setIsFetching(true);
    try {
      const session = await btCard?.sessions.create();
      if (!session?.nonce || !tokenId) {
        throw new Error('No nonce');
      }
      await authorizeDisplay({
        input: {
          nonce: session.nonce,
          paymentMethod: 'CARD',
          paymentTokenId: tokenId,
        },
      });
      const token = await btCard?.tokens.retrieve(tokenId, {
        apiKey: session?.sessionKey,
      });
      // @ts-ignore
      cardNumberRef?.current.setValue(token.data.number);
      // @ts-ignore
      cardExpirationRef?.current.setValue({
        month: token?.data.expiration_month,
        year: token?.data.expiration_year,
      });
      // @ts-ignore
      cardVerificationRef?.current.setValue(token.data.cvc);
    } catch (e) {
      Error(e);
      handleSetErrors(e, 'DISPLAY');
    }
    setIsFetching(false);
  };

  const displayBankDetails = async ({
    btBank,
    tokenId,
  }: {
    btBank: BasisTheoryReact<boolean>;
    tokenId?: string;
  }) => {
    handleResetErrors();
    setIsFetching(true);
    try {
      const session = await btBank?.sessions.create();
      if (!session?.nonce || !tokenId) {
        throw new Error('No nonce');
      }
      await authorizeDisplay({
        input: {
          nonce: session.nonce,
          paymentMethod: 'BANK',
          paymentTokenId: tokenId,
        },
      });
      const token = await btBank?.tokens.retrieve(tokenId, {
        apiKey: session?.sessionKey,
      });
      // @ts-ignore
      bankAccountNumberRef?.current.setValue(token.data.account_number);
      // @ts-ignore
      bankRoutingNumberRef?.current.setValue(token.data.routing_number);
    } catch (e) {
      Error(e);
      handleSetErrors(e, 'DISPLAY');
    }
    setIsFetching(false);
  };

  const handleToggleDetailsVisible = ({
    bt,
    tokenId,
  }: {
    bt: BasisTheoryReact<boolean>;
    tokenId?: string;
  }) => {
    if (isLoggedIn && tokenId) {
      if (!isDetailsVisible) {
        if (visibleForm === 'BANK') displayBankDetails({ btBank: bt, tokenId });
        if (visibleForm === 'CARD') displayCard({ btCard: bt, tokenId });
      }
      setIsDetailsVisible(!isDetailsVisible);
    }
  };

  return {
    bankAccountNumberRef,
    bankRoutingNumberRef,
    cardExpirationRef,
    cardNumberRef,
    cardVerificationRef,
    currentCardBrand,
    fieldErrors,
    generalError,
    handleSetCurrentCardBrand,
    handleSetVisibleForm,
    handleToggleDetailsVisible,
    hasSubmitted,
    isDetailsVisible,
    isFetching,
    isMobile,
    isSubmitting,
    submitBankDetails,
    submitCard,
    visibleForm,
  };
};

export default useConnect;
