import {
  Container,
  CustomIconContainer,
  Disclaimer,
  HelpIcon,
  IconContainer,
  ShieldIcon,
} from './styles';
import { Props } from './types';

const PaymentFootnote = ({ submitted }: Props) => {
  return (
    <Container>
      <IconContainer>
        {submitted ? (
          <HelpIcon />
        ) : (
          <CustomIconContainer>
            <ShieldIcon />
          </CustomIconContainer>
        )}
      </IconContainer>

      <Disclaimer>
        {submitted
          ? 'Need to make changes to your billing information? Reach out to Marble support for any modifications.'
          : 'Your billing information will be encrypted with bank-level security with our payment processing partner, BasisTheory. Payment information will not be stored on our servers.'}
      </Disclaimer>
    </Container>
  );
};

export default PaymentFootnote;
