import ClickAwayListener from 'react-click-away-listener';
import { FAQ, MARBLE_BLOG_URL } from 'globals/constants';

import DefaultDropdown, { DropdownTrigger } from 'components/Dropdown';

import useConnect from './connect';
import {
  Button,
  Chevron,
  ClickOutIcon,
  DropdownContent,
  FaqIcon,
  ExternalLink,
  MenuTitle,
  ReferralIcon,
  SettingsIcon,
  SignOutIcon,
  Text,
  Title,
  ViewAs,
  WhatsNewIcon,
  LockIcon,
} from './styles';
import { Props } from './types';
import { track } from 'services/segment';

const Dropdown = ({
  isTools = false,
  isUserSettings = false,
  isRewards = false,
  showWhatsNew = false,
  paths,
  title,
}: Props) => {
  const {
    currentPathName,
    handleClick,
    handleRewardsClick,
    handleClickAway,
    handleHide,
    handleLogout,
    hidden,
    isLocked,
    viewAs,
    referralValue,
  } = useConnect();

  const Tools = () => (
    <>
      <Button onClick={() => handleClick('insuranceinsider')}>
        <Text>Insurance Insider</Text>
      </Button>
      <ExternalLink
        href={MARBLE_BLOG_URL}
        rel="noopener noreferrer"
        target="_blank"
        onClick={() => track('Navbar - Blog Link Clicked')}
      >
        Blog <ClickOutIcon />
      </ExternalLink>
    </>
  );

  const Rewards = () => (
    <>
      <Button onClick={() => handleClick('perks')}>
        <Text>Perks & Discounts</Text>
      </Button>
      <Button onClick={handleRewardsClick}>
        <Text>Redeem {isLocked && <LockIcon />}</Text>
      </Button>
    </>
  );

  const UserSettings = () => (
    <>
      <Title>Settings</Title>
      <Button onClick={() => handleClick('settings')}>
        <SettingsIcon />
        <Text>Account settings</Text>
      </Button>
      <ExternalLink href={FAQ} rel="noopener noreferrer" target="_blank">
        <FaqIcon />
        FAQ
      </ExternalLink>
      <Button onClick={() => handleClick('refer')}>
        <ReferralIcon />
        <Text>Invite Friends, get {referralValue} Marbles</Text>
      </Button>
      {showWhatsNew ? (
        <Button onClick={() => handleClick('whatsnew')}>
          <WhatsNewIcon />
          <Text>What&apos;s new</Text>
        </Button>
      ) : null}
      <Button onClick={handleLogout} $withColor>
        <SignOutIcon />
        <Text>Sign out</Text>
      </Button>
    </>
  );

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <DefaultDropdown>
        <DropdownTrigger onClick={handleHide}>
          <MenuTitle $active={!!paths && paths?.includes(currentPathName)}>
            {title} {(isTools || isRewards) && <Chevron $isOpen={!hidden} />}
          </MenuTitle>
        </DropdownTrigger>
        <DropdownContent hidden={hidden}>
          {viewAs ? <ViewAs>Viewing as {viewAs}</ViewAs> : null}
          {isTools && <Tools />}
          {isRewards && <Rewards />}
          {isUserSettings && <UserSettings />}
        </DropdownContent>
      </DefaultDropdown>
    </ClickAwayListener>
  );
};

export default Dropdown;
