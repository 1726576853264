import { FC, memo } from 'react';

import { BetaTag, Container, Isotype, IsotypeMono, Logotype } from './styles';
import { Props } from './types';

const Logo: FC<Props> = ({
  className,
  colorVariant,
  size = 'medium',
  variant = 'isologotype',
  withColor,
  isBeta,
}) => (
  <Container className={className}>
    {variant.includes('iso') &&
      (withColor ? (
        <Isotype $size={size} $variant={variant} />
      ) : (
        <IsotypeMono
          $size={size}
          $variant={variant}
          $colorVariant={colorVariant}
        />
      ))}
    {variant !== 'isotype' && (
      <Logotype $size={size} $colorVariant={colorVariant} />
    )}
    {isBeta && <BetaTag $absolutePosition={variant === 'isotype'} />}
  </Container>
);

export default memo(Logo);
