import { gql } from '@apollo/client';

import { COVERAGE_ITEMS_INFO_EXAMPLE_FRAGMENT } from './coverageItemInfoExample';

/* eslint-disable import/prefer-default-export */

export const COVERAGE_ITEMS_INFO_FRAGMENT = gql`
  fragment CoverageItemInfoParts on CoverageItemInfoType {
    id
    coverageItem
    definition
    examples {
      ...CoverageItemInfoExampleParts
    }
  }
  ${COVERAGE_ITEMS_INFO_EXAMPLE_FRAGMENT}
`;
