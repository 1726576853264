import { Form, Formik } from 'formik';
import {
  ButtonContainer,
  Container,
  FormContent,
  FormFields,
  LoaderContainer,
} from './styles';
import { Props } from './types';
import BasisTheoryInputContainer from 'components/BasisTheoryInputContainer';
import {
  BasisTheoryProvider,
  TextElement,
} from '@basis-theory/basis-theory-react';
import PaymentFootnote from 'components/PaymentDetailsFootnote';
import Button from 'components/Button';
import PaymentDetailsToggle from 'components/PaymentDetailsVisibilityToggle';
import FullscreenLoader from 'components/FullscreenLoader';
import useConnect from './connect';

const CollectBankDetails = ({
  details,
  isPaymentSubmitted,
  submitBankDetails,
  isDetailsVisible,
  isSubmitting,
  isFetching,
  bankAccountNumberRef,
  bankRoutingNumberRef,
  handleToggleDetailsVisible,
  fieldErrors,
}: Props) => {
  const fieldsReadOnly = isPaymentSubmitted;
  const fieldsDisabled = isDetailsVisible && isPaymentSubmitted;

  const { btBank, handleOpenModal, isMobile } = useConnect({
    submitBankDetails,
  });

  if (!btBank) return <FullscreenLoader isFullPage={false} />;

  // Mask all but last 4 digits of routing number
  const routingPlaceholder = details?.routingNumber
    ? details?.routingNumber.replace(/[\w ](?=[\w ]{4})/g, 'X')
    : 'Routing Number';

  return (
    <BasisTheoryProvider bt={btBank}>
      <Container>
        <PaymentDetailsToggle
          handleToggleDetailsVisible={handleToggleDetailsVisible}
          bt={btBank}
          tokenId={details?.paymentToken}
          isDetailsVisible={isDetailsVisible}
        />
        <Formik onSubmit={handleOpenModal} initialValues={{}}>
          <Form>
            <FormContent>
              {isFetching && (
                <LoaderContainer>
                  <FullscreenLoader isFullPage={false} />
                </LoaderContainer>
              )}
              <FormFields>
                <BasisTheoryInputContainer
                  name="Account Number"
                  readOnly={fieldsReadOnly}
                  disabled={fieldsDisabled}
                  error={
                    fieldErrors?.find(
                      (error) => error.fieldName === 'dataaccountnumber',
                    )?.message
                  }
                >
                  <TextElement
                    readOnly={fieldsReadOnly}
                    disabled={fieldsDisabled}
                    id="accountNumber"
                    placeholder={details?.accountNumber || 'Account Number'}
                    ref={bankAccountNumberRef}
                    value={isDetailsVisible ? undefined : ''}
                  />
                </BasisTheoryInputContainer>
                <BasisTheoryInputContainer
                  name="Routing Number"
                  readOnly={fieldsReadOnly}
                  disabled={fieldsDisabled}
                  error={
                    fieldErrors?.find(
                      (error) => error.fieldName === 'dataroutingnumber',
                    )?.message
                  }
                >
                  <TextElement
                    id="routingNumber"
                    placeholder={
                      !isDetailsVisible ? routingPlaceholder : 'Routing Number'
                    }
                    ref={bankRoutingNumberRef}
                    readOnly={fieldsReadOnly}
                    disabled={fieldsDisabled}
                    value={isDetailsVisible ? undefined : ''}
                  />
                </BasisTheoryInputContainer>
              </FormFields>
              <PaymentFootnote submitted={isPaymentSubmitted} />
              {!isPaymentSubmitted && (
                <ButtonContainer>
                  <Button
                    fullWidth={isMobile}
                    id="submitBankDetails"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Confirm payment details
                  </Button>
                </ButtonContainer>
              )}
            </FormContent>
          </Form>
        </Formik>
      </Container>
    </BasisTheoryProvider>
  );
};

export default CollectBankDetails;
