import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */

export const ACTIVITY_FRAGMENT = gql`
  fragment ActivityParts on ActivityType {
    id
    activityType
    concept
    status
    marbles
    money
    date
    message
    isDonation
  }
`;
