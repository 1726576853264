import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

/* eslint-disable import/prefer-default-export */

export class MarbleError extends Error {
  public fields?: Record<string, string[]> | Partial<GraphQLError>;

  // eslint-disable-next-line @typescript-eslint/default-param-last
  constructor(message = 'Something went wrong!', error: ApolloError) {
    super(message);

    if (error?.networkError) {
      return;
    }

    this.fields = error?.graphQLErrors?.[0]?.extensions;
  }
}
