import { Children, cloneElement, isValidElement } from 'react';
import { fadeIn, slideDown, slideLeft, slideTop } from 'styles/animations';

import { Container } from './styles';
import { AnimateProps, AnimateSequenceProps } from './types';

export const Animate = ({
  children,
  className,
  delay = 0,
  id,
  duration = 300,
  position = 'auto',
  type = 'fadeIn',
}: AnimateProps) => {
  const animation = { fadeIn, slideDown, slideLeft, slideTop }[type];
  return (
    <Container
      $animation={animation}
      $delay={delay}
      $duration={duration}
      $position={position}
      className={className}
      id={id}
    >
      {children}
    </Container>
  );
};

export const AnimateSequence = ({ children }: AnimateSequenceProps) => {
  return (
    <>
      {Children.map(children, (child, index) => {
        if (isValidElement(child)) {
          if (child?.type === Animate) {
            const delay = (index + 1) * 100;
            const position = Children.count(children) - index;
            return cloneElement(child as React.ReactElement, {
              delay,
              position,
            });
          }
          return child;
        }
        return null;
      })}
    </>
  );
};
