import MaxWidth from 'components/MaxWidth';
import { BodyMedium, H4 } from 'components/Typography';
import styled from 'styled-components';
import { until } from 'styles/media';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  padding-block-start: 2rem;
  margin-bottom: 4rem;
  ${until.tablet} {
    gap: 1rem;
  }
`;

export const LoadingContainer = styled.div`
  height: 100%;
  margin: auto;
`;

export const Content = styled(MaxWidth)`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding-block-start: 1.5rem;
  padding-block-end: 1.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  ${until.tablet} {
    padding-block-start: 1rem;
    background: none;
  }
  border-radius: 0.375rem;
`;

export const DesktopTitle = styled(H4)``;

export const MobileTitle = styled(BodyMedium)``;
