import { gql } from '@apollo/client';

export const DESIRED_COVERAGES = gql`
  fragment DesiredCoverageParts on DesiredCoveragesType {
    comprehensiveDeductible
    collisionDeductible
    towing
    rental
    bodilyInjury
    propertyDamage
    uninsuredMotorist
    uninsuredMotoristPropertyDamage
    underinsuredMotorist
  }
`;

export const VEHICLE_ASSET_FRAGMENT = gql`
  fragment VehicleTypeParts on VehicleType {
    id
    ownership
    primaryUse
    primaryDriver {
      id
    }
    vehicleInfo
    vehicleVin
    hasExistingDamage
    deliveryUse
    rideshareUse
    monitoredDrivingDiscount
    drivers {
      id
    }
    desiredCoverages {
      ...DesiredCoverageParts
    }
  }
  ${DESIRED_COVERAGES}
`;

export const VEHICLE_ASSET_CART_FRAGMENT = gql`
  fragment VehicleCartTypeParts on VehicleType {
    id
    make
    model
    ownership
    vehicleInfo
    vehicleVin
    year
  }
`;

export const PROPERTY_ASSET_FRAGMENT = gql`
  fragment PropertyTypeParts on PropertyType {
    id
    addressLine1
    addressLine2
    propertyCity
    propertyZipCode
    propertyState {
      id
      abbreviation
      name
    }
    numBeds
    squareFootage
    yearBuilt
    numBathsFull
    numBathsPartial
    numStories
    roofYearBuilt
    heatingAndCoolingUpdated
    electricalUpdated
    plumbingUpdated
    securitySystem
    securitySystemType
    fireAlarm
    fireAlarmType
    isGatedCommunity
    hasTrampoline
    trampolineSafetyNet
    hasPool
    poolType
    poolHasSlide
    poolHasDivingBoard
    poolHasFence
    poolHasCage
    hasDogs
    deductibleHome
    deductibleWind
    previousCarrier
    previousCarrierYears
    previousPolicyExpiry
    previousPremium
    purchaseDate
    policyShopReason
  }
`;

export const PROPERTY_ASSET_CART_FRAGMENT = gql`
  fragment PropertyCartTypeParts on PropertyType {
    addressLine1
    addressLine2
    propertyCity
    propertyZipCode
    canopyId
  }
`;

export const RENTAL_ASSET_CART_FRAGMENT = gql`
  fragment RentalTypeParts on RentalType {
    addressLine
    city
  }
`;

export const PET_ASSET_CART_FRAGMENT = gql`
  fragment PetTypeParts on PetType {
    name
    breed
  }
`;
