import { memo } from 'react';

import { Component, Fallback, FallbackContainer } from './styles';
import { Props } from './types';

const CarrierThumbnail = ({
  className,
  margin = false,
  name = 'Unknown carrier',
  size,
  url,
  variant,
}: Props) => {
  if (!url) {
    return (
      <FallbackContainer className={className} $margin={margin}>
        <Fallback>{name}</Fallback>
      </FallbackContainer>
    );
  }

  return (
    <Component
      alt={`${name} logotype`}
      className={className}
      loading="lazy"
      $margin={margin}
      size={size}
      src={url}
      variant={variant}
    />
  );
};

export default memo(CarrierThumbnail);
