import { ReactComponent as DefaultIcon } from 'assets/images/icons/checked.svg';
import styled, { css } from 'styled-components';
import addAlpha from 'utils/addAlpha';

import { Body } from 'components/Typography';

import { ComponentProps, ContainerProps, IconProps } from './types';

export const Icon = styled(DefaultIcon)<IconProps>`
  ${({ $active }) =>
    $active
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`;

export const Component = styled.div<ComponentProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.0625rem solid ${({ theme }) => theme.colors.bluewood};

  &[role='checkbox'] {
    border-radius: 0.25rem;

    ${({
      $variant,
      $active,
      $hasCustomComponent,
      $leftIcon,
      theme,
      className,
    }) => {
      if ($leftIcon || $hasCustomComponent) {
        return css`
          display: none;
        `;
      }
      switch ($variant) {
        case 'inverse':
          return css`
            background-color: ${addAlpha(
              theme.colors.white,
              $active ? 1 : 0.12,
            )};
            border: 0.0625rem solid ${theme.colors.white};

            ${$active || (className && className.includes('white-disabled'))
              ? null
              : css`
                  &[aria-disabled='true'] {
                    border: 0.0625rem solid ${addAlpha(theme.colors.white, 0.4)};
                  }
                `}
          `;
        default:
          return css`
            background-color: ${theme.colors[$active ? 'bluewood' : 'white']};

            ${$active || (className && className.includes('white-disabled'))
              ? null
              : css`
                  &[aria-disabled='true'] {
                    background-color: ${theme.colors.desertStorm};
                  }
                `}
          `;
      }
    }}
  }

  &[role='radio'] {
    border-radius: 50%;

    ${({ $variant, $active, $leftIcon, theme, className }) => {
      if ($leftIcon) {
        return css`
          display: none;
        `;
      }
      switch ($variant) {
        case 'inverse':
          return css`
            background-color: ${theme.colors.bluewood};
            border: 0.0625rem solid ${theme.colors.white};
            ${$active
              ? css`
                  box-shadow: inset 0 0 0 0.3125rem ${theme.colors.white};
                `
              : null}

            ${$active || (className && className.includes('white-disabled'))
              ? null
              : css`
                  &[aria-disabled='true'] {
                    border: 0.0625rem solid ${addAlpha(theme.colors.white, 0.4)};
                  }
                `}
          `;
        default:
          return css`
            background-color: ${theme.colors.white};

            ${$active
              ? css`
                  box-shadow: inset 0 0 0 0.3125rem ${theme.colors.bluewood};
                `
              : null}

            ${$active || (className && className.includes('white-disabled'))
              ? null
              : css`
                  &[aria-disabled='true'] {
                    background-color: ${theme.colors.desertStorm};
                  }
                `}
          `;
      }
    }}
  }
`;

export const BasicInput = styled.input`
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  pointer-events: none;
  accent-color: ${({ theme }) => theme.colors.bluewood};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  width: 1rem;
  height: 1rem;
  padding: 0.125rem;
`;

export const LeftIconContainer = styled.div`
  display: flex;
  align-items: center;
  width: 2rem;
  height: 2rem;
  padding: 0.2rem;
`;

export const Label = styled(Body)<ContainerProps>`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  cursor: pointer;

  ${({ $hasCustomComponent }) => {
    return css`
      align-items: ${$hasCustomComponent ? 'start' : 'center'};
    `;
  }}

  ${({ $variant, $disabled, theme }) => {
    switch ($variant) {
      case 'inverse':
        return css`
          color: ${theme.colors.white};
          ${Icon} {
            color: ${theme.colors[$disabled ? 'bluewoodMedium' : 'bluewood']};
          }
        `;
      default:
        return css`
          color: ${theme.colors.bluewood};
          ${Icon} {
            color: ${theme.colors[$disabled ? 'desertStorm' : 'white']};
          }
        `;
    }
  }}

  ${({ $disabled }) =>
    $disabled
      ? css`
          cursor: not-allowed;
          pointer-events: none;
        `
      : css`
          &:hover,
          &:focus {
            opacity: 0.7;
          }
          &:focus-within {
            ${Component} {
              outline: 0.125rem solid ${({ theme }) => theme.colors.eastBay};
              outline-offset: 0.125rem;
            }
          }
        `}

  ${({ $active, theme, $withHighlight }) =>
    $withHighlight
      ? css`
          padding: 0.75rem 0.875rem;
          background-color: ${theme.colors[$active ? 'pampas' : 'white']};
          border: 0.0625rem solid
            ${theme.colors[$active ? 'bluewood' : 'quillGray']};
          border-radius: 0.375rem;
        `
      : null}
`;

export const Input = styled.input`
  position: absolute;
  width: 0.0625rem;
  height: 0.0625rem;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
`;
