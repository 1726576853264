import { gql } from '@apollo/client';
import { ON_POLICY_SYNCED_FRAGMENT } from 'graphql/fragments/onPolicySynced';

// eslint-disable-next-line import/prefer-default-export
export const SYNCED_POLICY = gql`
  subscription OnPolicySynced {
    onPolicySynced {
      ...OnPolicySyncedParts
    }
  }
  ${ON_POLICY_SYNCED_FRAGMENT}
`;
