import { FC } from 'react';

import { Component } from './styles';
import { Props } from './types';

const ButtonLink: FC<Props> = ({
  variant,
  children,
  icon,
  fullWidth,
  iconPosition = 'left',
  className,
  target,
  size,
  ...props
}) => (
  <Component
    $size={size}
    $variant={variant}
    $fullWidth={fullWidth}
    $hasIcon={!!icon}
    $iconPosition={iconPosition}
    className={className}
    target={target}
    rel={target === '_blank' ? 'noreferrer noopener' : undefined}
    {...props}
  >
    {icon}
    {children && <span>{children}</span>}
  </Component>
);

export default ButtonLink;
