import { gql } from '@apollo/client';
import { POLICY_FRAGMENT } from 'graphql/fragments';

export const ARCHIVE_POLICY = gql`
  mutation ArchivePolicy($input: ArchivePolicyInput!) {
    archivePolicy(input: $input)
  }
`;

export const CREATE_POLICY_USER = gql`
  mutation CreatePolicyUser($input: UserCarrierInput!) {
    createUserCarrier(input: $input) {
      ...PolicyParts
    }
  }
  ${POLICY_FRAGMENT}
`;

export const UPLOAD_POLICY_DOCUMENT = gql`
  mutation UploadPolicyDocument($input: UploadPolicyDocumentsInput!) {
    uploadPolicyDocument(input: $input) {
      ...PolicyParts
    }
  }
  ${POLICY_FRAGMENT}
`;

export const MARK_POLICY_SYNC_ANIMATION_SHOWN = gql`
  mutation MarkPolicySyncAnimationShown($id: UUID!) {
    markPolicySyncAnimationShown(id: $id) {
      ...PolicyParts
    }
  }
  ${POLICY_FRAGMENT}
`;

export const ACCEPT_SHARED_POLICY_ACCESS = gql`
  mutation AcceptSharedAccess($sharedAccessId: UUID!) {
    acceptSharedAccess(sharedAccessId: $sharedAccessId) {
      ok
    }
  }
`;
