import {
  createSearchParams,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { getCode } from 'containers/Guest/Authentication/helpers';
import { useCallback, useMemo } from 'react';

const useUrlParams = () => {
  const location = useLocation();
  const [params, setParams] = useSearchParams();

  const defaultEmail = params.get('email') || undefined;
  const defaultFirstName = params.get('fname') || undefined;
  const defaultLastName = params.get('lname') || undefined;
  const defaultPolicyCategory = params.get('policy_category') || undefined;

  const referralCode =
    params.get('referralCode') || getCode(location.pathname) || undefined;
  const redirectUrl = params.get('redirectUrl') || undefined;
  const utmSource = params.get('utm_source') || undefined;
  const utmMedium = params.get('utm_medium') || undefined;
  const utmCampaign = params.get('utm_campaign') || undefined;
  const utmTerm =
    params.get('utm_term') || params.get('usi_click_id') || undefined;
  const utmContent =
    params.get('utm_content') || params.get('usi_email_id') || undefined;

  const token = params.get('vtk') || undefined;
  const cioLinkId = params.get('link_id') || undefined;

  const shared = params.get('shared') || undefined;

  const display = params.get('display') || undefined;

  const removeUrlParam = useCallback(
    (param: string) => {
      params.delete(param);
      setParams(params);
    },
    [params, setParams],
  );

  const utmParams = useMemo(
    () => ({
      ...(utmSource && { utm_source: utmSource }),
      ...(utmMedium && { utm_medium: utmMedium }),
      ...(utmCampaign && { utm_campaign: utmCampaign }),
      ...(utmTerm && { utm_term: utmTerm }),
      ...(utmContent && { utm_content: utmContent }),
    }),
    [utmCampaign, utmContent, utmMedium, utmSource, utmTerm],
  );

  const redirectUrlParams = useMemo(
    () => ({
      ...(redirectUrl && { redirectUrl }),
    }),
    [redirectUrl],
  );

  const utmParamsString = `?${createSearchParams(utmParams)}`;

  const redirectUrlParamsString = `?${createSearchParams(redirectUrlParams)}`;

  return {
    cioLinkId,
    defaultEmail,
    defaultFirstName,
    defaultLastName,
    defaultPolicyCategory,
    display,
    redirectUrl,
    redirectUrlParamsString,
    referralCode,
    removeUrlParam,
    shared,
    token,
    utmCampaign,
    utmContent,
    utmMedium,
    utmParamsString,
    utmSource,
    utmTerm,
  };
};

export default useUrlParams;

export type UrlParams = ReturnType<typeof useUrlParams>;
export type UtmParams = Pick<
  UrlParams,
  'utmCampaign' | 'utmContent' | 'utmMedium' | 'utmSource' | 'utmTerm'
>;
