import { Link as DefaultLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import DefaultAvatar from 'components/Avatar';
import { Body, BodyMedium } from 'components/Typography';

export const Container = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex.foreground};
  width: 100%;
  height: 100%;
  padding: 3.75rem 1.5rem;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  background-color: ${({ theme }) => theme.colors.bluewood};

  & svg {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const ButtonCommonStyles = css`
  display: flex;
  align-items: center;
  padding: 0;
  font-size: inherit;
  background: none;
  border: 0;
  cursor: pointer;
  appearance: none;
`;

export const Close = styled.button`
  ${ButtonCommonStyles}
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: 1.5rem;
  top: 1.725rem;

  > svg {
    width: 100%;
    height: 100%;
  }
`;

export const Information = styled.div`
  display: flex;
  align-items: center;
`;

export const MoreInformation = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Name = styled(BodyMedium)``;

export const ViewAs = styled(Body)`
  margin-top: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Avatar = styled(DefaultAvatar)`
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
`;

export const List = styled.ul`
  margin-top: 3.375rem;
  padding: 0;
`;

export const ListItem = styled.li`
  & + & {
    margin-block-start: 3.375rem;
  }
`;

export const ListItemIcon = styled.svg`
  margin-right: 1rem;
`;

export const Link = styled(DefaultLink)`
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
`;

export const ExternalLink = styled.a`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;

  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

export const Logout = styled.button`
  ${ButtonCommonStyles}
  width: 100%;
  color: ${({ theme }) => theme.colors.persimon};

  & svg {
    color: inherit;
  }
`;

export const ModalButton = styled.button`
  ${ButtonCommonStyles}
  width: 100%;
  color: ${({ theme }) => theme.colors.white};

  & svg {
    color: inherit;
  }
`;
