import { createGlobalStyle } from 'styled-components';
import { reset } from 'styled-reset';

import './fonts.css';

const GlobalStyle = createGlobalStyle`
  ${reset};

  html {
    font-size: 100%; 
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }

  body {
    background-color: ${({ theme }) => theme.colors.newPampas};
    color: ${({ theme }) => theme.colors.bluewood};
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-feature-settings: "liga","kern";
    font-variant-numeric: proportional-nums;
  }

  a, button, input {
    font-family: inherit;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  //override min-height: 100vh for Storybook
  [class^="sb-"] #root {
    min-height: auto;
  }

  * {
    box-sizing: border-box;
  }

  :root {
    --reach-menu-button: 1;
  }

  /* clears the 'X' from Chrome. Used with address autocomplete and type='search' to remove browser input */
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration { display: none; }

  /* clears the 'X' from Internet Explorer */
  input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
  input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }


  // reduce z-index address autocomplete dropdown so it won't appear over the mobile open menu
  .MuiAutocomplete-popper {
    z-index: 9 !important;
  }
`;

export default GlobalStyle;
