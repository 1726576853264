import { useApolloClient, useMutation } from '@apollo/client';
import previousPath from 'containers/Root/cache';
import {
  LoginInput,
  LoginMutation,
  LoginMutationVariables,
  MeQuery,
  MutationSocialLoginArgs,
  RegisterInput,
  RegisterMutation,
  RegisterMutationVariables,
  RequestResetPasswordMutation,
  RequestResetPasswordMutationVariables,
  ResetPasswordInput,
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
  SetPasswordInput,
  SetPasswordShopFlowMutation,
  SetPasswordShopFlowMutationVariables,
  SocialLoginInput,
  SocialLoginMutation,
  ValidateResetTokenMutation,
  ValidateResetTokenMutationVariables,
} from 'graphql/generated';
import { MarbleError } from 'graphql/helpers';
import {
  LOGIN,
  REGISTER,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD,
  SOCIAL_LOGIN,
  VALIDATE_RESET_TOKEN,
  SET_PASSWORD_SHOP_FLOW,
} from 'graphql/mutations/auth';
import { ME } from 'graphql/queries/me';
import { useCallback } from 'react';
import { identify, reset, track } from 'services/segment';
import { removeToken, setToken } from 'services/token';
import { useNavigate } from 'react-router-dom';

import useMe from '../useMe';
import { HOME_PATH } from 'globals/constants';

const useAuth = () => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const { me } = useMe();
  const [loginMutation, { loading: loginLoading }] = useMutation<
    LoginMutation,
    LoginMutationVariables
  >(LOGIN, { fetchPolicy: 'no-cache' });
  const [socialLoginMutation, { loading: socialLoginLoading }] = useMutation<
    SocialLoginMutation,
    MutationSocialLoginArgs
  >(SOCIAL_LOGIN, { fetchPolicy: 'no-cache' });
  const [registerMutation, { loading: registerLoading }] = useMutation<
    RegisterMutation,
    RegisterMutationVariables
  >(REGISTER, { fetchPolicy: 'no-cache' });
  const [requestMutation, { loading: requestLoading }] = useMutation<
    RequestResetPasswordMutation,
    RequestResetPasswordMutationVariables
  >(REQUEST_RESET_PASSWORD);
  const [resetMutation, { loading: resetLoading }] = useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(RESET_PASSWORD);
  const [validateResetTokenMutation, { loading: validateResetTokenLoading }] =
    useMutation<
      ValidateResetTokenMutation,
      ValidateResetTokenMutationVariables
    >(VALIDATE_RESET_TOKEN);
  const [setPasswordShopFlowMutation, { loading: setPasswordLoading }] =
    useMutation<
      SetPasswordShopFlowMutation,
      SetPasswordShopFlowMutationVariables
    >(SET_PASSWORD_SHOP_FLOW);

  const redirect = (url: string) => {
    window.location.replace(url);
  };

  const handleLoginResponse = useCallback(
    async (response, redirectUrl?) => {
      const data = response?.data?.login;
      if (data && data.token) {
        setToken(data.token);
        identify(data.id, {
          email: data.email,
          first_name: data.firstName,
          last_name: data.lastName,
          name: `${data.firstName} ${data.lastName}`,
        });
        client.cache.writeQuery<MeQuery>({
          data: { me: data },
          query: ME,
        });
        if (previousPath() !== '') {
          window.location.replace(previousPath());
          previousPath('');
        }

        if (redirectUrl) {
          redirect(redirectUrl);
        } else {
          navigate(HOME_PATH);
        }
      }
    },
    [client.cache, navigate],
  );

  const login = useCallback(
    async (input: LoginInput) => {
      try {
        const response = await loginMutation({ variables: { input } });
        await handleLoginResponse(response);
      } catch (error) {
        if (error.message === 'Error decoding signature') {
          removeToken();
          reset();
          sessionStorage.clear();
          const retryResponse = await loginMutation({ variables: { input } });
          await handleLoginResponse(retryResponse);
        } else if (
          error.message.includes('email') ||
          error.message.includes('robot') ||
          error.message.includes('account has been deactivated or deleted')
        ) {
          throw new MarbleError(error.message, error);
        } else {
          throw new MarbleError(
            "Hmm looks like we're running into a snag on our end. Our team is looking into it right away. Please try again shortly.",
            error,
          );
        }
      }
    },
    [loginMutation, handleLoginResponse],
  );

  const socialLogin = useCallback(
    async (input: SocialLoginInput, existingUserRedirectUrl?: string) => {
      try {
        const response = await socialLoginMutation({ variables: { input } });
        const data = response?.data;
        const redirectUrl = data?.socialLogin?.isNewUser
          ? undefined
          : existingUserRedirectUrl;

        if (data && data.socialLogin && data.socialLogin.token) {
          setToken(data.socialLogin.token);
          identify(data.socialLogin.id, {
            email: data.socialLogin.email,
            first_name: data.socialLogin.firstName,
            last_name: data.socialLogin.lastName,
            name: `${data.socialLogin.firstName} ${data.socialLogin.lastName}`,
          });

          client.cache.writeQuery<MeQuery>({
            data: { me: data.socialLogin },
            query: ME,
          });
          if (previousPath() !== '') {
            window.location.replace(previousPath());
            previousPath('');
          }

          if (redirectUrl) {
            redirect(redirectUrl);
          } else {
            navigate(HOME_PATH);
          }
        }
      } catch (error) {
        if (
          error.message.includes('email') ||
          error.message.includes('account has been deactivated or deleted')
        ) {
          throw new MarbleError(error.message, error);
        } else {
          throw new MarbleError(
            "Hmm looks like we're running into a snag on our end. Our team is looking into it right away. Please try again shortly.",
            error,
          );
        }
      }
    },
    [client.cache, navigate, socialLoginMutation],
  );

  const logout = useCallback(() => {
    if (me && !me.isViewedAs) track('User - Logout');
    removeToken();
    reset();
    sessionStorage.clear();
    previousPath('');
    window.location.replace('/');
  }, [me]);

  const register = useCallback(
    async (input: RegisterInput, redirectUrl?: string) => {
      try {
        const response = await registerMutation({ variables: { input } });
        const data = response?.data?.register;
        const redirectUrlParam = redirectUrl;

        if (data && data.token) {
          setToken(data.token);
          identify(data.id, {
            email: data.email,
            first_name: data.firstName,
            last_name: data.lastName,
            name: `${data.firstName} ${data.lastName}`,
          });

          client.cache.writeQuery<MeQuery>({
            data: { me: data },
            query: ME,
          });

          if (previousPath() !== '') {
            window.location.replace(previousPath());
            previousPath('');
          }

          if (redirectUrlParam) {
            redirect(redirectUrlParam);
          } else {
            navigate(HOME_PATH);
          }
        }
      } catch (error) {
        if (error.message.includes('robot')) {
          throw new MarbleError(error.message, error);
        }
        throw new MarbleError('Failed to register', error);
      }
    },
    [client.cache, navigate, registerMutation],
  );

  const requestResetPassword = useCallback(
    async (input: ResetPasswordInput) => {
      try {
        await requestMutation({
          optimisticResponse: {
            requestResetPassword: {
              __typename: 'ResetPasswordPayload',
              email: input.email,
            },
          },
          variables: { input },
        });
      } catch (error) {
        throw new MarbleError('Failed to request reset password', error);
      }
    },
    [requestMutation],
  );

  const resetPassword = useCallback(
    async (input: SetPasswordInput) => {
      try {
        await resetMutation({ variables: { input } });
      } catch (error) {
        throw new MarbleError('Failed to reset password', error);
      }
    },
    [resetMutation],
  );

  const validateResetToken = useCallback(
    async (token: string) => {
      try {
        await validateResetTokenMutation({
          variables: { input: { token } },
        });
      } catch (error) {
        return false;
      }

      return true;
    },
    [validateResetTokenMutation],
  );

  const setPasswordShopFlow = useCallback(
    async (input: RegisterInput) => {
      try {
        const response = await setPasswordShopFlowMutation({
          variables: { input },
        });
        const data = response?.data?.setPasswordShopFlow;

        if (data && data.token) {
          setToken(data.token);
          identify(data.id, {
            email: data.email,
            first_name: data.firstName,
            last_name: data.lastName,
            name: `${data.firstName} ${data.lastName}`,
          });

          client.cache.writeQuery<MeQuery>({
            data: { me: data },
            query: ME,
          });
        }
      } catch (error) {
        if (error.message.includes('robot')) {
          throw new MarbleError(error.message, error);
        }
        throw new MarbleError('Failed to set password', error);
      }
    },
    [client.cache, setPasswordShopFlowMutation],
  );

  return {
    loading:
      loginLoading ||
      setPasswordLoading ||
      socialLoginLoading ||
      registerLoading ||
      requestLoading ||
      resetLoading,
    login,
    logout,
    register,
    requestResetPassword,
    resetPassword,
    setPasswordShopFlow,
    socialLogin,
    validateResetToken,
    validateResetTokenLoading,
  };
};

export default useAuth;
