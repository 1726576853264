import { HIDE_FOOTER_ROUTES } from 'globals/constants';
import useModals from 'graphql/hooks/useModals';
import { ModalEnumTypes } from 'graphql/variables/modal';
import { RouteObject, matchRoutes, useLocation } from 'react-router-dom';

const useConnect = () => {
  const { pathname } = useLocation();
  const { openModal } = useModals();

  const handleOpen = () => {
    openModal(ModalEnumTypes.REFERRAL);
  };

  const isFooterHidden = matchRoutes(
    HIDE_FOOTER_ROUTES as RouteObject[],
    pathname,
  );
  return { handleOpen, isFooterHidden };
};

export default useConnect;
