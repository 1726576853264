import useConnect from './connect';
import {
  Button,
  Container,
  StyledClickAwayListener,
  TooltipBody,
} from './styles';
import { Props } from './types';

const Tooltip = ({
  alignLeft = false,
  children,
  content,
  direction = 'bottom',
  disableOutline = false,
  isMobile = false,
  margin = undefined,
  ariaLabel = 'Help',
  className,
  trackText = undefined,
}: Props) => {
  const { active, createMarkup, hideTip, showTip } = useConnect(trackText);

  return (
    <StyledClickAwayListener
      onClickAway={(e) => {
        if (active) {
          e.preventDefault();
          hideTip();
        }
      }}
    >
      <Container className={className}>
        <Button
          $alignLeft={alignLeft}
          $disableOutline={disableOutline}
          onMouseEnter={showTip}
          onFocus={showTip}
          onMouseLeave={hideTip}
          onBlur={hideTip}
          aria-describedby="tooltip-hint"
          aria-expanded={active}
          aria-label={ariaLabel}
          type="button"
        >
          {children}
        </Button>
        <TooltipBody
          $direction={direction}
          $isActive={!active}
          $isMobile={isMobile}
          $margin={margin}
          onMouseEnter={showTip}
          onMouseLeave={hideTip}
          onBlur={hideTip}
          dangerouslySetInnerHTML={createMarkup(content)}
          id="tooltip-hint"
          role="tooltip"
        />
      </Container>
    </StyledClickAwayListener>
  );
};

export default Tooltip;
