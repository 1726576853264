import { useQuery } from '@apollo/client';
import { RewardsQuery } from 'graphql/generated';
import { REWARDS } from 'graphql/queries/rewards';
import { normalizeReward } from 'models/Reward';
import { useMemo } from 'react';

const useRewards = (options: { skip?: boolean } = { skip: false }) => {
  const { data, loading } = useQuery<RewardsQuery>(REWARDS, options);

  const rewards = useMemo(
    () => (data?.rewards ? normalizeReward(data.rewards) : undefined),
    [data],
  );

  return {
    loading,
    rewards,
  };
};

export default useRewards;
