import { Small } from 'components/Typography';
import styled from 'styled-components';
import { ReactComponent as DefaultShieldIcon } from 'assets/images/icons/shield.svg';
import { FiHelpCircle as DefaultHelpIcon } from 'react-icons/fi';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  align-items: center;
`;

export const Disclaimer = styled(Small)`
  color: ${({ theme }) => theme.colors.starDust};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  padding-right: 0.5rem;
`;

export const CustomIconContainer = styled.div`
  & svg {
    inline-size: 2rem;
    block-size: 2rem;
  }
`;

export const HelpIcon = styled(DefaultHelpIcon)`
  width: 1rem;
  height: 1rem;
  color: ${({ theme }) => theme.colors.starDust};
`;

export const ShieldIcon = styled(DefaultShieldIcon)``;
