import { WithoutNulls } from 'utils/removeNulls';

import { BeneficiaryPartsFragment } from '../graphql/generated';

type Input = WithoutNulls<BeneficiaryPartsFragment>;

export const normalizeBeneficiary = (input: Input) => ({
  firstName: input.firstName,
  lastName: input.lastName,
  percentage: input.percentage,
});

export type Beneficiary = ReturnType<typeof normalizeBeneficiary>;
