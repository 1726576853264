import { useMutation } from '@apollo/client';
import {
  AuthorizePaymentDisplay,
  MutationAuthorizePaymentDisplayArgs,
  SavePaymentToken,
  MutationSavePaymentTokenArgs,
} from 'graphql/generated';
import { useCallback } from 'react';
import {
  AUTHORIZE_PAYMENT_DISPLAY,
  SAVE_PAYMENT_TOKEN,
} from 'graphql/mutations/payments';

const usePaymentActions = () => {
  const [savePaymentToken] = useMutation<
    SavePaymentToken,
    MutationSavePaymentTokenArgs
  >(SAVE_PAYMENT_TOKEN);
  const [authorizePaymentDisplay] = useMutation<
    AuthorizePaymentDisplay,
    MutationAuthorizePaymentDisplayArgs
  >(AUTHORIZE_PAYMENT_DISPLAY);

  const saveToken = useCallback(
    async (input: MutationSavePaymentTokenArgs) => {
      await savePaymentToken({
        variables: input,
      });
    },
    [savePaymentToken],
  );

  const authorizeDisplay = useCallback(
    async (input: MutationAuthorizePaymentDisplayArgs) => {
      await authorizePaymentDisplay({
        variables: input,
      });
    },
    [authorizePaymentDisplay],
  );

  return {
    authorizeDisplay,
    saveToken,
  };
};

export default usePaymentActions;
