import { ModalEnumTypes } from 'graphql/variables/modal';
import { lazy } from 'react';

const AddPolicyModal = lazy(() => import('./Modals/AddPolicyModal'));
const ArchiveHealthPolicyModal = lazy(
  () => import('./Modals/ArchiveHealthPolicyModal'),
);
const ArchivePolicyModal = lazy(() => import('./Modals/ArchivePolicyModal'));
const AvatarPickerModal = lazy(() => import('./Modals/AvatarPickerModal'));

const BalanceLockedModal = lazy(() => import('./Modals/BalanceLockedModal'));
const DECUploadInformationModal = lazy(
  () => import('./Modals/DECUploadInformationModal'),
);
const DocumentInformationModal = lazy(
  () => import('./Modals/DocumentInformationModal'),
);
const FeatureModal = lazy(() => import('containers/Main/Modals/FeatureModal'));

const FeedbackTrackerBottomSheet = lazy(
  () => import('./Modals/FeedbackTrackerSheet'),
);

const GetAppModal = lazy(() => import('./Modals/GetAppModal'));

const InformationModal = lazy(() => import('./Modals/InformationModal'));
const InformationReasonModal = lazy(
  () => import('./Modals/InformationReasonModal'),
);
const FeaturedPartnerModal = lazy(
  () => import('containers/Main/Modals/FeaturedPartnerModal'),
);
const RedemptionModal = lazy(() => import('./Modals/RedemptionModal'));
const ReferralModal = lazy(() => import('./Modals/ReferralModal'));
const ReminderModal = lazy(() => import('./Modals/ReminderModal'));
const ReminderConfirmationModal = lazy(
  () => import('./Modals/ReminderConfirmationModal'),
);
const RemoveConfirmationModal = lazy(
  () => import('./Modals/RemoveConfirmationModal'),
);

const ShareAccessModal = lazy(() => import('./Modals/ShareAccessModal'));
const VideoPlayerModal = lazy(
  () => import('containers/Main/Modals/VideoPlayerModal'),
);

const PlacementModal = lazy(
  () => import('containers/Main/Modals/PlacementModal'),
);

const ProgressModal = lazy(
  () => import('containers/Main/Modals/ProgressModal'),
);

const ShoppingZipCodeModal = lazy(
  () => import('containers/Main/Modals/ShopZipCodeModal'),
);

const EditHealthPolicyModal = lazy(
  () => import('containers/Main/Modals/EditHealthPolicyModal'),
);

const PhoneNumberModal = lazy(
  () => import('containers/Main/Modals/PhoneNumberModal'),
);

const ConfirmNameModal = lazy(
  () => import('containers/Main/Modals/ConfirmNameModal'),
);

const DismissOfferModal = lazy(
  () => import('containers/Main/Modals/DismissOfferModal'),
);

const ArchiveShoppingCartModal = lazy(
  () => import('containers/Main/Modals/ArchiveShoppingCartModal'),
);

const SaveShopFirstDetailedModal = lazy(
  () => import('containers/Main/Modals/SaveShopFirstDetailedModal'),
);

const GetQuoteLoadingModal = lazy(
  () => import('containers/Main/Modals/GetQuoteLoadingModal'),
);

const GetQuoteCategoryPickerModal = lazy(
  () => import('containers/Main/Modals/GetQuoteCategoryPickerModal'),
);

const GetQuoteExistingCartModal = lazy(
  () => import('containers/Main/Modals/GetQuoteExistingCartModal'),
);

const RequestOfferInfoModal = lazy(
  () => import('containers/Main/Modals/RequestOfferInfoModal'),
);

const ConfirmPaymentMethodModal = lazy(
  () => import('containers/Main/Modals/ConfirmPaymentMethodModal'),
);

export const CommonModals = [
  <AddPolicyModal key={ModalEnumTypes.ADD_POLICY} />,
  <ArchiveShoppingCartModal key={ModalEnumTypes.ARCHIVE_SHOPPING_CART} />,
  <AvatarPickerModal key={ModalEnumTypes.AVATAR_PICKER} />,
  <BalanceLockedModal key={ModalEnumTypes.BALANCE} />,
  <ConfirmNameModal key={ModalEnumTypes.CONFIRM_NAME} />,
  <DECUploadInformationModal key={ModalEnumTypes.DEC_UPLOAD} />,
  <DismissOfferModal key={ModalEnumTypes.DISMISS_OFFER} />,
  <DocumentInformationModal key={ModalEnumTypes.DOCUMENT_INFORMATION} />,
  <EditHealthPolicyModal key={ModalEnumTypes.EDIT_HEALTH_POLICY} />,
  <FeaturedPartnerModal key={ModalEnumTypes.FEATURED_PARTNER} />,
  <FeatureModal key={ModalEnumTypes.FEAT} />,
  <FeedbackTrackerBottomSheet key={ModalEnumTypes.FEEDBACK_TRACKER} />,
  <GetAppModal key={ModalEnumTypes.GET_APP} />,
  <InformationModal key={ModalEnumTypes.INFORMATION} />,
  <PlacementModal key={ModalEnumTypes.PLACEMENT} />,
  <ProgressModal key={ModalEnumTypes.PROGRESS} />,
  <ReferralModal key={ModalEnumTypes.REFERRAL} />,
  <ReminderConfirmationModal key={ModalEnumTypes.REMINDER_CONFIRMATION} />,
  <ReminderModal key={ModalEnumTypes.REMINDER} />,
  <ShoppingZipCodeModal key={ModalEnumTypes.SHOP_ZIP_CODE} />,
  <GetQuoteCategoryPickerModal
    key={ModalEnumTypes.GET_QUOTE_CATEGORY_PICKER}
  />,
  <GetQuoteExistingCartModal key={ModalEnumTypes.GET_QUOTE_EXISTING_CART} />,
  <RequestOfferInfoModal key={ModalEnumTypes.REQUEST_OFFER_INFO} />,
];

export const HasPolicyModals = [
  <ArchivePolicyModal key={ModalEnumTypes.ARCHIVE_POLICY} />,
  <ArchiveHealthPolicyModal key={ModalEnumTypes.ARCHIVE_HDV_POLICY} />,
  <InformationReasonModal key={ModalEnumTypes.INFORMATION_REASON} />,
  <RedemptionModal key={ModalEnumTypes.REDEMPTION} />,
  <RemoveConfirmationModal key={ModalEnumTypes.REMOVE_CONFIRMATION} />,
  <ShareAccessModal key={ModalEnumTypes.SHARE_ACCESS} />,
  <VideoPlayerModal key={ModalEnumTypes.VIDEO_PLAYER} />,
  <PhoneNumberModal key={ModalEnumTypes.PHONE_NUMBER_VERIFICATION} />,
];

export const RegisterModals = [
  <SaveShopFirstDetailedModal
    key={ModalEnumTypes.SAVE_SHOP_FIRST_DETAILED_FORM}
  />,
  <GetQuoteLoadingModal key={ModalEnumTypes.GET_QUOTE_LOADING} />,
];

export const PaymentModals = [
  <ConfirmPaymentMethodModal key={ModalEnumTypes.CONFIRM_PAYMENT_METHOD} />,
];
