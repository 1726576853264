import { useEffect, useState } from 'react';
import keysOf from 'utils/keysOf';

const sizes = {
  desktop: 1441,
  laptop: 1199.98,
  mobile: 689.98,
  smallMobile: 480,
  tablet: 891.98,
  tabletPortrait: 750,
} as const;

const minWidthQuery = (width: number) => `@media (min-width: ${width}px)`;
const maxWidthQuery = (width: number) => `@media (max-width: ${width}px)`;

export const from = keysOf(sizes).reduce(
  (acc, key) => ({
    ...acc,
    [key]: minWidthQuery(sizes[key]),
  }),
  {} as { [key in keyof typeof sizes]: string },
);

export const until = keysOf(sizes).reduce(
  (acc, key) => ({
    ...acc,
    [key]: maxWidthQuery(sizes[key]),
  }),
  {} as { [key in keyof typeof sizes]: string },
);

export const useMediaQuery = (query: string): boolean => {
  const mediaQuery = query.replace('@media', '').trim();

  const [match, setMatch] = useState(
    () => window.matchMedia(mediaQuery).matches,
  );

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery);

    const handleChange = (event: MediaQueryListEvent) =>
      setMatch(event.matches);

    mediaQueryList.addEventListener('change', handleChange);

    return () => mediaQueryList.removeEventListener('change', handleChange);
  }, [mediaQuery]);

  return match;
};
