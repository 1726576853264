import { Link as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  Body,
  BodyBold,
  BodyMedium,
  H1,
  H2,
  H3,
  H4,
} from 'components/Typography';

export const Container = styled.div`
  ul,
  ol {
    margin-block-end: 1.5rem;
  }
`;

export const Text = styled(Body)`
  margin-block-end: 1rem;
`;

export const BoldText = styled(BodyBold).attrs({ as: 'span' })`
  margin-block-end: 1rem;
`;

export const Title1 = styled(H1)`
  margin-block-end: 0.75rem;
`;
export const Title2 = styled(H2)`
  margin-block-end: 0.75rem;
`;
export const Title3 = styled(H3)`
  margin-block-end: 0.75rem;
`;
export const Title4 = styled(H4)`
  margin-block-end: 1rem;
`;
export const Title5 = styled(BodyBold)`
  margin-block-end: 1rem;
`;

export const Title6 = styled(BodyMedium)`
  margin-block-end: 1rem;
`;

export const LinkStyles = css`
  color: ${({ theme }) => theme.colors.bluewood};
  transition: color ease 300ms;

  &:visited {
    color: ${({ theme }) => theme.colors.bluewood};
  }

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.pacificBlueDark};
  }
`;

export const ExternalLink = styled.a`
  ${LinkStyles}
`;
export const InternalLink = styled(RouterLink)`
  ${LinkStyles}
`;

const ListItemStyles = css`
  margin-block-end: 1rem;

  > p {
    margin-block-end: 0.75rem;
  }

  &:last-child {
    margin-block-end: 1rem;
  }
`;

export const UlItem = styled(Body).attrs({ as: 'li' })`
  ${ListItemStyles}
  list-style-type: disc;
  margin-inline-start: 1rem;
  padding-inline-start: 0.5rem;
`;

export const OlItem = styled(Body).attrs({ as: 'li' })`
  ${ListItemStyles}
  counter-increment: counter;
  position: relative;
  padding-inline-start: 3rem;

  &::before {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: 1rem;
    padding: 0.5rem;
    font-weight: 500;
    background-color: ${({ theme }) => theme.colors.eastBaySmooth};
    border-radius: 0.25rem;
    content: counter(counter);
  }
`;

export const BreakLine = styled.span`
  margin-block-end: 0.5rem;
  display: flex;
`;
