export const capitalize = (text: string) => {
  if (text.length === 0) return text;

  if (!text.includes('.')) {
    return text[0].toUpperCase() + text.slice(1).toLocaleLowerCase();
  } else {
    const newStr = text.replace(/([!?.]\s+)([a-z])/g, function (_, char, next) {
      return char + next.toUpperCase();
    });
    return newStr.charAt(0).toUpperCase() + newStr.slice(1);
  }
};
