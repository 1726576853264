import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */

export const ME_FRAGMENT = gql`
  fragment MeParts on UserType {
    id
    firstName
    lastName
    preferredName
    legalNameConfirmed
    address
    city
    zipCode
    state
    phone
    avatar
    email
    created
    token
    gender
    dob
    marbleEarned
    marbleAvailable
    marbleAvailableConverted
    progressBar {
      action
      status
    }
    referralCode
    referrals {
      firstName
      lastName
      memberSince
    }
    totalReferralsBalance
    viewedAsBy
    signupBonus
    isAmbassador
    insuranceTypes
    appDownloaded
    shoppingZipCode
    creditScore
    isHomeOwner
    isInsuredForAuto
    isNewUser
    usesTobaccoProducts
    petType
    petGender
    policyUploadRewards {
      category
      marbleCount
    }
    zendeskToken
    moveInDate
    previousAddress
    previousCity
    previousZipCode
    previousState
    ownership
  }
`;
