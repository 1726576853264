import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */

export const USER_FRAGMENT = gql`
  fragment UserParts on UserType {
    email
    firstName
    lastName
  }
`;
