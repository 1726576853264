import { Body, H4 } from 'components/Typography';
import styled from 'styled-components';
import { FiAlertCircle as DefaultAlertIcon } from 'react-icons/fi';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  text-align: center;
`;

export const Title = styled(H4)`
  margin-top: 1rem;
`;

export const Subtitle = styled(Body)`
  max-width: 30rem;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
`;

export const CheckIcon = styled(DefaultAlertIcon)`
  width: 2rem;
  height: 2rem;
  color: ${({ theme }) => theme.colors.persimon};
`;
