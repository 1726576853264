import { gql } from '@apollo/client';
import { FEATURE_FRAGMENT } from 'graphql/fragments';

/* eslint-disable import/prefer-default-export */

export const FEATURES = gql`
  query Features {
    featureItems {
      ...FeatureParts
    }
  }
  ${FEATURE_FRAGMENT}
`;
