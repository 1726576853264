import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from 'utils/localStorage';

const TOKEN_KEY = 'marble-token';

export const getToken = () => getLocalStorageItem(TOKEN_KEY) || '';

export const removeToken = () => removeLocalStorageItem(TOKEN_KEY);

export const setToken = (token: string) =>
  setLocalStorageItem(TOKEN_KEY, token);
