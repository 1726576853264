import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */

export const DOCUMENT_FRAGMENT = gql`
  fragment DocumentParts on PolicyDocumentType {
    id
    created
    name
    documentType
    documentSize
  }
`;
