import { useQuery } from '@apollo/client';
import { PaymentPageDetailsQuery } from 'graphql/generated';
import { GET_PAYMENT_PAGE_DETAILS } from 'graphql/queries/payments';
import { useMemo } from 'react';

const usePaymentPageDetails = ({ offerId }: { offerId?: string }) => {
  const { data, loading } = useQuery<PaymentPageDetailsQuery>(
    GET_PAYMENT_PAGE_DETAILS,
    {
      variables: { offerId },
    },
  );

  const details = useMemo(
    () => (data?.paymentPageDetails ? data.paymentPageDetails : undefined),
    [data],
  );

  return {
    details,
    loading,
  };
};

export default usePaymentPageDetails;
