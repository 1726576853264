import { useParams } from 'react-router-dom';

import OfferSummary from 'components/OfferSummary';
import {
  Container,
  Content,
  DesktopTitle,
  LoadingContainer,
  MobileTitle,
} from './styles';
import PaymentMethodSelection from 'components/PaymentMethodSelection';
import useConnect from './connect';
import FullscreenLoader from 'components/FullscreenLoader';
import { Props } from './types';
import PaymentDetailsConfirmation from 'components/PaymentDetailsConfirmation';
import { PaymentModals } from 'containers/Main/modals';
import PaymentDetailsNotFound from 'components/PaymentDetailsNotFound';

const PaymentDetails = ({ userId }: Props) => {
  const { offerId } = useParams<{
    offerId: string;
  }>();
  const isLoggedIn = !!userId;
  const { details, hasNoAccessToPaymentDetails, isMobile, loading } =
    useConnect({ isLoggedIn, offerId });
  if (loading)
    return (
      <LoadingContainer>
        <FullscreenLoader isFullPage={false} />
      </LoadingContainer>
    );
  if (!details || !offerId) return <PaymentDetailsNotFound />;
  const {
    category,
    email,
    termMonths,
    namedInsureds,
    carrier,
    carrierLogo,
    assetName,
    expiryDate,
    offerDetails,
    monthlyPremium,
    paymentDetails,
    isPaymentSubmitted,
  } = details;
  if (!category) return <PaymentDetailsNotFound />;

  const title = isPaymentSubmitted
    ? 'View payment details'
    : 'Confirm payment details';

  return (
    <>
      <Container>
        {isMobile ? (
          <MobileTitle>{title}</MobileTitle>
        ) : (
          <DesktopTitle>{title}</DesktopTitle>
        )}
        {!loading && category && (
          <Content>
            <OfferSummary
              category={category}
              termMonths={termMonths}
              assetName={assetName}
              namedInsureds={namedInsureds}
              carrier={carrier}
              carrierLogo={carrierLogo}
              expiryDate={expiryDate}
              offerDetails={offerDetails}
              monthlyPremium={monthlyPremium}
            />
            {(isPaymentSubmitted && !isLoggedIn) ||
            hasNoAccessToPaymentDetails ? (
              <PaymentDetailsConfirmation carrier={carrier} email={email} />
            ) : (
              <PaymentMethodSelection
                details={paymentDetails}
                isLoggedIn={isLoggedIn}
                offerId={offerId}
                isPaymentSubmitted={isPaymentSubmitted}
                carrier={carrier}
                userEmail={email}
              />
            )}
          </Content>
        )}
      </Container>
      {!loading && PaymentModals}
    </>
  );
};

export default PaymentDetails;
