import { useQuery } from '@apollo/client';
import { MeQuery } from 'graphql/generated';
import { ME } from 'graphql/queries/me';
import { normalizeUser } from 'models/User';
import { useMemo } from 'react';
import { getToken } from 'services/token';

const useMe = () => {
  const { data, loading, refetch } = useQuery<MeQuery>(ME, {
    skip: !getToken(),
  });

  const me = useMemo(
    () => (data?.me ? normalizeUser(data.me) : undefined),
    [data],
  );

  return {
    loading,
    me,
    refetch,
  };
};

export default useMe;
