import Guest from 'containers/Guest';
import Main from 'containers/Main';

import Loader from 'components/FullscreenLoader';

import previousPath from './cache';
import useConnect from './connect';

import useScrollToLocation from 'graphql/hooks/useScrollToLocation';
import {
  PAYMENT_PATH,
  REGISTER_CONFIRM_PATH,
  REGISTER_SHOP_FIRST_DETAILED_PATH,
  REGISTER_SHOP_FIRST_PATH_NEW_POLICY_LOGIN,
  REGISTER_SHOP_FIRST_PATH_NEW_POLICY_LOGIN_SUCCESS,
} from 'globals/constants';

const Root = () => {
  const { loading, location, me } = useConnect();
  useScrollToLocation();

  if (loading && !me) return <Loader isFullPage />;

  // Don't redirect to login if user is on these exact pages
  const authedRegisterPaths = [
    REGISTER_CONFIRM_PATH,
    REGISTER_SHOP_FIRST_PATH_NEW_POLICY_LOGIN,
    REGISTER_SHOP_FIRST_PATH_NEW_POLICY_LOGIN_SUCCESS,
  ];

  // Both registered and guest users can access these pages
  const authedAndGuestPaths = [PAYMENT_PATH];

  // Don't redirect to login if user is on any pages with these subpaths
  const authedRegisterSubpaths = [REGISTER_SHOP_FIRST_DETAILED_PATH];

  const matchesExactHidePath = authedRegisterPaths.includes(location.pathname);
  const containsHideSubPath = authedRegisterSubpaths.some((path) =>
    location.pathname.includes(path),
  );

  const matchesExactAuthedAndGuestPath = authedAndGuestPaths.includes(
    location.pathname,
  );

  if (!me || matchesExactHidePath || containsHideSubPath) {
    if (location.pathname !== undefined && location.pathname !== '/') {
      previousPath(location.pathname);
    }
    return <Guest />;
  }

  if (!me || matchesExactAuthedAndGuestPath) {
    return <Guest />;
  }

  return <Main />;
};

export default Root;
