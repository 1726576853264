import { keyframes } from 'styled-components';

/* eslint-disable import/prefer-default-export */

export const spin = keyframes`
    from {
      transform: rotate(0deg);
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
      transform: rotate(359deg);
    }
`;
