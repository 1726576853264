import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */

export const COVERAGE_ITEMS_INFO_EXAMPLE_FRAGMENT = gql`
  fragment CoverageItemInfoExampleParts on CoverageItemInfoExampleType {
    id
    exampleType
    title
    icon
    videoUrl
    order
  }
`;
