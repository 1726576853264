import { useApolloClient, useMutation } from '@apollo/client';
import {
  AcceptSharedAccessMutation,
  AcceptSharedAccessMutationVariables,
  ArchivePolicyMutation,
  ArchivePolicyMutationVariables,
  AreCarrierCredentialsValidQuery,
  AreCarrierCredentialsValidQueryVariables,
  CreatePolicyUserMutation,
  CreatePolicyUserMutationVariables,
  GetUploadUrlMutation,
  GetUploadUrlMutationVariables,
  MeQuery,
  PoliciesQuery,
  UploadPolicyDocumentMutation,
  UploadPolicyDocumentMutationVariables,
} from 'graphql/generated';

import {
  POLICY,
  SYNC_PENDING,
  PENDING,
  ARCHIVE_REASON,
} from 'globals/constants';

import { MarbleError } from 'graphql/helpers';
import { GET_UPLOAD_URL } from 'graphql/mutations/global';
import {
  ACCEPT_SHARED_POLICY_ACCESS,
  ARCHIVE_POLICY,
  CREATE_POLICY_USER,
  UPLOAD_POLICY_DOCUMENT,
} from 'graphql/mutations/policy';
import {
  ARE_CARRIER_CREDENTIALS_VALID,
  POLICIES,
} from 'graphql/queries/policy';
import { ME } from 'graphql/queries/me';
import { useCallback, useState } from 'react';

import {
  ArchivePolicyInput,
  CreateUserInput,
  Result,
  UploadDocumentInput,
} from './types';

const usePolicyActions = () => {
  const client = useApolloClient();
  const [archivePolicyMutation] = useMutation<
    ArchivePolicyMutation,
    ArchivePolicyMutationVariables
  >(ARCHIVE_POLICY);
  const [createUserMutation] = useMutation<
    CreatePolicyUserMutation,
    CreatePolicyUserMutationVariables
  >(CREATE_POLICY_USER);
  const [getUploadUrlMutation] = useMutation<
    GetUploadUrlMutation,
    GetUploadUrlMutationVariables
  >(GET_UPLOAD_URL);
  const [uploadDocumentsMutation] = useMutation<
    UploadPolicyDocumentMutation,
    UploadPolicyDocumentMutationVariables
  >(UPLOAD_POLICY_DOCUMENT);
  const [acceptSharedAccessMutation] = useMutation<
    AcceptSharedAccessMutation,
    AcceptSharedAccessMutationVariables
  >(ACCEPT_SHARED_POLICY_ACCESS);
  const [createUserLoading, setCreateUserLoading] = useState<boolean>(false);
  const [uploadDocumentsLoading, setUploadDocumentsLoading] =
    useState<boolean>(false);

  const createUser = useCallback(
    async (input: CreateUserInput) => {
      try {
        let isValid: boolean = false;

        setCreateUserLoading(true);

        try {
          const isValidResponse = await client.query<
            AreCarrierCredentialsValidQuery,
            AreCarrierCredentialsValidQueryVariables
          >({
            fetchPolicy: 'network-only',
            query: ARE_CARRIER_CREDENTIALS_VALID,
            variables: {
              carrierId: input.carrierId,
              password: input.policyPassword,
              username: input.policyUsername,
            },
          });
          isValid = isValidResponse.data.areCarrierCredentialsValid ?? false;
        } catch (error) {
          throw new Error('These credentials are not valid!');
        }

        if (isValid) {
          const response = await createUserMutation({
            variables: {
              input: {
                carrier: input.carrierId,
                category: input.policyCategory,
                password: input.policyPassword,
                policyId: input.policyId,
                renewal: input.renewal,
                username: input.policyUsername,
              },
            },
          });
          const data = response.data?.createUserCarrier;

          if (data) {
            const cache = client.readQuery<PoliciesQuery>({
              query: POLICIES,
            });

            if (cache?.policies) {
              client.writeQuery<PoliciesQuery>({
                data: {
                  policies: [
                    ...cache.policies.filter(
                      (policy) =>
                        policy.id !== input.policyId ||
                        policy.category !== input.policyCategory,
                    ),
                    data,
                  ],
                },
                query: POLICIES,
                variables: { isCurrent: true },
              });
            }
          }
        } else {
          throw new Error('These credentials are not valid!');
        }
      } catch (error) {
        throw new MarbleError(error.message, error);
      } finally {
        setCreateUserLoading(false);
      }
    },
    [client, createUserMutation],
  );

  const archivePolicy = useCallback(
    async (input: ArchivePolicyInput) => {
      const { policyId, archiveReason } = input;
      try {
        const response = await archivePolicyMutation({
          variables: {
            input: {
              archiveReason,
              id: policyId,
            },
          },
        });

        const data = response.data?.archivePolicy;

        if (data) {
          const cache = client.readQuery<PoliciesQuery>({
            query: POLICIES,
          });

          if (
            cache?.policies &&
            archiveReason !== ARCHIVE_REASON.USER_REQUEST_FIX
          ) {
            client.writeQuery<PoliciesQuery>({
              data: {
                policies: [
                  ...cache.policies.filter(
                    (policy) => policy.id !== input.policyId,
                  ),
                ],
              },
              query: POLICIES,
              variables: { isCurrent: true },
            });
          }
        }
      } catch (error) {
        throw new MarbleError(error.message, error);
      }
    },
    [archivePolicyMutation, client],
  );

  const uploadDocuments = useCallback(
    async (input: UploadDocumentInput) => {
      try {
        const results: Result[] = [];

        setUploadDocumentsLoading(true);

        for (const file of input.files) {
          const getUploadUrlResponse = await getUploadUrlMutation({
            variables: {
              input: {
                contentType: file.type,
                filename: file.name,
                private: true,
              },
            },
          });
          const getUploadUrlData = getUploadUrlResponse.data?.getUploadUrl;

          if (
            getUploadUrlData &&
            getUploadUrlData.retrieveUrl &&
            getUploadUrlData.uploadUrl
          ) {
            results.push({
              file,
              retrieveUrl: getUploadUrlData.retrieveUrl,
              uploadUrl: getUploadUrlData.uploadUrl,
            });
          }
        }

        if (results.length > 0) {
          const success: boolean[] = [];

          for (const result of results) {
            const uploadFileResponse = await fetch(result.uploadUrl, {
              body: result.file,
              headers: {
                'x-amz-acl': 'private',
              },
              method: 'PUT',
            });
            success.push(uploadFileResponse.ok);
          }

          if (success.length > 0 && success.every((current) => current)) {
            const uploadDocumentsResponse = await uploadDocumentsMutation({
              variables: {
                input: {
                  carrier: input.carrierId,
                  category: input.policyCategory,
                  documents: results.map((result) => ({
                    documentSize: result.file.size,
                    documentType: input.documentType || 'DOCUMENT_DEC',
                    name: result.file.name.substr(0, 100),
                    urlFile: result.retrieveUrl,
                  })),
                  policy: input.policyId,
                  renewal: input.renewal,
                },
              },
            });
            const uploadDocumentsData =
              uploadDocumentsResponse.data?.uploadPolicyDocument;

            if (uploadDocumentsData) {
              const cache = client.readQuery<PoliciesQuery>({
                query: POLICIES,
              });

              if (cache?.policies) {
                client.writeQuery<PoliciesQuery>({
                  data: {
                    policies: [
                      ...cache.policies.filter(
                        (policy) =>
                          policy.id !== input.policyId ||
                          policy.category !== input.policyCategory,
                      ),
                      uploadDocumentsData,
                    ],
                  },
                  query: POLICIES,
                  variables: { isCurrent: true },
                });
              }
            }

            // update for progress bar
            const cache = client.readQuery<MeQuery>({
              query: ME,
            });
            if (cache?.me) {
              const updatedProgress = cache.me?.progressBar?.map((x) => {
                if (x.action === POLICY && x.status === PENDING) {
                  return {
                    __typename: x.__typename,
                    action: x.action,
                    status: SYNC_PENDING,
                  };
                }
                return x;
              });

              client.writeQuery<MeQuery>({
                data: {
                  me: {
                    ...cache?.me,
                    progressBar: updatedProgress,
                  },
                },
                query: ME,
              });
            }
          }
        }
      } catch (error) {
        throw new MarbleError('Failed to upload documents', error);
      } finally {
        setUploadDocumentsLoading(false);
      }
    },
    [
      client,
      getUploadUrlMutation,
      setUploadDocumentsLoading,
      uploadDocumentsMutation,
    ],
  );

  const acceptSharedAccess = useCallback(
    async (sharedAccessId: string) => {
      try {
        await acceptSharedAccessMutation({
          variables: {
            sharedAccessId: sharedAccessId,
          },
        });
        return true;
      } catch (error) {
        return false;
      }
    },
    [acceptSharedAccessMutation],
  );

  return {
    acceptSharedAccess,
    archivePolicy,
    createUser,
    loading: createUserLoading || uploadDocumentsLoading || uploadDocuments,
    uploadDocuments,
  };
};

export default usePolicyActions;
