import { gql } from '@apollo/client';
import { ME_FRAGMENT } from 'graphql/fragments';

export const LOGIN = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      ...MeParts
    }
  }
  ${ME_FRAGMENT}
`;

export const SOCIAL_LOGIN = gql`
  mutation SocialLogin($input: SocialLoginInput!) {
    socialLogin(input: $input) {
      ...MeParts
    }
  }
  ${ME_FRAGMENT}
`;

export const REGISTER = gql`
  mutation Register($input: RegisterInput!) {
    register(input: $input) {
      ...MeParts
    }
  }
  ${ME_FRAGMENT}
`;

export const REQUEST_RESET_PASSWORD = gql`
  mutation RequestResetPassword($input: ResetPasswordInput!) {
    requestResetPassword(input: $input) {
      email
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: SetPasswordInput!) {
    resetPassword(input: $input)
  }
`;

export const VALIDATE_RESET_TOKEN = gql`
  mutation ValidateResetToken($input: ValidateResetTokenInput!) {
    validateResetToken(input: $input)
  }
`;

export const SET_PASSWORD_SHOP_FLOW = gql`
  mutation SetPasswordShopFlow($input: ShopSetPasswordInput!) {
    setPasswordShopFlow(input: $input) {
      ...MeParts
    }
  }
  ${ME_FRAGMENT}
`;
