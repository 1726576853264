import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */

export const REDEMPTION_PRODUCT_FRAGMENT = gql`
  fragment ProductParts on RedemptionProductType {
    id
    productName
    title
    subtitle
    active
    maximumAmount
    minimumAmount
    conversionRatio
    logo
    logoCard
    isDonation
    provider
  }
`;
