import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import {
  BannerType,
  BannersQuery,
  BannersQueryVariables,
} from 'graphql/generated';
import { BANNERS } from 'graphql/queries/banners';
import {
  BANNER_PATHS,
  HOME_PATH,
  LOGIN_PAGE,
  MODAL_FADE_DURATION,
  WALLET,
} from 'globals/constants';

import useMe from '../useMe';
import { useLocation } from 'react-router-dom';
import { getLocalStorageBool, setLocalStorageItem } from 'utils/localStorage';

const useBanners = (
  variables: BannersQueryVariables = { sourceType: 'WEB_APP' },
) => {
  const { data } = useQuery<BannersQuery, BannersQueryVariables>(BANNERS, {
    variables,
  });

  const { me } = useMe();
  const { pathname } = useLocation();
  const [currentBanner, setCurrentBanner] = useState<string | undefined>();
  const [currentKey, setCurrentKey] = useState<string>('');
  const [showNotification, setShowNotification] = useState<boolean>(true);
  const [isClosing, setIsClosing] = useState<boolean>(false);

  const handleClose = () => {
    setLocalStorageItem(currentKey, true, me?.id);
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      setShowNotification(false);
    }, MODAL_FADE_DURATION);
  };

  const pickBanner = useCallback(
    (banners: Pick<BannerType, 'page' | 'text'>[], hasUser: boolean) => {
      if (hasUser && pathname === HOME_PATH) {
        return banners.find((cur) => cur?.page?.includes(WALLET))?.text;
      } else if (pathname === HOME_PATH) {
        return banners.find((cur) => cur?.page === LOGIN_PAGE)?.text;
      } else {
        return banners[0]?.text;
      }
    },
    [pathname],
  );

  useEffect(() => {
    const isRead = getLocalStorageBool(currentKey, me?.id);
    setShowNotification(!isRead);

    const key = `banner${pathname}/${me ? 'main' : 'guest'}`;
    if (key !== currentKey) {
      setCurrentKey(key);
    }
  }, [currentKey, pathname, me]);

  useEffect(() => {
    const bannerRoute = BANNER_PATHS[pathname as keyof typeof BANNER_PATHS];
    if (bannerRoute && data?.banners) {
      const availableBanners = data?.banners.filter((cur) =>
        bannerRoute.includes(cur?.page as keyof typeof BANNER_PATHS),
      );
      const banner = pickBanner(availableBanners, !!me);
      setCurrentBanner(banner);
    } else {
      setCurrentBanner(undefined);
    }
  }, [data, me, pathname, pickBanner]);

  return {
    banner: showNotification && currentBanner,
    handleClose,
    isClosing,
  };
};

export default useBanners;
