import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useScrollToLocation = () => {
  const { hash, pathname } = useLocation();
  const [clientHeight, setClientHeight] = useState(0);
  const navigate = useNavigate();
  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((entries) =>
        setClientHeight(entries[0].target.clientHeight),
      ),
    [],
  );

  resizeObserver.observe(document.body);

  useEffect(() => {
    const id = hash.replace('#', '');
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView();
      setTimeout(() => {
        navigate(pathname);
      }, 1000);
    }
  }, [clientHeight, pathname, resizeObserver, hash, navigate]);
};

export default useScrollToLocation;
