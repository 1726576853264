import { PRIVACY_POLICY } from 'globals/constants';
import { memo } from 'react';

import useConnect from './connect';
import {
  Container,
  Content,
  Copyright,
  ExternalLink,
  InternalLink,
  Links,
  Logo,
  ReferButton,
} from './styles';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { handleOpen, isFooterHidden } = useConnect();

  if (isFooterHidden) return null;
  return (
    <Container>
      <Content>
        <InternalLink to="/">
          <Logo size="small" colorVariant="grey" />
        </InternalLink>
        <Links>
          <ReferButton onClick={handleOpen}>Refer</ReferButton>
          <ExternalLink
            href={PRIVACY_POLICY}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy
          </ExternalLink>
        </Links>
        <Copyright>© {currentYear} Marble. All rights reserved.</Copyright>
      </Content>
    </Container>
  );
};

export default memo(Footer);
