import { Fragment, memo } from 'react';

import Input from './Input';
import useLogic from './logic';
import { AllCapsLegend, Container, Error, Group, Legend } from './styles';
import { Props } from './types';
import useConnect from './connect';

const InputGroup = ({
  className,
  direction,
  disabled,
  legend,
  name,
  options,
  role,
  withHighlight = false,
  onClick,
  allCaps,
  variant = 'primary',
  fullWidth,
  fullHeight,
  defaultValue,
}: Props) => {
  const { error } = useLogic({ name });

  const { clicked, handleFirstClick } = useConnect();

  return (
    <Container className={className} $variant={variant}>
      {legend &&
        (allCaps ? (
          <AllCapsLegend>{legend}</AllCapsLegend>
        ) : (
          <Legend>{legend}</Legend>
        ))}
      <Group
        $direction={direction}
        $variant={variant}
        $fullWidth={fullWidth}
        $fullHeight={fullHeight}
      >
        {options.map((option, index) => {
          return (
            <Fragment key={`${option.value}_${index}`}>
              <Input
                defaultChecked={defaultValue === option.value && !clicked}
                disabled={disabled}
                id={`${name}_${index}`}
                key={`${option.value}_${index}`}
                label={option.label}
                name={option.name || name}
                role={role}
                value={option.value}
                withHighlight={withHighlight}
                onClick={(v) => {
                  handleFirstClick();
                  if (onClick) onClick(v);
                }}
                variant={variant}
                leftIcon={option.leftIcon}
                customComponent={option.customComponent}
              />
            </Fragment>
          );
        })}
      </Group>
      {error && <Error>{error}</Error>}
    </Container>
  );
};

export default memo(InputGroup);
