import { parseISO } from 'date-fns';
import { UserType } from 'graphql/generated';

type InputUser = UserType;

export const normalizeUser = (input: InputUser) => {
  const fullName =
    input.firstName && input.lastName
      ? `${input.firstName} ${input.lastName}`
      : undefined;
  const initials =
    input.firstName && input.lastName
      ? `${input.firstName.charAt(0)}${input.lastName.charAt(0)}`.toUpperCase()
      : undefined;
  const isViewedAs = !!input.viewedAsBy;

  return {
    address: input.address,
    appDownloaded: input.appDownloaded,
    avatar: input.avatar,
    city: input.city,
    created: input.created ? parseISO(input.created).getTime() : undefined,
    creditScore: input.creditScore || undefined,
    dob: input.dob ? parseISO(input.dob).getTime() : undefined,
    email: input.email,
    firstName: input.firstName,
    fullName,
    gender: input.gender,
    id: input.id,
    initials,
    insuranceTypes: input?.insuranceTypes,
    isAmbassador: input.isAmbassador || false,
    isHomeOwner: input.isHomeOwner || false,
    isInsuredForAuto: input.isInsuredForAuto || false,
    isNewAccount:
      input.created && Date.now() - Date.parse(input.created) < 86400,
    isViewedAs,
    lastName: input.lastName,
    legalNameConfirmed: input.legalNameConfirmed,
    marbleAvailable: input.marbleAvailable,
    marbleAvailableConverted:
      input.marbleAvailableConverted && input.marbleAvailableConverted > 0
        ? input.marbleAvailableConverted
        : undefined,
    marbleEarned: input.marbleEarned || 0,
    moveInDate: input.moveInDate
      ? parseISO(input.moveInDate).getTime()
      : undefined,
    ownership: input.ownership,
    petGender: input.petGender,
    petType: input.petType,
    phone: input.phone,
    policyUploadRewards: input.policyUploadRewards || [],
    preferredName: input.preferredName,
    previousAddress: input.previousAddress,
    previousCity: input.previousCity,
    previousState: input.previousState,
    previousZipCode: input.previousZipCode,
    progressBar: input.progressBar,
    referralCode: input.referralCode,
    referrals: input.referrals || [],
    shoppingZipCode: input.shoppingZipCode || '',
    signupBonus: input.signupBonus || undefined,
    stateId: input.state,
    token: input.token,
    totalReferralsBalance: input.totalReferralsBalance || 0,
    usesTobacco: input.usesTobaccoProducts,
    viewedAs: input.viewedAsBy || undefined,
    zendeskToken: input.zendeskToken,
    zipCode: input.zipCode,
  };
};

export type User = ReturnType<typeof normalizeUser>;
