import { StateType } from 'graphql/generated';

type InputState = StateType;

export const normalizeState = (input: InputState) => ({
  abbreviation: input.abbreviation,
  id: input.id,
  name: input.name,
});

export type State = ReturnType<typeof normalizeState>;
