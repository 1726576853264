import { Menu } from '@reach/menu-button';
import { FC, memo } from 'react';

import { Content, Trigger } from './styles';
import { Props } from './types';

const Dropdown: FC<Props> = ({ children }) => <Menu>{children}</Menu>;

export default memo(Dropdown);

export { Content as DropdownContent, Trigger as DropdownTrigger };
