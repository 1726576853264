import {
  DriverPartsFragment,
  HomeOwnerPartsFragment,
  LifeInsuredPartsFragment,
  NamedInsuredType,
  RenterPartsFragment,
} from 'graphql/generated';
import { WithoutNulls } from 'utils/removeNulls';
import { formatDateSlashNoLocale } from 'utils/dates';

import { normalizeState } from 'models/State';

type DriverInput = WithoutNulls<DriverPartsFragment>;
type LifeInsuredInput = WithoutNulls<LifeInsuredPartsFragment>;
type NamedInsuredBaseInput =
  | WithoutNulls<HomeOwnerPartsFragment>
  | WithoutNulls<RenterPartsFragment>;

export const normalizeNamedInsuredBase = (data: NamedInsuredBaseInput) => ({
  dob: data.dob ? formatDateSlashNoLocale(new Date(data.dob)) : undefined,
  firstName: data.firstName,
  gender: data.gender,
  id: data.id,
  lastName: data.lastName,
  name: `${data.firstName} ${data.lastName}`,
  relationToMarbleMember: data.relationToMarbleMember,
});

export const normalizeDriver = (data: DriverInput) => ({
  ageFirstLicensed: data.ageFirstLicensed,
  careerStatus: data.careerStatus,
  dob: data.dob ? formatDateSlashNoLocale(new Date(data.dob)) : undefined,
  educationLevel: data.educationLevel,
  firstName: data.firstName,
  gender: data.gender,
  id: data.id,
  lastName: data.lastName,
  licenseNumber: data.driversLicenseNumber,
  licenseState: data.driversLicenseState
    ? normalizeState(data.driversLicenseState)
    : undefined,
  maritalStatus: data.maritalStatus,
  name: `${data.firstName} ${data.lastName}`,
  relationToMarbleMember: data.relationToMarbleMember,
});

export const normalizeLifeInsured = (data: LifeInsuredInput) => ({
  dob: data.dob ? formatDateSlashNoLocale(new Date(data.dob)) : undefined,
  educationLevel: data.educationLevel,
  firstName: data.firstName,
  gender: data.gender,
  id: data.id,
  lastName: data.lastName,
  maritalStatus: data.maritalStatus,
  name: `${data.firstName} ${data.lastName}`,
  relationToMarbleMember: data.relationToMarbleMember,
});

export type Driver = ReturnType<typeof normalizeDriver>;

export const normalizeNameInsured = (data: NamedInsuredType) => {
  return {
    altNames: data.altNames || [],
    dob: data.dob ? formatDateSlashNoLocale(new Date(data.dob)) : undefined,
    driversLicenseNumber: data.driversLicenseNumber,
    driversLicenseState: data.driversLicenseState,
    firstName: data.firstName,
    fullName: (data.firstName || '') + (' ' + data.lastName || ''),
    gender: data.gender,
    id: data.id,
    lastName: data.lastName,
    namedType: data.namedType,
    parentId: data.parentId,
    relationToMarbleMember: data.relationToMarbleMember,
  };
};

export type NamedInsured = ReturnType<typeof normalizeNameInsured>;
