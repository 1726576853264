import styled, { css } from 'styled-components';
import { from } from 'styles/media';

import { Body, OverlineSemi } from 'components/Typography';

import { GroupProps, Props } from './types';

export const Legend = styled(Body).attrs({ as: 'legend' })`
  margin-bottom: 1.5rem;
`;

export const AllCapsLegend = styled(OverlineSemi).attrs({ as: 'legend' })`
  margin-bottom: 1rem;
`;

export const Container = styled.fieldset<{ $variant: Props['variant'] }>`
  ${({ $variant }) => {
    switch ($variant) {
      case 'inverse':
        return css`
          color: ${({ theme }) => theme.colors.white};
        `;
      default:
        return css`
          color: ${({ theme }) => theme.colors.bluewood};
        `;
    }
  }}
`;

export const Group = styled.div<GroupProps>`
  display: flex;

  > * {
    inline-size: fit-content;
    block-size: 3rem;
  }

  ${({ $direction }) => {
    switch ($direction) {
      case 'horizontal':
        return css`
          > *:not(:last-child) {
            margin-inline-end: 1.5rem;
          }
        `;
      case 'vertical':
        return css`
          flex-direction: column;
          align-items: flex-start;

          > *:not(:last-child) {
            margin-block-end: 1.5rem;
          }
        `;

      case '2ColumnGrid':
        return css`
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          gap: 0.7rem;
          align-items: flex-start;
          white-space: nowrap;

          > * {
            inline-size: 100%;
            overflow: hidden;
          }

          ${from.tablet} {
            flex-direction: row;
            align-items: unset;
            > * {
              inline-size: 48%;
            }
          }
        `;

      case '3ColumnGrid':
        return css`
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 0.7rem;
          align-items: flex-start;
          white-space: nowrap;

          > * {
            inline-size: 48%;
            overflow: hidden;
          }

          ${from.tablet} {
            flex-direction: row;
            align-items: unset;
            > * {
              inline-size: 30%;
            }
          }
        `;
      default:
        return css`
          display: inline-flex;
          flex-direction: column;
          flex-wrap: wrap;
          gap: 0.7rem;
          align-items: flex-start;

          > *:not(:last-child) {
            margin-block-end: 1.5rem;
          }

          ${from.tablet} {
            flex-direction: row;
            align-items: unset;

            > *:not(:last-child) {
              margin-inline-end: 1.5rem;
              margin-block-end: 0;
            }
          }
        `;
    }
  }}

  ${({ $variant }) => {
    switch ($variant) {
      case 'inverse':
        return css`
          color: ${({ theme }) => theme.colors.white};
        `;
      default:
        return css`
          color: ${({ theme }) => theme.colors.bluewood};
        `;
    }
  }}

${({ $fullWidth, $fullHeight }) => {
    if ($fullWidth)
      return css`
        > * {
          inline-size: 100%;
        }
      `;
    if ($fullHeight)
      return css`
        > * {
          block-size: unset;
        }
      `;
  }}
`;

export const Error = styled.span`
  min-height: 0.75rem;
  margin-top: 0.5rem;
  margin-left: 0.75rem;
  color: ${({ theme }) => theme.colors.persimon};
  font-size: 0.625rem;
  line-height: 0.75rem;
`;
