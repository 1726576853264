import DesktopTopBar from './Desktop';
import MobileTopBar from './Mobile';
import useConnect from './connect';

const TopBar = () => {
  const {
    avatarUrl,
    firstName,
    handleTrackHome,
    handleTrackShop,
    hasFeatures,
    hasPolicies,
    initials,
    isNavHidden,
    isMobile,
    isModalRoute,
    lastName,
    viewAs,
  } = useConnect();

  if (isNavHidden) return null;

  if (isMobile) {
    if (isModalRoute) {
      return null;
    }

    return (
      <MobileTopBar
        avatarUrl={avatarUrl}
        firstName={firstName}
        initials={initials}
        lastName={lastName}
        onHomeClick={handleTrackHome}
        onShopClick={handleTrackShop}
        showWhatsNew={hasFeatures && hasPolicies}
        viewAs={viewAs}
      />
    );
  }

  return (
    <DesktopTopBar
      avatarUrl={avatarUrl}
      initials={initials}
      onHomeClick={handleTrackHome}
      onShopClick={handleTrackShop}
      showWhatsNew={hasFeatures && hasPolicies}
    />
  );
};

export default TopBar;
