import InputGroup from 'components/InputGroup';
import { FiCreditCard as CreditCardIcon } from 'react-icons/fi';
import { PiBank as BankIcon } from 'react-icons/pi';
import {
  Container,
  Error,
  PaymentFormContainer,
  PaymentTypeContainer,
  Title,
} from './styles';
import useConnect from './connect';
import { PaymentFormName, Props } from './types';
import CollectCardDetails from 'components/CollectCardDetails';
import { Formik } from 'formik';
import FullscreenLoader from 'components/FullscreenLoader';
import CollectBankDetails from 'components/CollectBankDetails';
import PaymentDetailsConfirmation from 'components/PaymentDetailsConfirmation';
import { Animate } from 'components/Animate';

const PaymentMethodSelection = ({
  details,
  isLoggedIn,
  offerId,
  isPaymentSubmitted,
  carrier,
  userEmail,
}: Props) => {
  const {
    bankAccountNumberRef,
    bankRoutingNumberRef,
    isDetailsVisible,
    visibleForm,
    generalError,
    fieldErrors,
    handleSetVisibleForm,
    handleToggleDetailsVisible,
    isMobile,
    cardExpirationRef,
    cardNumberRef,
    cardVerificationRef,
    currentCardBrand,
    submitBankDetails,
    submitCard,
    handleSetCurrentCardBrand,
    isSubmitting,
    isFetching,
    hasSubmitted,
  } = useConnect({
    isLoggedIn,
    offerId,
    submittedPaymentMethod: details?.paymentMethod,
  });

  return (
    <Container>
      {(isPaymentSubmitted && !isLoggedIn) || hasSubmitted ? (
        <Animate type="slideTop" duration={500}>
          <PaymentDetailsConfirmation carrier={carrier} email={userEmail} />
        </Animate>
      ) : (
        <>
          {!isPaymentSubmitted && (
            <PaymentTypeContainer>
              <Title>Payment Method</Title>
              <Formik initialValues={{}} onSubmit={() => {}}>
                <InputGroup
                  disabled={isPaymentSubmitted}
                  defaultValue={details?.paymentMethod}
                  direction={isMobile ? 'vertical' : 'horizontal'}
                  name={'paymentMethod'}
                  options={[
                    {
                      label: 'Card',
                      leftIcon: <CreditCardIcon size={'1.25rem'} />,
                      value: 'CARD',
                    },
                    {
                      label: 'Bank Account',
                      leftIcon: <BankIcon size={'1.5rem'} />,
                      value: 'BANK',
                    },
                  ]}
                  role="radio"
                  withHighlight
                  fullWidth
                  onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                    let button = e.target as HTMLInputElement;
                    handleSetVisibleForm(button.value as PaymentFormName);
                  }}
                />
              </Formik>
            </PaymentTypeContainer>
          )}
          <PaymentFormContainer>
            {isSubmitting && <FullscreenLoader isFullPage={false} />}
            {visibleForm === 'CARD' && (
              <CollectCardDetails
                cardExpirationRef={cardExpirationRef}
                cardNumberRef={cardNumberRef}
                cardVerificationRef={cardVerificationRef}
                currentCardBrand={currentCardBrand}
                isLoggedIn={isLoggedIn}
                details={details}
                isPaymentSubmitted={isPaymentSubmitted}
                submitCard={submitCard}
                isDetailsVisible={isDetailsVisible}
                handleSetCurrentCardBrand={handleSetCurrentCardBrand}
                isSubmitting={isSubmitting}
                isFetching={isFetching}
                handleToggleDetailsVisible={handleToggleDetailsVisible}
                fieldErrors={fieldErrors}
              />
            )}
            {visibleForm === 'BANK' && (
              <CollectBankDetails
                details={details}
                isPaymentSubmitted={isPaymentSubmitted}
                submitBankDetails={submitBankDetails}
                isDetailsVisible={isDetailsVisible}
                isSubmitting={isSubmitting}
                isFetching={isFetching}
                bankAccountNumberRef={bankAccountNumberRef}
                bankRoutingNumberRef={bankRoutingNumberRef}
                handleToggleDetailsVisible={handleToggleDetailsVisible}
                fieldErrors={fieldErrors}
              />
            )}
            {generalError && <Error>{generalError}</Error>}
          </PaymentFormContainer>
        </>
      )}
    </Container>
  );
};

export default PaymentMethodSelection;
