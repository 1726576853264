import useLogic from './logic';
import { Component } from './styles';
import { Props } from './types';

const Button = ({
  children,
  fullWidth,
  icon,
  iconPosition = 'left',
  onClick: defaultOnClick,
  className,
  variant,
  size,
  ...props
}: Props) => {
  const { onClick } = useLogic({ defaultOnClick });
  return (
    <Component
      $fullWidth={fullWidth}
      $hasIcon={!!icon}
      $iconPosition={iconPosition}
      $size={size}
      $variant={variant}
      className={className}
      onClick={onClick}
      {...props}
    >
      {icon}
      {children && <span>{children}</span>}
    </Component>
  );
};

export default Button;

export type { Props as ButtonProps };
