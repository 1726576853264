import MaxWidth from 'components/MaxWidth';
import styled from 'styled-components';
import { until } from 'styles/media';

export const Container = styled.nav`
  background-color: ${({ theme }) => theme.colors.bluewood};
`;

export const Content = styled(MaxWidth)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  block-size: 5rem;
  ${until.tablet} {
    block-size: 3.75rem;
  }
`;
