import {
  FiChevronDown,
  FiHelpCircle,
  FiLock,
  FiLogOut,
  FiRadio,
  FiSettings,
  FiUser,
  FiUsers,
} from 'react-icons/fi';
import styled, { css } from 'styled-components';
import { ReactComponent as DefaultClickOutIcon } from 'assets/images/icons/box-arrow-up-right.svg';

import { DropdownContent as DefaultDropdownContent } from 'components/Dropdown';
import { Body, OverlineBold } from 'components/Typography';

import { ButtonProps } from './types';

const IconCommonStyles = css`
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1.125rem;
  color: inherit;
`;

export const DropdownContent = styled(DefaultDropdownContent)`
  & > *:not(:last-child) {
    margin-block-end: 1.5rem;
  }
  z-index: 20;
`;

export const Title = styled(OverlineBold)`
  color: ${({ theme }) => theme.colors.starDust};
`;

export const ExternalLink = styled.a`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.bluewood};
  text-decoration: none;

  &:hover,
  &:focus {
    cursor: pointer;
    opacity: 0.7;
  }
`;

export const Text = styled(Body)``;

export const ViewAs = styled(Body)`
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.colors.starDust};
`;

export const FaqIcon = styled(FiHelpCircle).attrs({ strokeWidth: 1.5 })`
  ${IconCommonStyles}
`;

export const SettingsIcon = styled(FiSettings).attrs({ strokeWidth: 1.5 })`
  ${IconCommonStyles}
`;

export const SignOutIcon = styled(FiLogOut).attrs({ strokeWidth: 1.5 })`
  ${IconCommonStyles}
`;

export const UserIcon = styled(FiUser).attrs({ strokeWidth: 1.5 })`
  ${IconCommonStyles}
`;

export const WhatsNewIcon = styled(FiRadio).attrs({ strokeWidth: 1.5 })`
  ${IconCommonStyles}
`;

export const ReferralIcon = styled(FiUsers).attrs({ strokeWidth: 1.5 })`
  ${IconCommonStyles}
`;

export const Button = styled.button<ButtonProps>`
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.bluewood};
  display: flex;
  padding: 0;

  &:hover,
  &:focus {
    cursor: pointer;
    opacity: 0.7;
  }

  ${({ $withColor }) =>
    $withColor
      ? css`
          color: ${({ theme }) => theme.colors.persimon};
        `
      : null}
`;

export const MenuTitle = styled.div<{ $active: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ $active, theme }) =>
    $active ? `${theme.colors.goldenRod};` : `${theme.colors.white}`};
  font-weight: ${({ theme }) => theme.weights.semiBold};
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-decoration: none;
  cursor: pointer;

  :hover,
  :focus {
    color: ${({ theme }) => theme.colors.goldenRod};
  }

  &.active {
    color: ${({ theme }) => theme.colors.goldenRod};
  }
`;

export const Chevron = styled(FiChevronDown)<{
  $isOpen: boolean;
}>`
  color: inherit;
  font-size: 1.25rem;
  flex-shrink: 0;
  margin-left: 0.375rem;

  ${({ $isOpen }) =>
    $isOpen ? 'transform: rotate(180deg);' : 'transform: rotate(0);'}
`;

export const ClickOutIcon = styled(DefaultClickOutIcon)`
  margin-left: 0.5rem;
  > path {
    stroke: ${({ theme }) => theme.colors.bluewood};
    stroke-width: 0.025rem;
  }
`;

export const LockIcon = styled(FiLock)`
  width: 1rem;
  height: 1rem;
  color: inherit;
`;
