import {
  FiX as CloseIcon,
  FiHelpCircle as FaqIcon,
  FiLogOut as LogOutIcon,
  FiUsers as ReferralIcon,
  FiSettings as SettingsIcon,
  FiRadio as WhatsNewIcon,
} from 'react-icons/fi';

import useConnect from './connect';
import {
  Avatar,
  Close,
  Container,
  ExternalLink,
  Information,
  Link,
  List,
  ListItem,
  ListItemIcon,
  Logout,
  ModalButton,
  MoreInformation,
  Name,
  ViewAs,
} from './styles';
import { Props } from './types';
import { FAQ } from 'globals/constants';

const Menu = ({
  firstName,
  handleLogout,
  initials,
  lastName,
  onClose,
  showWhatsNew,
  viewAs,
}: Props) => {
  const { handleTrackSettings, openReferralModal, referralValue } = useConnect({
    onClose,
  });

  return (
    <Container>
      <Close onClick={onClose}>
        <CloseIcon />
      </Close>
      <Information>
        <Avatar initials={initials} />
        <MoreInformation>
          <Name>
            {firstName} {lastName}
          </Name>
          {viewAs ? <ViewAs>Viewing as {viewAs}</ViewAs> : null}
        </MoreInformation>
      </Information>
      <List>
        <ListItem>
          <Link onClick={handleTrackSettings} to="/settings">
            <ListItemIcon as={SettingsIcon} />
            Account settings
          </Link>
        </ListItem>
        <ListItem>
          <ExternalLink href={FAQ} rel="noopener noreferrer" target="_blank">
            <ListItemIcon as={FaqIcon} />
            FAQ
          </ExternalLink>
        </ListItem>
        <ListItem>
          <ModalButton onClick={openReferralModal}>
            <ListItemIcon as={ReferralIcon} />
            Invite Friends, get {referralValue} Marbles
          </ModalButton>
        </ListItem>
        {showWhatsNew ? (
          <ListItem>
            <Link onClick={onClose} to="/whats-new">
              <ListItemIcon as={WhatsNewIcon} />
              What&apos;s new
            </Link>
          </ListItem>
        ) : null}
        <ListItem>
          <Logout onClick={handleLogout}>
            <ListItemIcon as={LogOutIcon} />
            Sign out
          </Logout>
        </ListItem>
      </List>
    </Container>
  );
};

export default Menu;
