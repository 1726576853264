import useMe from 'graphql/hooks/useMe';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { track } from 'services/segment';

const useConnect = () => {
  const { pathname } = useLocation();

  const { me } = useMe();

  const handleTrackAvatar = useCallback(() => {
    if (me && !me.isViewedAs) track('Avatar - Clicked');
  }, [me]);

  return {
    handleTrackAvatar,
    pathname,
  };
};

export default useConnect;
